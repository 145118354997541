import React, {createRef} from 'react'
import TablePage from "../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../constants/PageState';
import {
    Drawer, LinearProgress, Table,
    TableBody,
    TableCell, TableFooter,
    TableHead, TablePagination,
    TableRow, TextField
} from "@material-ui/core";
import DrawerWrapper from "../../common/DrawerWrapper";
import Grid from '@material-ui/core/Grid';
import CONFIG from "../../config";
import {sortTable} from "../../util/DataUtil";
import SelectControl from "../../components/controls/SelectControl";
import LawsAndRegulationsTypes, {
    getLawsAndRegulations,
    getLawsAndRegulationsString
} from "../../constants/LawsAndRegulationsTypes";
import {transformDate} from "../../util/DateUtil";
import {getAllLawsAndRegulations} from "../../services/admin/LawsAndRegulationsService";
import update from "immutability-helper";
import {downloadDocument} from "../../services/DocumentService";

class LawsAndRegulations extends TablePage {

    tableDescription = [];

    tableDescriptionCitLaw = [
        { key: 'name', label: strings.lawsAndRegulations.title },
        { key: 'description', label: strings.lawsAndRegulations.tableDescription },
        { key: 'date', label: strings.lawsAndRegulations.date, transform: 'date' },
        { key: 'document', label: strings.lawsAndRegulations.document, transform: 'renderDownloadImage' },
    ];

    tableDescriptionVatLaw = [
        { key: 'name', label: strings.lawsAndRegulations.title },
        { key: 'description', label: strings.lawsAndRegulations.tableDescription },
        { key: 'date', label: strings.lawsAndRegulations.date, transform: 'date' },
        { key: 'document', label: strings.lawsAndRegulations.document, transform: 'renderDownloadImage' },
    ];

    tableDescriptionOpinionOfMinistryOfFinance = [
        { key: 'name', label: strings.lawsAndRegulations.title },
        { key: 'id', label: strings.lawsAndRegulations.id },
        { key: 'date', label: strings.lawsAndRegulations.date, transform: 'renderDate' },
        { key: 'description', label: strings.lawsAndRegulations.tableDescription },
        { key: 'document', label: strings.lawsAndRegulations.document, transform: 'renderDownloadImage' },
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: {
                selectedOption: {
                    'name' : getLawsAndRegulationsString(LawsAndRegulationsTypes.CIT_LAW),
                    'value' : LawsAndRegulationsTypes.CIT_LAW
                }
            },
            showSearch: true,
            // tableData: [],
            tableData : [
            ],
            searchData : {
                search : '',
                total : 0,
                page : 1,
                perPage : 30
            },
            pageState: PageState.View,
            total: 100,
            errors: {},
            options: getLawsAndRegulations(),
            windowWidth: undefined
        };
    }

    date(item){
        if (!item){
            return '';
        }
        var s = item;

        if (typeof s !== 'string'){
            return item;
        }

        var n = s.indexOf('T');
        return s.substring(0, n != -1 ? n : s.length)
    }

    fetchData() {
        this.state.searchData.type = Number(this.getSearchParam('type'));

        getAllLawsAndRegulations(this.state.searchData).then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                tableData: response.data.result,
                searchData : {
                    ...this.state.searchData,
                    page : response.data.page,
                    perPage : response.data.perPage,
                    total : Math.ceil(response.data.total/response.data.perPage)
                }
            });
        });
    }

    handleResize = () => this.setState({
        windowWidth: window.innerWidth
    });

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    componentDidMount() {
        this.fetchData();

        this.handleResize();
        window.addEventListener('resize', this.handleResize);

        let paramString = this.getSearchParam('type');

        if(paramString){
            this.state.data.selectedOption.value = Number(paramString);
            this.state.data.selectedOption.name = getLawsAndRegulationsString(LawsAndRegulationsTypes.CIT_LAW);
        }

        this.setState({
            data: this.state.data
        });

        this.tableDescription = this.tableDescriptionCitLaw;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.data !== this.state.data){
            if(this.state.data.selectedOption && this.state.data.selectedOption.value){
                if(this.state.data.selectedOption.value === LawsAndRegulationsTypes.CIT_LAW){
                    this.tableDescription = this.tableDescriptionCitLaw;
                }else if(this.state.data.selectedOption.value === LawsAndRegulationsTypes.VAT_LAW){
                    this.tableDescription = this.tableDescriptionVatLaw;
                }else if(this.state.data.selectedOption.value === LawsAndRegulationsTypes.OPINION_OF_MINISTRY_OF_FINANCE){
                    this.tableDescription = this.tableDescriptionOpinionOfMinistryOfFinance;
                }else if(this.state.data.selectedOption.value === LawsAndRegulationsTypes.WHT_LAW){
                    this.tableDescription = this.tableDescriptionOpinionOfMinistryOfFinance;
                }else if(this.state.data.selectedOption.value === LawsAndRegulationsTypes.DTT_LAW){
                    this.tableDescription = this.tableDescriptionOpinionOfMinistryOfFinance;
                }

            }

            this.setState({
                data: this.state.data
            });
        }

        window.addEventListener('resize', this.handleResize);
    }

    renderDownloadImage(item){
        return(
            <img src={'/images/direct-download1.svg'}  alt={'download'} onClick={()=> {
                if (!item){
                    return
                }

                downloadDocument(item)
            }}/>
        );
    }

    renderDate(item){
        if(!item){
            return '';
        }

        return transformDate(item);
    }

    renderLink(item){
        if (!item) {
            return '';
        }

        return(
            <a href={item}>{ strings.lawsAndRegulations.link }</a>
        );
    }

    sortTable(param){
        let sortedData = sortTable(this.state.tableData, param);

        this.setState({
            tableData: sortedData
        })
    }

    getPageHeader() {
        return <h1>{ strings.lawsAndRegulations.mainTitle }</h1>;
    }

    getPageDescription(){
        return <h3>{ strings.lawsAndRegulations.description }</h3>
    }

    renderTableHeader() {

        let result = [];

        for(let item of this.tableDescription) {
            if(item.label !== 'Document'){
                result.push(
                    <TableCell key={ 'table-header-' + result.length } onClick={() => this.sortTable(item.key)} style={{cursor: 'pointer'}}>
                        <div>
                            { item.label }
                            <div className={'sort-icon'}>
                                <img src={'/images/Polygon2.svg'} />
                                <img src={'/images/Polygon3.svg'} />
                            </div>
                        </div>
                    </TableCell>
                )
            }else {
                result.push(
                    <TableCell key={ 'table-header-' + result.length } style={{cursor: 'default'}}>
                        <div style={{justifyContent: 'center'}}>
                            { item.label }
                        </div>
                    </TableCell>
                )
            }
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    { result }
                </TableRow>
            </TableHead>
        );
    }

    renderTableRowData(item) {

        let result = [];

        for(let description of this.tableDescription) {
            if(description.label !== 'Document'){
                result.push(
                    <TableCell key={ 'table-data-' + description.key + '-' + result.length }>
                        {
                            description.transform !== undefined &&
                            this[description.transform](item[description.key])
                        }
                        {
                            description.transform === undefined &&
                            item[description.key]
                        }
                    </TableCell>
                );
            }else {
                result.push(
                    <TableCell key={ 'table-data-' + description.key + '-' + result.length } style={{textAlign: 'center'}}>
                        {
                            description.transform !== undefined &&
                            this[description.transform](item[description.key])
                        }
                        {
                            description.transform === undefined &&
                            item[description.key]
                        }
                    </TableCell>
                );
            }
        }

        return result;
    }

    changeData(event, data = 'data') {
        this.props.history.push('/laws-and-regulations?type=' + event.target.value.value)
        this.fetchData()

        this.setState({
            [data]: update(this.state[data], { [event.target.name]: {$set: event.target.value} })
        });
    }

    renderTableRow(data = []) {

        let result = [];

        for(let item of data) {

            if(this.state.data.selectedOption && (item.type === this.state.data.selectedOption.value)){
                let className = 'table-row';

                if(this.isRowSelected(item)) {
                    className += ' selected';
                }

                result.push(
                    <TableRow key={ 'table-row-' + result.length } className={ className } onClick={ () => this.selectRow(item) }>
                        { this.renderTableRowData(item) }
                    </TableRow>
                )
            }
        }

        return (
            <TableBody>
                { result }
            </TableBody>
        );
    }

    renderRowMenu(index, item) {
        return(
            <TableCell>
            </TableCell>
        );
    }

    render() {
        let maxHeight = 0;

        if(this.state.windowWidth >= 1920){
            maxHeight = 40;
        }else {
            maxHeight = 32;
        }

        const colorStyles = {
            control: base => ({
                ...base,
                height: maxHeight,
                minHeight: maxHeight
            }),
            valueContainer: (styles) => {
                const position = 'initial';

                return {...styles, position};
            },
            singleValue: (styles) => {
                const paddingBottom = 0;

                return {...styles, paddingBottom};
            },
            indicatorsContainer: (styles) => {
                let height = maxHeight;

                return {...styles, height};
            }
        };

        return (
            <div id={'services-table-page'}>
                <div className={'header-image'}
                     style={{backgroundImage: `url('/images/MaskGroup2.jpg')`}}
                >
                </div>
                <Grid id='services-table'>
                    <div className='header'>
                        { this.getPageHeader() }
                        { this.getPageDescription() }
                    </div>
                    <div className='filter-controls'>

                        {
                            this.state.showSearch &&
                            <div className={'filter-wrapper'}>
                                <TextField
                                    placeholder={ strings.table.search }
                                    type="search"
                                    name='search'
                                    value={ this.state.searchData.search }
                                    onChange={ this.searchChanged }
                                    variant={'outlined'}
                                    className={'filter-input'}
                                />
                            </div>
                        }

                        <SelectControl
                            options={this.state.options}
                            fullWidth
                            autoFocus
                            name='selectedOption'
                            onChange={ this.changeData }
                            margin="normal"
                            value={ this.state.data.selectedOption }
                            nameKey={ 'name' }
                            valueKey={ 'value' }
                            selected={ this.state.data.selectedOption }
                            styles={colorStyles}
                        />

                        {
                            this.state.showAdd &&
                            this.renderTableControls()
                        }
                    </div>
                    <div className={'table-wrapper'} md={12}>
                        { this.renderTable(this.state.tableData) }
                    </div>

                    <Drawer id='drawer' anchor='right' open={  this.showDrawer() } onClose={ () => this.setPageState(PageState.View) } >
                        <DrawerWrapper onBack={ () => this.setPageState(PageState.View) }>
                            { this.renderDrawerContent() }
                        </DrawerWrapper>
                    </Drawer>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(LawsAndRegulations)));
