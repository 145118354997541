import React from 'react';
import strings from '../../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../../functions/Validation";
import Select from "react-select";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel, Radio,
    RadioGroup
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import AnswerTypes from "../../../../constants/AnswerTypes";
import CreatableSelectControl from "../../../controls/CreatableSelectControl";
import SelectControl from "../../../controls/SelectControl";
import PageState from "../../../../constants/PageState";
import DropdownOptions from "../../../../constants/DropdownOptions";

const CustomRadio = withStyles({
    root: {
        color: '#1C1C1C',
        '&$checked': {
            color: '#1C1C1C',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

function renderPlans(data, selectPlan, key, removePlan){
    let result = [];

    if(data.plans){
        for(let i=0; i < data.plans.length; i++){
            result.push(
                <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}}>
                    <h6 onClick={() => selectPlan(data.plans[i], key)}>{data.plans[i].title ? data.plans[i].title : data.plans[i].name}</h6>
                    <span onClick={(event) => {
                        removePlan(event, key, i)
                    }}>X</span>
                </div>
            )
        }
    }

    return result;
}

function renderProducts(data, errors, onChange, types, openDialog, uploadedFile, decisions, onSubmit, changeProduct, handleOpen, addPlan, changePlan, selectPlan, addNewTypeDialogOpen, addWTHDecisionDialogOpen, handleAdd, removeProduct, removePlan){
    let result = [];

    for(let key in data.products){
        let item = data.products[key];
        let selectedPlan = {};

        if(item.plans){
            for(let plan of item.plans){
                if(plan.selected){
                    selectedPlan = plan;
                }
            }
        }

        result.push(
            <div>
                <div className={'product'}>
                    <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}}>
                        <p>{strings.vendorsDataBase.product}</p>
                        <span onClick={(event)=> {
                            removeProduct(event, key)
                        }}>X</span>
                    </div>
                    <Divider />

                    <div className={ 'select-container' + (hasError(errors, 'name') ? ' error' : '') }>
                        <label>{ strings.vendorsDataBase.name }</label>
                        <TextField
                            error={ hasError(errors, 'name') }
                            helperText={ getError(errors, 'name') }
                            fullWidth
                            name='name'
                            onChange={ (event) => changeProduct(event, key) }
                            margin="normal"
                            value={ item.name }
                            variant={'outlined'}
                        />
                    </div>

                    <div className={ 'select-container' + (hasError(errors, 'description') ? ' error' : '') }>
                        <label>{ strings.vendorsDataBase.description }</label>
                        <TextField
                            error={ hasError(errors, 'description') }
                            helperText={ getError(errors, 'description') }
                            fullWidth
                            name='description'
                            onChange={ (event) => changeProduct(event, key) }
                            margin="normal"
                            value={ item.description }
                            variant={'outlined'}
                            multiline
                            className={'description-smaller'}
                        />
                    </div>
                </div>

                <div className={'plans'}>
                    <div className={'left'}>
                        <p>{strings.vendorsDataBase.plans}</p>
                        <Divider />
                        {renderPlans(item, selectPlan, key, removePlan)}
                        <div className='add-button'>
                            <div onClick={ (event) => addPlan(key) }>
                                { strings.vendorsDataBase.addPlan}
                            </div>
                        </div>
                    </div>

                    <div className={'right'}>
                        <div className={ 'select-container' + (hasError(errors, 'name') ? ' error' : '') }>
                            <label>{ strings.vendorsDataBase.title }</label>
                            <TextField
                                error={ hasError(errors, 'name') }
                                helperText={ getError(errors, 'name') }
                                fullWidth
                                name='name'
                                onChange={ (event) => changePlan(event, key, selectedPlan.id) }
                                margin="normal"
                                value={ selectedPlan ? selectedPlan.name : '' }
                                variant={'outlined'}
                            />
                        </div>

                        <div className={'select-control-container' + (hasError(errors, 'type') ? ' error' : '')}>

                            <label>{strings.vendorsDataBase.type}</label>

                            <SelectControl
                                placeholder={' '}
                                onChange={ (event) => changePlan(event, key, selectedPlan.id) }
                                options={ types }
                                selected={selectedPlan.vendor_plan_type}
                                value={selectedPlan.vendor_plan_type}
                                isClearable={ true }
                                className={'dropdown'}
                                nameKey={ 'name' }
                                valueKey={ 'id' }
                                name={'type'}
                            />
                        </div>

                        {
                            addNewTypeDialogOpen
                            ?
                            <div className={'dialog-form'}>
                                <div className={'dialog-header'}>
                                    <DialogTitle id="form-dialog-title">{strings.vendorsDataBase.addNewTypeTitle}</DialogTitle>
                                </div>
                                <Divider />
                                <DialogContent>
                                    <div className={ 'select-container' + (hasError(errors, 'typeName') ? ' error' : '') }>
                                        <label>{ strings.vendorsDataBase.typeName }</label>
                                        <TextField
                                            error={ hasError(errors, 'typeName') }
                                            helperText={ getError(errors, 'typeName') }
                                            fullWidth
                                            name='typeName'
                                            onChange={ (event) => changePlan(event, key, selectedPlan.id) }
                                            margin="normal"
                                            value={ selectedPlan.typeName }
                                            variant={'outlined'}
                                        />
                                    </div>

                                    <label>{ strings.vendorsDataBase.payment }</label>
                                    <RadioGroup name="planPayment" onChange={ (event) => changePlan(event, key, selectedPlan.id) }>
                                        <FormControlLabel value={AnswerTypes.YES} control={<CustomRadio />} label={ strings.vendorsDataBase.yes } />
                                        <FormControlLabel value={AnswerTypes.NO} control={<CustomRadio />} label={ strings.vendorsDataBase.no } />
                                    </RadioGroup>
                                </DialogContent>
                                <DialogActions>
                                    <Button type="submit" color="primary" onClick={(event) => handleAdd(event, DropdownOptions.TYPES, data, ['typeName', 'planPayment'], key, selectedPlan.id, 'type')}>
                                        Add
                                    </Button>
                                </DialogActions>
                            </div>
                            :
                            <div className='add-button indent'>
                                <div onClick={ () => handleOpen('addNewTypeDialog') }>
                                    { strings.vendorsDataBase.addNewType}
                                </div>
                            </div>
                        }

                        <div className={ 'select-container' + (hasError(errors, 'description') ? ' error' : '') }>
                            <label>{ strings.vendorsDataBase.description }</label>
                            <TextField
                                error={ hasError(errors, 'description') }
                                helperText={ getError(errors, 'description') }
                                fullWidth
                                name='description'
                                onChange={ (event) => changePlan(event, key, selectedPlan.id) }
                                margin="normal"
                                value={ selectedPlan.description }
                                variant={'outlined'}
                                multiline
                                rowsMax={5}
                                className={'description-smaller'}
                            />
                        </div>

                        <div className={ 'select-container' + (hasError(errors, 'price') ? ' error' : '') }>
                            <label>{ strings.vendorsDataBase.price }</label>
                            <TextField
                                error={ hasError(errors, 'price') }
                                helperText={ getError(errors, 'price') }
                                fullWidth
                                name='price'
                                onChange={ (event) => changePlan(event, key, selectedPlan.id) }
                                margin="normal"
                                value={ selectedPlan.price }
                                variant={'outlined'}
                            />
                        </div>

                        <div className={'browse-button'}>
                            <p>{ strings.adminCorStates.attachment }</p>
                            <div>
                                <Button variant="contained" color="primary" onClick={()=> openDialog(key, selectedPlan.id)}>
                                    { strings.adminCorStates.chooseFile }
                                </Button>
                                <p className={'upload-description'}>{ strings.adminCorStates.supportedFormats }</p>
                            </div>
                        </div>
                        <p className={'file-name'}>{selectedPlan.file ? selectedPlan.file.name : ''}</p>

                        <p className={'additional-info'}>{strings.vendorsDataBase.additionalInfoContent}</p>

                        <div className={'select-control-container' + (hasError(errors, 'wthDecision') ? ' error' : '')}>
                            <label>{strings.vendorsDataBase.wthDecision}</label>
                            <SelectControl
                                placeholder={' '}
                                onChange={ (event) => changePlan(event, key, selectedPlan.id) }
                                options={ decisions }
                                selected={selectedPlan.decision}
                                value={selectedPlan.decision}
                                isClearable={ true }
                                className={'dropdown'}
                                nameKey={ 'name' }
                                valueKey={ 'id' }
                                name={'decision'}
                            />
                        </div>

                        {
                            addWTHDecisionDialogOpen
                            ?
                            <div className={'dialog-form'}>
                                <div className={'dialog-header'}>
                                    <DialogTitle id="form-dialog-title">{strings.vendorsDataBase.addNewWthDecisionTitle}</DialogTitle>
                                </div>
                                <Divider />
                                <DialogContent>
                                    <div className={ 'select-container' + (hasError(errors, 'wthDecisionName') ? ' error' : '') }>
                                        <label>{ strings.vendorsDataBase.wthDecisionName }</label>
                                        <TextField
                                            error={ hasError(errors, 'wthDecisionName') }
                                            helperText={ getError(errors, 'wthDecisionName') }
                                            fullWidth
                                            name='wthDecisionName'
                                            onChange={ (event) => changePlan(event, key, selectedPlan.id) }
                                            margin="normal"
                                            value={ data.wthDecisionName }
                                            variant={'outlined'}
                                        />
                                    </div>

                                    <div className={ 'select-container' + (hasError(errors, 'percent') ? ' error' : '') }>
                                        <label>{ strings.vendorsDataBase.percent }</label>
                                        <TextField
                                            error={ hasError(errors, 'percent') }
                                            helperText={ getError(errors, 'percent') }
                                            fullWidth
                                            name='percent'
                                            onChange={ (event) => changePlan(event, key, selectedPlan.id) }
                                            margin="normal"
                                            value={ data.percent }
                                            variant={'outlined'}
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button type="submit" color="primary" onClick={(event) => handleAdd(event, DropdownOptions.DECISIONS, data, ['wthDecisionName', 'percent'], key, selectedPlan.id, 'decision')}>
                                        Add
                                    </Button>
                                </DialogActions>
                            </div>
                            :
                            <div className='add-button indent'>
                                <div onClick={ () => handleOpen('addWTHDecision') }>
                                    { strings.vendorsDataBase.addNewWthDecision}
                                </div>
                            </div>
                        }

                        {/*<div className='save-button'>*/}
                        {/*    <Button variant="contained" onClick={ onSubmit }>*/}
                        {/*        { strings.vendorsDataBase.save}*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    }

    return result;
}

const VendorsDataBaseForm = ({
                            onSubmit,
                            onChange,
                            errors,
                            data,
                                 countries,
                            openDialog,
                            uploadedFile,
                            addNewTypeDialogOpen,
                            addWTHDecisionDialogOpen,
                            handleOpen,
                            handleClose,
                            types,
                            decisions,
                                 removePlan,
                            handleAdd,
                            addProduct,
                            addPlan,
                            changeProduct,
                            changePlan,
                            selectPlan,
                            onAdd,
                            onEdit,
                                 removeProduct,
                            pageState,
                        }) => (
    <form id="admin-service-form" className={'vendors-data-base-form'} onSubmit={ onSubmit } action = "#">
        <div className={ 'select-container' + (hasError(errors, 'name') ? ' error' : '') }>
            <label>{ strings.vendorsDataBase.name }</label>
            <TextField
                error={ hasError(errors, 'name') }
                helperText={ getError(errors, 'name') }
                fullWidth
                name='name'
                onChange={ onChange }
                margin="normal"
                value={ data.name }
                variant={'outlined'}
            />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'description') ? ' error' : '') }>
            <label>{ strings.vendorsDataBase.description }</label>
            <TextField
                error={ hasError(errors, 'description') }
                helperText={ getError(errors, 'description') }
                fullWidth
                name='description'
                onChange={ onChange }
                margin="normal"
                value={ data.description }
                variant={'outlined'}
                multiline
                rowsMax={5}
                className={'description'}
            />
        </div>

        <div className={'select-control-container' + (hasError(errors, 'country') ? ' error' : '')}>
            {/*{*/}
            {/*    this.props.label &&*/}
            {/*    <label>{ this.props.label }</label>*/}
            {/*}*/}

            <label>Country</label>

            <SelectControl
                placeholder={' '}
                onChange={ (event)=> onChange(event) }
                options={ countries }
                selected={data.country}
                // value={selectedPlan.type}
                isClearable={ true }
                className={'dropdown'}
                nameKey={ 'name' }
                valueKey={ 'id' }
                name={'country'}
            />

            {/*{*/}
            {/*    this.state.hasError &&*/}
            {/*    <p className='error'>{ this.state.error }</p>*/}
            {/*}*/}
        </div>

        <div className={ 'select-container' + (hasError(errors, 'website_link') ? ' error' : '') }>
            <label>{ strings.vendorsDataBase.websiteLink }</label>
            <TextField
                error={ hasError(errors, 'website_link') }
                helperText={ getError(errors, 'website_link') }
                fullWidth
                name='website_link'
                onChange={ onChange }
                margin="normal"
                value={ data.website_link }
                variant={'outlined'}
            />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'website_pricing') ? ' error' : '') }>
            <label>{ strings.vendorsDataBase.websitePricing }</label>
            <TextField
                error={ hasError(errors, 'website_pricing') }
                helperText={ getError(errors, 'website_pricing') }
                fullWidth
                name='website_pricing'
                onChange={ onChange }
                margin="normal"
                value={ data.website_pricing }
                variant={'outlined'}
            />
        </div>

        <div className={'product-and-plans'}>
            {renderProducts(data, errors, onChange, types, openDialog, uploadedFile, decisions, onSubmit, changeProduct, handleOpen, addPlan, changePlan, selectPlan, addNewTypeDialogOpen, addWTHDecisionDialogOpen, handleAdd, removeProduct, removePlan)}
        </div>

        <div className='add-new-product'>
            <Button variant="contained" onClick={ addProduct }>
                { strings.vendorsDataBase.addNewProduct}
            </Button>
        </div>

        <div className='submit-container'>
            <Button variant="contained" onClick={ pageState === PageState.Add ? onAdd : onEdit }>
                { strings.adminCorStates.save}
            </Button>
        </div>
    </form>
);

export default VendorsDataBaseForm;