import React from 'react';
import strings from '../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../functions/Validation";

const ResetPasswordForm = ({
                              onSubmit,
                              onChange,
                              errors,
                              data,
                              edit,
                              onEdit
                          }) => (
    <form id="edit-profile-form" onSubmit={ onSubmit } action = "#">

        <div className={'form-header'}>
            <h2 className={'desktop-title'}>{ strings.myProfile.password }</h2>
            <h2 className={'mobile-title'}>{ strings.myProfile.resetPassword }</h2>
            <img src={'/images/edit.svg'}  onClick={() => onEdit('editPassword')} />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'password') ? ' error' : '') }>
            {
                edit
                ?
                <div>
                    <label>{ strings.myProfile.currentPassword }</label>
                    <TextField
                        error={ hasError(errors, 'password') }
                        helperText={ getError(errors, 'password') }
                        fullWidth
                        name='password'
                        variant={'outlined'}
                        onChange={ onChange }
                        margin="normal"
                        value={ data.password }
                        type={'password'}
                        disabled={true}
                    />
                </div>
                :
                <div>
                    <label>{ strings.myProfile.password }</label>
                    <TextField
                        error={ hasError(errors, 'password') }
                        helperText={ getError(errors, 'password') }
                        fullWidth
                        name='password'
                        onChange={ onChange }
                        margin="normal"
                        value={ data.password }
                        type={'password'}
                        disabled={!edit}
                        InputProps={{
                            disableUnderline: true
                        }}
                    />
                </div>
            }
        </div>

        {
            edit &&
            <div className={ 'select-container' + (hasError(errors, 'newPassword') ? ' error' : '') }>
                <label>{ strings.myProfile.newPassword }</label>
                <TextField
                    error={ hasError(errors, 'newPassword') }
                    helperText={ getError(errors, 'newPassword') }
                    fullWidth
                    name='newPassword'
                    variant={'outlined'}
                    onChange={ onChange }
                    margin="normal"
                    value={ data.newPassword }
                    type={'password'}
                    disabled={!edit}
                />
            </div>
        }

        {
            edit &&
            <div className={ 'select-container' + (hasError(errors, 'repeatPassword') ? ' error' : '') }>
                <label>{ strings.myProfile.confirmPassword }</label>
                <TextField
                    error={ hasError(errors, 'repeatPassword') }
                    helperText={ getError(errors, 'repeatPassword') }
                    fullWidth
                    name='repeatPassword'
                    variant={'outlined'}
                    onChange={ onChange }
                    margin="normal"
                    value={ data.repeatPassword }
                    type={'password'}
                    disabled={!edit}
                />
            </div>
        }

        {
            edit &&
            <div className='submit-container desktop-button'>
                <Button variant="contained" color="primary" onClick={ () => onSubmit('editPassword') }>
                    { strings.myProfile.save }
                </Button>
            </div>
        }

        <div className='submit-container mobile-button'>
            {
                edit
                ?
                <Button className={'save-btn'} variant="contained" color="primary" onClick={ () => onSubmit('editPassword') }>
                    { strings.myProfile.save }
                </Button>
                :
                <Button className={'edit-btn'} variant="contained" color="primary" onClick={ () => onEdit('editPassword') }>
                    { strings.myProfile.edit }
                </Button>
            }
        </div>
    </form>
);

export default ResetPasswordForm;