import React, {createRef} from 'react'
import TablePage from "../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {Link, withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../constants/PageState';
import {
    Divider,
    Drawer, FormControl, FormControlLabel, LinearProgress, Radio, RadioGroup, Table,
    TableBody,
    TableCell, TableFooter,
    TableHead, TablePagination,
    TableRow, TextField
} from "@material-ui/core";
import DrawerWrapper from "../../common/DrawerWrapper";
import Grid from '@material-ui/core/Grid';
import CONFIG from "../../config";
import {sortTable} from "../../util/DataUtil";
import {withStyles} from "@material-ui/core/styles";
import AnswerTypes from "../../constants/AnswerTypes";
import VendorsDataBaseDetails from "./VendorsDataBaseDetails";
import {getAllCorStates} from "../../services/admin/CorStatesService";
import {getAllVendorsDatabases} from "../../services/admin/VendorsDataBaseService";

class VendorsDataBase extends TablePage {

    tableDescription = [
        { key: 'name', label: strings.vendorsDataBase.name },
        { key: 'description', label: strings.vendorsDataBase.description },
        { key: 'country', label: strings.vendorsDataBase.country, transform: 'renderCountry' },
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            showSearch: true,
            // tableData: [],
            tableData : [
            ],
            searchData : {
                search : '',
                total : 0,
                page : 1,
                perPage : 30
            },
            pageState: PageState.View,
            total: 100,
            errors: {},
            seeDetails: false
        };

        this.changeView = this.changeView.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    renderCountry(item){
        if (!item){
            return '';
        }

        return item.name;
    }

    fetchData() {

        getAllVendorsDatabases(this.state.searchData).then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                tableData: response.data.result,
                // searchData : {
                //     ...this.state.searchData,
                //     page : response.data.page,
                //     perPage : response.data.perPage,
                //     total : Math.ceil(response.data.total/response.data.perPage)
                // }
            })
        })
    }

    componentDidMount() {
        this.fetchData();
    }

    sortTable(param){
        let sortedData = sortTable(this.state.tableData, param);

        this.setState({
            tableData: sortedData
        })
    }

    renderSeeDetails(){
        return (
            <div className={'change-view'} onClick={this.changeView}>{ strings.vendorsDataBase.seeDetails }</div>
        );
    }

    getPageHeader() {
        return <h1>{ strings.vendorsDataBase.mainTitle }</h1>;
    }

    getPageDescription(){
        return <h3>{ strings.vendorsDataBase.pageDescription }</h3>
    }

    renderTableHeader() {

        let result = [];

        for(let item of this.tableDescription) {
            if(item.key !== 'productsAndPlans'){
                result.push(
                    <TableCell key={ 'table-header-' + result.length } onClick={() => this.sortTable(item.key)} style={{cursor: 'pointer'}}>
                        <div>
                            { item.label }
                            <div className={'sort-icon'}>
                                <img src={'/images/Polygon2.svg'} />
                                <img src={'/images/Polygon3.svg'} />
                            </div>
                        </div>
                    </TableCell>
                )
            }else {
                result.push(
                    <TableCell key={ 'table-header-' + result.length } style={{cursor: 'default'}}>
                        <div>
                            { item.label }
                        </div>
                    </TableCell>
                )
            }
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    { result }
                </TableRow>
            </TableHead>
        );
    }

    renderTableRow(data = []) {

        let result = [];

        for(let item of data) {

            let className = 'table-row';

            if(this.isRowSelected(item)) {
                className += ' selected';
            }

            result.push(
                <TableRow key={ 'table-row-' + result.length } className={ className } onClick={ () => this.selectRow(item) }>
                    { this.renderTableRowData(item) }
                </TableRow>
            )
        }

        return (
            <TableBody>
                { result }
            </TableBody>
        );
    }

    renderRowMenu(index, item) {
        return(
            <TableCell>
            </TableCell>
        );
    }

    changeView(){
        this.setState({
            seeDetails: true
        })
    }

    showTable(){
        return(
            <Grid id='services-table'>
                <div className='header'>
                    { this.getPageHeader() }
                    { this.getPageDescription() }
                </div>
                <div className='filter-controls'>

                    {
                        this.state.showSearch &&
                        <div className={'filter-wrapper'}>
                            <TextField
                                placeholder={ strings.table.search }
                                type="search"
                                name='search'
                                value={ this.state.searchData.search }
                                onChange={ this.searchChanged }
                                variant={'outlined'}
                                className={'filter-input'}
                            />
                        </div>
                    }

                    {
                        this.state.showAdd &&
                        this.renderTableControls()
                    }
                </div>
                <div className={'table-wrapper'} md={12}>
                    { this.renderTable(this.state.tableData) }
                </div>

                <Drawer id='drawer' anchor='right' open={  this.showDrawer() } onClose={ () => this.setPageState(PageState.View) } >
                    <DrawerWrapper onBack={ () => this.setPageState(PageState.View) }>
                        { this.renderDrawerContent() }
                    </DrawerWrapper>
                </Drawer>
            </Grid>
        );
    }

    render() {
        return (
            <div id={'services-table-page'}>
                <div className={'header-image'}
                     style={{backgroundImage: `url('/images/MaskGroup2.jpg')`}}
                >
                </div>
                { 
                    this.state.seeDetails
                    ?
                        this.state.selectedItem.product &&
                        <VendorsDataBaseDetails data={this.state.selectedItem} />
                    :
                    this.showTable()
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(VendorsDataBase)));