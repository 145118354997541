import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllCreditPackages(page, perPage) {
    return await request('/api/credit_packages/' + page + '/' + perPage, {}, HttpMethod.GET);
}

export async function editCreditPackage(data) {
    return await request('/api/credit_packages', data, HttpMethod.PUT);
}

export async function addCreditPackage(data) {
    return await request('/api/credit_packages', data, HttpMethod.POST);
}

export async function deleteCreditPackage(id) {
    return await request('/api/credit_packages/' + id, [], HttpMethod.DELETE);
}

export async function getCreditConfig() {
    return await request('/api/credit_config', [], HttpMethod.GET);
}

export async function editCreditConfig(data) {
    return await request('/api/credit_config', data, HttpMethod.PUT);
}