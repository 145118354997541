import React from 'react';
import strings from '../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../functions/Validation";
import {Divider} from "@material-ui/core";

function renderLoginForm(onSubmit, onChange, errors, data, keyPress, redirectToCreateAccount, onForgotPassword, forgotPassword){
    return (
        <div>
            <div className={ 'select-container' + (hasError(errors, 'email') ? ' error' : '') }>
                <TextField
                    label={ strings.login.email }
                    error={ hasError(errors, 'email') }
                    helperText={ getError(errors, 'email') }
                    fullWidth
                    name='email'
                    onChange={ onChange }
                    onKeyPress={ keyPress }
                    margin="normal"
                    value={ data.email }
                    variant={'outlined'}
                />
            </div>

            <div className={ 'select-container' + (hasError(errors, 'password') ? ' error' : '') }>
                <TextField
                    label={ strings.login.password }
                    error={ hasError(errors, 'password') }
                    helperText={ getError(errors, 'password') }
                    fullWidth
                    name='password'
                    type='password'
                    onChange={ onChange }
                    onKeyPress={ keyPress }
                    margin="normal"
                    value={ data.password }
                    variant={'outlined'}
                />
            </div>

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={ onSubmit }>
                    { strings.login.login }
                </Button>
            </div>
            <p className={'forgot-password'} onClick={onForgotPassword}>{strings.login.forgotPassword}</p>
            <Divider />
            <div className={'social-login'}>
                <div className={'fb-login'}>
                    <img src={'/images/facebook.svg'} />
                    <p>{ strings.login.fbLogin }</p>
                </div>
                <div className={'google-login'}>
                    <img src={'/images/search1.svg'} />
                    <p>{ strings.login.googleLogin }</p>
                </div>
            </div>

            <div className={'create-account-link'} onClick={redirectToCreateAccount}>
                { strings.login.createAccount }
            </div>
        </div>
    );
}

function renderForgotPasswordForm(onSubmit, onChange, errors, data, keyPress, resetPassword){
    return (
        <div className={'forgot-password-form'}>
            <h3>{strings.login.forgotPasswordMessage}</h3>

            <div className={ 'select-container' + (hasError(errors, 'email') ? ' error' : '') }>
                <TextField
                    label={ strings.login.email }
                    error={ hasError(errors, 'email') }
                    helperText={ getError(errors, 'email') }
                    fullWidth
                    name='email'
                    onChange={ onChange }
                    onKeyPress={ keyPress }
                    margin="normal"
                    value={ data.email }
                    variant={'outlined'}
                />
            </div>

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={ resetPassword }>
                    { strings.login.resetPassword }
                </Button>
            </div>
        </div>
    );
}

const LoginForm = ({
    onSubmit,
    onChange,
    errors,
    data,
    keyPress,
    redirectToCreateAccount,
    onForgotPassword,
    forgotPassword,
    resetPassword
}) => (
    <form id="login-form" onSubmit={ onSubmit } action = "#">
        {
            forgotPassword
            ?
            renderForgotPasswordForm(onSubmit, onChange, errors, data, keyPress, resetPassword)
            :
            renderLoginForm(onSubmit, onChange, errors, data, keyPress, redirectToCreateAccount, onForgotPassword, forgotPassword)
        }
    </form>
);

export default LoginForm;