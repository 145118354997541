import Navigation from "../components/admin/Navigation";
import Header from "../components/Header";
import AdminMobileNavigation from "../components/admin/AdminMobileNavigation";
import MobileHeader from "../components/MobileHeader";
import React from "react";

export function renderHeader(width, path){
    // check user permission
    if(width >= 768){
        return (
            isCurrentPath(path, '/admin') ? <Navigation /> : <Header />
        );
    }else {
        return (
            isCurrentPath(path, '/admin') ? <AdminMobileNavigation /> : <MobileHeader />
        );
    }
}

function isCurrentPath(path, url) {
    return path.includes(url);
}