import React from 'react'
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../constants/PageState';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Page from "../../common/Page";
import {Divider, TextField} from "@material-ui/core";

class SingleBlog extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                'id' : 1,
                'author' : 'Author',
                'title' : 'Cursus neque dignissim sit',
                'date' : '18/05/2020',
                'blogCoverImage' : '/images/image1.jpg',
                'content' : 'Commodo quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. \n' +
                    ' \n' +
                    '\n' +
                    'Auctor eu augue ut lectus arcu bibendum at varius vel. Sit amet risus nullam eget felis. Arcu non sodales neque sodales ut. Ut etiam sit amet nisl purus in. A cras semper auctor neque vitae. Tellus id interdum velit laoreet id. Vel facilisis volutpat est velit egestas dui. Id aliquet lectus proin nibh nisl. Elementum pulvinar etiam non quam lacus suspendisse. Odio tempor orci dapibus ultrices. Ut sem viverra aliquet eget sit. Duis at consectetur lorem donec massa sapien faucibus et. Sed tempus urna et pharetra pharetra massa massa ultricies. Tristique magna sit amet purus.\n' +
                    '\n' +
                    'Mattis vulputate enim nulla aliquet porttitor lacus luctus. Sit amet nisl suscipit adipiscing. Tincidunt tortor aliquam nulla facilisi cras fermentum odio eu feugiat. Adipiscing vitae proin sagittis nisl rhoncus. Sit amet purus gravida quis blandit turpis cursus in hac. Ultricies tristique nulla aliquet enim tortor at auctor urna. Velit aliquet sagittis id consectetur purus ut faucibus. Quis hendrerit dolor magna eget est lorem ipsum. Eget est lorem ipsum dolor sit amet consectetur adipiscing elit. Ut enim blandit volutpat maecenas volutpat blandit aliquam etiam erat.\n' +
                    '\n' +
                    'Lorem ipsum dolor sit amet consectetur adipiscing elit ut. Adipiscing elit duis tristique sollicitudin nibh sit amet commodo. Ornare arcu odio ut sem nulla. Erat pellentesque adipiscing commodo elit at. Urna nunc id cursus metus aliquam eleifend mi in nulla. Velit egestas dui id ornare arcu odio ut sem. Semper viverra nam libero justo. Et sollicitudin ac orci phasellus egestas tellus rutrum. Magna eget est lorem ipsum dolor sit amet. Porttitor massa id neque aliquam vestibulum. A iaculis at erat pellentesque adipiscing commodo. Quisque id diam vel quam.'
            },
            blogPosts: [
                {
                    'id' : 2,
                    'title' : 'Malesuada senectus libero scelerisque neque, sit mattis dignissim',
                    'src' : '/images/Rectangle129.jpg'
                },
                {
                    'id' : 3,
                    'title' : 'Malesuada senectus libero scelerisque neque, sit mattis dignissim',
                    'src' : '/images/Rectangle130.jpg'
                },
                {
                    'id' : 2,
                    'title' : 'Malesuada senectus libero scelerisque neque, sit mattis dignissim',
                    'src' : '/images/Rectangle129.jpg'
                },
                {
                    'id' : 3,
                    'title' : 'Malesuada senectus libero scelerisque neque, sit mattis dignissim',
                    'src' : '/images/Rectangle130.jpg'
                }
            ],
            pageState: PageState.View,
            errors: {},
        };
    }

    fetchData() {
        // this.setState({
        //     lockTable: true
        // });
    }

    componentDidMount() {
        this.fetchData();
    }

    renderBlogCard(blog){
        return(
            <div className={'blog-card'}>
                <img src={blog.src} />
                <p>{blog.title}</p>
            </div>
        );
    }

    renderPopularBlogs(){
        let result = [];

        for(let blog of this.state.blogPosts){
            result.push(this.renderBlogCard(blog));
        }

        return result;
    }

    render() {
        return (
            <div id={'single-blog'}>
                <div className={'header-image'}
                     style={{backgroundImage: `url('/images/MaskGroup3.jpg')`}}
                >
                </div>
                <Grid container md={24}>
                    <Grid item md={6} className={'single-blog-wrapper'}>
                        {/*<div>*/}
                            <h1>{ strings.singleBlog.news }</h1>
                            <Divider />
                            <h2> {this.state.data.title} </h2>
                            <div className={'social-wrapper'}>
                                <div>
                                    <div className={'author'}>
                                        {this.state.data.author},
                                    </div>
                                    <div className={'date'}>
                                        {this.state.data.date}
                                    </div>
                                </div>
                                <div className={'social'}>
                                    <a href={'/'}><img src={'/images/share1.svg'} /></a>
                                    <a href={'/'}><img src={'/images/facebook1.svg'} /></a>
                                    <a href={'/'}><img src={'/images/linkedin1.svg'} /></a>
                                    <a href={'/'}><img src={'/images/twitter1.svg'} /></a>
                                </div>
                            </div>
                            <img src={this.state.data.blogCoverImage} />
                            <p>{this.state.data.content}</p>
                        {/*</div>*/}
                    </Grid>
                    <Grid item md={3} className={'other-blogs-wrapper'}>
                        <TextField
                            fullWidth
                            placeholder={strings.singleBlog.search}
                            name='name'
                            onChange={ this.changeData }
                            margin="normal"
                            type="search"
                            value={ this.state.data.name }
                            className={'search'}
                            InputProps={{
                                disableUnderline: true
                            }}
                        />
                        <h3>{ strings.singleBlog.mostPopular }</h3>
                        {
                            this.renderPopularBlogs()
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleBlog)));