import React from 'react';
import strings from '../../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../../functions/Validation";
import DatePickerControl from "../../../controls/DatePickerControl";
import PageState from "../../../../constants/PageState";

const LawsAndRegulationsForm = ({
                              onSubmit,
                              onAdd,
                              onEdit,
                              onChange,
                                    fileName,
                                    dateSlash,
                              errors,
                              data,
                              keyPress,
                              openDialog,
                              uploadedFile,
                              pageState
                          }) => (
    <form id="admin-service-form" onSubmit={ onSubmit } action = "#">
        <div className={ 'select-container' + (hasError(errors, 'name') ? ' error' : '') }>
            <label>{ strings.adminCorStates.title }</label>
            <TextField
                error={ hasError(errors, 'name') }
                helperText={ getError(errors, 'name') }
                fullWidth
                name='name'
                onChange={ onChange }
                onKeyPress={ keyPress }
                margin="normal"
                value={ data.name }
                variant={'outlined'}
            />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'description') ? ' error' : '') }>
            <label>{ strings.adminCorStates.description }</label>
            <TextField
                error={ hasError(errors, 'description') }
                helperText={ getError(errors, 'description') }
                fullWidth
                name='description'
                onChange={ onChange }
                onKeyPress={ keyPress }
                margin="normal"
                value={ data.description }
                variant={'outlined'}
                className={'description'}
                multiline
                rows={9}
            />
        </div>



        <div className={ 'select-container' + (hasError(errors, 'date') ? ' error' : '') }>
            <label>{ strings.citLaw.date}</label>
            <DatePickerControl
                date={dateSlash(data.date)}
                name={'date'}
                onChange={ onChange }
                // dateFormat='MM/DD/yyyy'
                placeholder={'MM/DD/YYYY'}
                image={data.date ? '' : '/images/calendar1.svg'}
            />
        </div>

        <div className={'browse-button'}>
            <p>{ strings.adminCorStates.attachment }</p>
            <Button variant="contained" color="primary" onClick={openDialog}>
                { strings.adminCorStates.chooseFile }
            </Button>
        </div>
        <p className={'file-name'}>{uploadedFile ? uploadedFile.name : (fileName ? fileName : '')}</p>
        <p className={'upload-description'}>{ strings.adminCorStates.supportedFormats }</p>

        <div className='submit-container'>
            <Button variant="contained" onClick={ pageState === PageState.Add ? onAdd : onEdit }>
                { strings.adminCorStates.save}
            </Button>
        </div>
    </form>
);

export default LawsAndRegulationsForm;