import React, {createRef} from 'react'
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../constants/PageState';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Page from "../../common/Page";
import ContactUsForm from "../../components/forms/user/contactUsForm/ContactUsForm";
import Validators from "../../constants/ValidatorTypes";
import {contactUs} from "../../services/ContactFormService";

class ContactUs extends Page {

    validationList = {
        fullName: [{ type: Validators.REQUIRED }],
        email: [{ type: Validators.EMAIL }],
        phoneNumber: [{ type: Validators.IS_NUMBER }],
        messageSubject: [{ type: Validators.REQUIRED }],
        message: [{ type: Validators.REQUIRED }],
        agree: [{ type: Validators.REQUIRED }],
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            pageState: PageState.View,
            errors: {},
        };

        this.submit = this.submit.bind(this);
    }

    fetchData() {
        // this.setState({
        //     lockTable: true
        // });
    }

    componentDidMount() {
        this.fetchData();
    }

    submit(){
        if (!this.validate()) {
            return;
        }

        contactUs(this.state.data).then(response => {

            if(!response.ok) {

                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })
        });
    }

    render() {
        return (
            <div id={'contact-us'}>
                <div className={'left'}>
                    <div className={'text'}>
                        <h1>{ strings.contactUs.mainTitle }</h1>
                        <p>{ strings.contactUs.description }</p>
                    </div>
                    <img className={'desktop-image'} src={'/images/illustracija.svg'} />
                </div>
                <div className={'right'}>
                    <ContactUsForm
                        data={this.state.data}
                        errors={this.state.errors}
                        onSubmit={ this.submit }
                        onChange={this.changeData}
                        onCheckboxChange={this.changeCheckBox}
                    />
                </div>
                <div className={'mobile-image'}>
                    <img src={'/images/illustracija.svg'} />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactUs)));