import React, {createRef} from 'react'
import Grid from '@material-ui/core/Grid';
import Page from "../../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../../localization";
import Button from "@material-ui/core/Button";
import { InputAdornment, TextField} from "@material-ui/core";
import { ChromePicker } from 'react-color';
import {resetPasswordRequest} from "../../../services/UserService";
import {
    addCreditConfig,
    addCreditPackage,
    deleteCreditPackage, editCreditConfig,
    editCreditPackage,
    getAllCreditPackages, getCreditConfig
} from "../../../services/admin/CreatePackagesService";
import {withSnackbar} from "notistack";
import {getAllCorStates} from "../../../services/admin/CorStatesService";
import AccountTypes from "../../../constants/AccountTypes";


class AdminCreditPackages extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: [
            ],
            initialAmount: {
                'amount' : 0,
                'disabled' : true
            },
            openCreditsCheckoutModal: false,
            showColorPicker: false,
            disabled: true,
            queryData : {
                page : 1,
                perPage : 20
            }
        };

        this.addNewPackage = this.addNewPackage.bind(this);
        this.savePackage = this.savePackage.bind(this);
        this.editPackage = this.editPackage.bind(this);
        this.changeData = this.changeData.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        if (this.getUser() && (AccountTypes.ADMIN !== this.getUser().type)){
            this.props.history.push('/');
            return
        }

        this.fetchData();
    }

    fetchData() {
        getAllCreditPackages(this.state.queryData.page, this.state.queryData.perPage).then(response => {
            if (!response.ok) {
                return;
            }

            let creditPackages = response.data.result;

            creditPackages.forEach((item) => {
                item.disabled = true;
            });

            this.setState({
                data: creditPackages
            });
        });

        getCreditConfig().then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                initialAmount: {
                    amount : response.data.initial_amount,
                    disabled : true
                }
            });
        });
    }

    handleChangeComplete(color, item){
        item.color = color.hex;

        this.setState({
            item: item
        });
    }

    toggleColorPicker(item){
        item.showColorPicker = !item.showColorPicker;

        this.setState({
            item: item
        })
    }

    renderPackages() {
        let result = [];

        for(let item of this.state.data){
            result.push(
                <div className={'single-package card'}>
                    <div className={'columns-wrapper'}>
                        <div className={'package-column color-picker'}>
                            <label>{strings.creditPackages.color}</label>
                            <div className={item.disabled ? 'color disabled' : 'color'} style={{background: item.color ? item.color : '#39A4DD'}} onClick={() => this.toggleColorPicker(item)}>
                            </div>
                            {
                                item.showColorPicker
                                ?
                                <div>
                                    <div className={'chrome-picker-wrapper'} onClick={ () => this.toggleColorPicker(item) }/>
                                    <ChromePicker
                                        color={ item.color }
                                        onChangeComplete={ (color) => this.handleChangeComplete(color, item) }
                                        name={'color'}
                                        disableAlpha={true}
                                    />
                                </div>
                                :
                                null
                            }
                        </div>

                        <div className={'package-column'}>
                            <label>{strings.creditPackages.packageName}</label>
                            <TextField
                                name='name'
                                multiline
                                value={ item.name }
                                onChange={ (event) => this.changeData(event, item) }
                                disabled={item.disabled}
                                autoFocus
                                InputProps={{
                                    disableUnderline: (item.disabled)
                                }}
                            />
                        </div>

                        <div className={'package-column'}>
                            <label>{strings.creditPackages.credits}</label>
                            <TextField
                                name='credits'
                                value={ item.credits }
                                onChange={ (event) => this.changeData(event, item) }
                                disabled={item.disabled}
                                type={'number'}
                                InputProps={{
                                    disableUnderline: (item.disabled)
                                }}
                            />
                        </div>

                        <div className={'package-column'}>
                            <label>{strings.creditPackages.price}</label>
                            <TextField
                                name='price'
                                value={ item.price }
                                onChange={ (event) => this.changeData(event, item) }
                                disabled={item.disabled}
                                type={'number'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            €
                                        </InputAdornment>
                                    ),
                                    disableUnderline: (item.disabled)
                                }}
                            />
                        </div>

                        <div className={'package-column description'}>
                            <label>{strings.creditPackages.description}</label>
                            <TextField
                                name='description'
                                value={ item.description }
                                onChange={ (event) => this.changeData(event, item) }
                                disabled={item.disabled}
                                multiline
                                InputProps={{
                                    disableUnderline: (item.disabled)
                                }}
                            />
                        </div>
                    </div>

                    <div className={'buttons-wrapper'}>
                        {
                            item.disabled
                            ?
                            <Button className={'edit-button'} variant="contained" onClick={() => this.editPackage(item)}>
                                { strings.creditPackages.edit }
                            </Button>
                            :
                            <Button className={'save-button'} variant="contained" onClick={() => this.savePackage(item)}>
                                { strings.creditPackages.save }
                            </Button>
                        }
                        <Button className={'delete-button'} variant="contained" onClick={() => this.deletePackage(item)}>
                            { strings.creditPackages.delete }
                        </Button>
                    </div>
                </div>
            )
        }

        return result;
    }

    addNewPackage(){
        let newPackage = {};
        newPackage.new = true;

        this.setState({
            data : [...this.state.data, newPackage]
        });
    }

    editPackage(item){
        item.disabled = false;

        this.setState({
            item: item
        })
    }

    savePackage(item){
        if (!item.new){
            item.disabled = true;

            this.setState({
                item: item
            });

            editCreditPackage(item).then(response => {
                if (!response.ok) {
                    this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                    return;
                }

                this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })

                setTimeout(()=> {
                    this.fetchData();
                }, 1000)
            });
        }else{
            item.disabled = true;

            this.setState({
                item: item
            });

            item.color = item.color ? item.color : '#39A4DD';

            addCreditPackage(item).then(response => {
                if (!response.ok) {
                    this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                    return;
                }

                this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })

                setTimeout(()=> {
                    this.fetchData();
                }, 1000)
            });
        }
    }

    deletePackage(item){
        deleteCreditPackage(item.id).then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })

            setTimeout(()=> {
                this.fetchData();
            }, 1000)
        });
    }

    changeData(event, item){
        item[event.target.name] = event.target.value;

        this.setState({
            item: item
        })
    }

    addConfig(){
        editCreditConfig({
            amount : this.state.initialAmount.amount
        }).then(response => {
            if (!response.ok){
                return;
            }

            this.setState({
                initialAmount : {
                    amount : this.state.initialAmount.amount,
                    disabled : true
                }
            })
        })
    }

    editConfig(item){
        item.disabled = false;

        this.setState({
            item: item
        })
    }

    render() {
        return (
            <div id={'admin-credit-packages-wrapper'}>
                <Grid container spacing={24}>
                    <div>
                        <h3 className={'title'}>{ strings.creditPackages.initialAmountTitle }</h3>
                        <h3 className={'description'}>{ strings.creditPackages.initialAmountDescription }</h3>
                    </div>
                    <div className={'initial-amount-wrapper card'}>
                        <p>{ strings.creditPackages.initialAmount }</p>
                        <div className={'initial-amount-input'}>
                            <TextField
                                name='amount'
                                value={ this.state.initialAmount.amount }
                                onChange={ (event) => this.changeData(event, this.state.initialAmount) }
                                disabled={this.state.initialAmount.disabled}
                                type={'number'}
                                InputProps={{
                                    disableUnderline: (this.state.initialAmount.disabled)
                                }}
                            />
                            <div className={'buttons-wrapper'}>
                                <div className={'buttons-wrapper'}>
                                    {
                                        this.state.initialAmount.disabled
                                        ?
                                        <Button className={'edit-button'} variant="contained" onClick={() => this.editConfig(this.state.initialAmount)}>
                                            { strings.creditPackages.edit }
                                        </Button>
                                        :
                                        <Button className={'save-button'} variant="contained" onClick={() => this.addConfig(this.state.initialAmount)}>
                                            { strings.creditPackages.save }
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3 className={'title'}>{ strings.creditPackages.title }</h3>
                    <h3 className={'description'}>{ strings.creditPackages.mainDescription }</h3>
                    <div className={'packages-wrapper'}>
                        <div className={'package-card'}>
                            {
                                this.renderPackages()
                            }
                        </div>

                        <Button className={'add-button'} variant="contained" onClick={this.addNewPackage}>
                            { strings.creditPackages.addNewPackage }
                        </Button>
                    </div>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminCreditPackages)));
