import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid';
import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {Button, ListItemIcon, ListItemText, Menu, MenuItem} from "@material-ui/core";

class Buyer extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            buyerProcess: false,
            showMessage: false
        }

        this.props.changeFullScreen(false);
    }

    changeBuyerProcess(state){
        this.setState({
            buyerProcess: state
        });
    }

    buyNow() {
        this.setState({
            showMessage: true
        })
    }

    downloadFile(url, filename) {
        const a = document.createElement('a');
        a.href = url;
        a.download = filename; // This forces the browser to download the file
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up after the download
    }

    yes() {
        this.downloadFile('/images/TeamViewer.pdf', 'TeamViewer.pdf');
        this.downloadFile('/images/attachment.pdf', 'attachment.pdf');
        // window.open(`/images/TeamViewer.pdf`, '_blank');
        // window.open(`/images/attachment.pdf`, '_blank');
    }

    render() {
        return (
            <div id={'main-page'}>
                <Grid container spacing={24}>
                    <div style={{'minHeight': '80vh', 'max-width': '900px', 'margin': '0 auto', 'padding': '50px 0', 'width': '100%'}}>
                        {
                            !this.state.buyerProcess &&
                            <>
                                <p>
                                    Use our website plug-in so your buyers could automaticaly check, comply and save on taxes once providing data on geolocation!
                                </p>
                                <p>
                                    Click <span onClick={() => this.changeBuyerProcess(true)} style={{'cursor': 'pointer'}}><b>HERE</b></span> to simulate buying process from customer perspective:
                                </p>
                                <div style={{'height': '1px', 'width': '100%', 'max-width': '900px', 'background': 'black', 'opacity': '0.2', 'margin': '30px auto'}}>

                                </div>
                                <img src={'/images/Homepage.png'}/>
                                <p>
                                    Use our advanced search on Homepage (linkovan homepage na glavnu) to get all taxation info from our vendors, services type and countires database. We use compliance info from our affiliates to get best practies in regard of witholding tax, VAT, double avoiding taxation and other possible taxes or rules which could maximise saving trough ultimate efective pricing.
                                </p>
                            </>
                        }
                        {
                            this.state.buyerProcess &&
                            <>
                                <p>Teamviewer</p>

                                {
                                    !this.state.showMessage &&
                                    <>
                                        <p>Pay with your card</p>
                                        <img style={{margin: '20px 0'}} src={'/images/payment.png'}/>
                                        <div>
                                            <Button variant="contained" color="primary" onClick={ () => this.buyNow() }>
                                                Buy now
                                            </Button>
                                        </div>

                                    </>
                                }

                                {
                                    this.state.showMessage &&
                                    <>
                                        <p>
                                            Oooops, there may be some additional taxes in your home country, would you like to pay 3$ to comply and pay lowest taxes localy?
                                        </p>

                                        <Button variant="contained" color="primary" onClick={ () => this.yes() }>
                                            Yes
                                        </Button>
                                    </>
                                }
                            </>
                        }
                    </div>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

// export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Buyer));
