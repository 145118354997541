import React from 'react';
import strings from '../../../localization';
import {Button, TextField} from "@material-ui/core";
import {getError, hasError} from "../../../functions/Validation";
import AccountTypes from "../../../constants/AccountTypes";
import {checkAccountType} from "../../../util/AccountTypeUtil";

function renderPrivateEntityForm(data, errors, onChange){
    return(
        <div className={'private-entity-wrapper'}>
            <div className={ 'select-container' + (hasError(errors, 'name') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'name') }
                    helperText={ getError(errors, 'name') }
                    fullWidth
                    name='name'
                    onChange={ onChange }
                    value={ data.name }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.privateEntity.name}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'surname') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'surname') }
                    helperText={ getError(errors, 'surname') }
                    fullWidth
                    name='surname'
                    onChange={ onChange }
                    value={ data.surname }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.privateEntity.surname}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'email') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'email') }
                    helperText={ getError(errors, 'email') }
                    fullWidth
                    name='email'
                    onChange={ onChange }
                    value={ data.email }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.privateEntity.email}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'password') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'password') }
                    helperText={ getError(errors, 'password') }
                    fullWidth
                    name='password'
                    type='password'
                    onChange={ onChange }
                    value={ data.password }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.privateEntity.password}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'repeatedPassword') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'repeatedPassword') }
                    helperText={ getError(errors, 'repeatedPassword') }
                    fullWidth
                    name='repeatedPassword'
                    onChange={ onChange }
                    type='password'
                    value={ data.repeatedPassword }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.privateEntity.repeatedPassword}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'phone') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'phone') }
                    helperText={ getError(errors, 'phone') }
                    fullWidth
                    name='phone'
                    onChange={ onChange }
                    value={ data.phone }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.privateEntity.phone}
                    type={'number'}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'city') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'city') }
                    helperText={ getError(errors, 'city') }
                    fullWidth
                    name='city'
                    onChange={ onChange }
                    value={ data.city }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.privateEntity.city}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'address') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'address') }
                    helperText={ getError(errors, 'address') }
                    fullWidth
                    name='address'
                    onChange={ onChange }
                    value={ data.address }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.privateEntity.address}
                />
            </div>
        </div>
    );
}

function renderLegalEntityForm(data, errors, onChange){
    return(
        <div className={'legal-entity-wrapper'}>
            <div className={ 'select-container' + (hasError(errors, 'email') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'email') }
                    helperText={ getError(errors, 'email') }
                    fullWidth
                    name='email'
                    onChange={ onChange }
                    value={ data.email }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.privateEntity.email}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'name') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'name') }
                    helperText={ getError(errors, 'name') }
                    fullWidth
                    name='name'
                    onChange={ onChange }
                    value={ data.name }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.privateEntity.name}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'address') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'address') }
                    helperText={ getError(errors, 'address') }
                    fullWidth
                    name='address'
                    onChange={ onChange }
                    value={ data.address }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.legalEntity.address}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'location') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'location') }
                    helperText={ getError(errors, 'location') }
                    fullWidth
                    name='location'
                    onChange={ onChange }
                    value={ data.location }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.legalEntity.location}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'legalRegistrationNumber') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'legalRegistrationNumber') }
                    helperText={ getError(errors, 'legalRegistrationNumber') }
                    fullWidth
                    name='legalRegistrationNumber'
                    onChange={ onChange }
                    value={ data.legalRegistrationNumber }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.legalEntity.legalRegistrationNumber}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'pib') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'pib') }
                    helperText={ getError(errors, 'pib') }
                    fullWidth
                    name='pib'
                    onChange={ onChange }
                    value={ data.pib }
                    margin="dense"
                    variant='outlined'
                    placeholder={strings.legalEntity.pib}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'password') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'password') }
                    helperText={ getError(errors, 'password') }
                    fullWidth
                    name='password'
                    onChange={ onChange }
                    value={ data.password }
                    margin="dense"
                    type='password'
                    variant='outlined'
                    placeholder={strings.legalEntity.password}
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'repeatedPassword') ? ' error' : '') }>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={ hasError(errors, 'repeatedPassword') }
                    helperText={ getError(errors, 'repeatedPassword') }
                    fullWidth
                    name='repeatedPassword'
                    onChange={ onChange }
                    value={ data.repeatedPassword }
                    margin="dense"
                    variant='outlined'
                    type='password'
                    placeholder={strings.legalEntity.repeatedPassword}
                />
            </div>
        </div>
    );
}

const AccountForm = ({
                          onSubmit,
                          onChange,
                          onButtonPress,
                          data,
                          errors
                      }) => (
    <form id="account-form" onSubmit={ onSubmit } action = "#">
        {
            checkAccountType(data, AccountTypes.PRIVATE_ENTITY) &&
            renderPrivateEntityForm(data, errors, onChange)
        }
        {
            checkAccountType(data, AccountTypes.LEGAL_ENTITY) &&
            renderLegalEntityForm(data, errors, onChange)
        }
        {
            data.accountType &&
            <div className={'submit-container-wrapper'}>
                <div className='submit-container'>
                    <Button variant="contained" color="primary" onClick={ () => onSubmit(data.accountType)}>
                        { strings.register.createAccount }
                    </Button>
                </div>
                <div className={'wrapper'}>
                    <div className={'register-info'} onClick={ (event) => onButtonPress(event, 'accountType', data.accountType === AccountTypes.PRIVATE_ENTITY ? AccountTypes.LEGAL_ENTITY : AccountTypes.PRIVATE_ENTITY) }>
                        {
                            checkAccountType(data, AccountTypes.PRIVATE_ENTITY)
                            ?
                            strings.register.registerLegalEntity
                            :
                            strings.register.registerPrivateEntity
                        }
                    </div>
                </div>
            </div>
        }
    </form>
);

export default AccountForm;