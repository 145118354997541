import React, {createRef} from 'react';
import TablePage from "../../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../../constants/PageState';
import {
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import CONFIG from "../../../config";
import {sortTable} from "../../../util/DataUtil";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import UndoIcon from "@material-ui/icons/Undo";
import Button from "@material-ui/core/Button";
import ServicesModal from "../../../common/admin/ServicesModal";
import Dropzone from "react-dropzone";
import VendorsDataBaseForm from "../../../components/forms/admin/vendorsDataBase/VendorsDataBaseForm";
import PlanDetailsForm from "../../../components/forms/admin/vendorsDataBase/PlanDetailsForm";
import PlanDetailsSidebar from "../../../components/forms/admin/vendorsDataBase/PlanDetailsSidebar";
import {
    addLawsAndRegulations,
    deleteLawsAndRegulations,
    editLawsAndRegulations
} from "../../../services/admin/LawsAndRegulationsService";
import {
    addDecision,
    addType, addTypes,
    addVendorsDatabase,
    deleteVendorsDatabase,
    editVendorsDatabase, getAllVendorsDatabases, getDecisions
} from "../../../services/admin/VendorsDataBaseService";
import DropdownOptions from "../../../constants/DropdownOptions";
import {getAllCorStates} from "../../../services/admin/CorStatesService";
import {getCountry} from "../../../services/CountryService";
import update from "immutability-helper";
import AccountTypes from "../../../constants/AccountTypes";
import {addDocument} from "../../../services/DocumentService";
import ReferenceTypes from "../../../constants/ReferenceTypes";

class AdminVendorsDataBase extends TablePage {

    tableDescription = [
        { key: 'name', label: strings.vendorsDataBase.name },
        { key: 'description', label: strings.vendorsDataBase.description },
        { key: 'website_link', label: strings.vendorsDataBase.websiteLink, transform: 'renderWebsiteLink' },
        { key: 'website_pricing', label: strings.vendorsDataBase.websitePricing, transform: 'renderWebsitePricing' },
        // { key: 'products', label: strings.vendorsDataBase.plan, transform: 'renderPlanDetails' },
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: {
                products: [{
                    plans: []
                }]
            },
            showSearch: true,
            addNewTypeDialog: false,
            addWTHDecision: false,
            showAdd: true,
            // tableData: [],
            tableData : [
            ],
            pageState: PageState.View,
            total: 100,
            errors: {},
            selectedItem: {},
            openModal: false,
            openPlanDetailsModal: false,
            uploadedFile: null,
            types: [
            ],
            deletedProducts: [
            ],
            deletedPlans: [
            ],
            decisions: [
            ],
            countries : [],
            searchData : {
                search : '',
                total : 0,
                page : 1,
                perPage : 30
            }
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.togglePlanDetailsModal = this.togglePlanDetailsModal.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.addNew = this.addNew.bind(this);
        this.openAddNewDialog = this.openAddNewDialog.bind(this);
        this.closeAddNewDialog = this.closeAddNewDialog.bind(this);
        this.addNewProduct = this.addNewProduct.bind(this);
        this.changeProduct = this.changeProduct.bind(this);
        this.changePlan = this.changePlan.bind(this);
        this.addNewPlan = this.addNewPlan.bind(this);
        this.selectPlan = this.selectPlan.bind(this);
        this.add = this.add.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.edit = this.edit.bind(this);
        this.removePlan = this.removePlan.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.dropzoneRef = createRef();
    }

    fetchData() {
        getAllVendorsDatabases(this.state.searchData).then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                ...this.state,
                tableData: response.data.result,
                searchData : {
                    ...this.state.searchData,
                    page : response.data.page,
                    perPage : response.data.perPage,
                    total : Math.ceil(response.data.total/response.data.perPage)
                }
            })
        })

        getCountry().then(response => {
            if (!response.ok){
                return;
            }

            this.setState({
                ...this.state,
                countries : response.data.result
            })
        })

        getDecisions().then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                decisions: response.data
            });
        });

        addTypes().then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                types: response.data
            });
        });
    }

    componentDidMount() {
        if (this.getUser() && (AccountTypes.ADMIN !== this.getUser().type)){
            this.props.history.push('/');
            return
        }

        this.fetchData();
    }

    renderPlanDetails(item){
        // if(!item){
        //     return '';
        // }

        for(let item of this.state.tableData){
            // console.log(item)
            if(item.products){
                return(
                    <div style={{cursor: 'pointer'}} onClick={ () => this.togglePlanDetailsModal(this.state.tableData[item]) }>{strings.vendorsDataBase.seePlanDetails}</div>
                );
            }
        }
    }

    renderWebsiteLink(item){
        if(!item){
            return '';
        }

        return(
            <a href={item}>{strings.vendorsDataBase.link}</a>
        );
    }

    renderWebsitePricing(item){
        if(!item){
            return '';
        }

        return(
            <a href={item}>{strings.vendorsDataBase.link}</a>
        );
    }

    sortTable(param){
        let sortedData = sortTable(this.state.tableData, param);

        this.setState({
            tableData: sortedData
        })
    }

    getPageHeader() {
        return <h1>{ strings.vendorsDataBase.mainTitle }</h1>;
    }

    getPageDescription(){
        return <h3>{ strings.vendorsDataBase.pageDescription }</h3>
    }

    renderTableHeader() {

        let result = [];

        for(let item of this.tableDescription) {
            if((item.key !== 'plan') && (item.key !== 'website_link') && (item.key !== 'website_pricing')){
                result.push(
                    <TableCell key={ 'table-header-' + result.length } onClick={() => this.sortTable(item.key)} style={{cursor: 'pointer'}}>
                        <div>
                            { item.label }
                            <div className={'sort-icon'}>
                                <img src={'/images/Polygon2.svg'} />
                                <img src={'/images/Polygon3.svg'} />
                            </div>
                        </div>
                    </TableCell>
                )
            }else {
                result.push(
                    <TableCell key={ 'table-header-' + result.length } style={{cursor: 'default'}}>
                        <div>
                            { item.label }
                        </div>
                    </TableCell>
                )
            }
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    { result }
                    <TableCell>
                        {/*{ strings.table.actions }*/}
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    renderTableRowData(item) {

        let result = [];

        for(let description of this.tableDescription) {
            if(description.label !== 'Download'){
                result.push(
                    <TableCell key={ 'table-data-' + description.key + '-' + result.length }>
                        {
                            description.transform !== undefined &&
                            this[description.transform](item[description.key])
                        }
                        {
                            description.transform === undefined &&
                            item[description.key]
                        }
                    </TableCell>
                );
            }else {
                result.push(
                    <TableCell key={ 'table-data-' + description.key + '-' + result.length } style={{textAlign: 'center'}}>
                        {
                            description.transform !== undefined &&
                            this[description.transform](item[description.key])
                        }
                        {
                            description.transform === undefined &&
                            item[description.key]
                        }
                    </TableCell>
                );
            }
        }

        return result;
    }

    renderTableControls() {
        return [
            <Button variant="contained" color="secondary"
                    onClick={ () => this.toggleModal() }
            >
                { strings.table.addDocument }
            </Button>
        ]
    }

    renderRowMenu(index, item) {

        let ariaOwns = 'action-menu-' + index;

        return(
            <TableCell>
                <IconButton
                    aria-owns={ this.state.anchorEl ? ariaOwns : undefined }
                    aria-haspopup="true"
                    onClick={ (event) => this.handleMenuClick(event, ariaOwns) }
                >
                    <MoreVert/>
                </IconButton>
                {
                    ariaOwns === this.state.ariaOwns &&
                    <Menu
                        id={ ariaOwns }
                        anchorEl={ this.state.anchorEl }
                        open={ Boolean(this.state.anchorEl) }
                        onClose={ () => this.handleMenuClose() }
                        className={'table-additional-menu'}
                    >
                        <div className={'menu-header'}>
                            {  strings.table.additionalOptions}
                        </div>
                        <MenuItem onClick={ () => this.toggleModal(item) }>
                            <ListItemIcon>
                                <EditIcon/>
                            </ListItemIcon>
                            <ListItemText inset primary={ strings.table.edit }/>
                        </MenuItem>
                        {
                            !item[this.deletedField] &&
                            <MenuItem onClick={ () => this.delete(item) }>
                                <ListItemIcon>
                                    <DeleteIcon/>
                                </ListItemIcon>
                                <ListItemText inset primary={ strings.table.delete }/>
                            </MenuItem>
                        }
                        {
                            item[this.deletedField] &&
                            <MenuItem onClick={ () => this.handleRestore(item) }>
                                <ListItemIcon>
                                    <UndoIcon/>
                                </ListItemIcon>
                                <ListItemText inset primary={ strings.table.undo }/>
                            </MenuItem>
                        }

                    </Menu>
                }

            </TableCell>
        );
    }

    toggleModal(item) {
        this.setState({
            openModal: !this.state.openModal,
            pageState: item ? PageState.Edit : PageState.Add
        });

        if (item && item.products){
            for (let product of item.products){
                if (!product.plans){
                    continue;
                }

                if (product.plans[0]){
                    product.plans[0].selected = true;
                }
            }
        }

        this.setState({
            data: item ? item : {
                products: item ? item.products : [
                    {
                        plans: [{
                            "id" : 1,
                            'new' : true,
                            'title' : 'New plan 1',
                            'selected' : true
                        }]
                    }
                ]
            },
        });

        this.handleMenuClose();
    }

    togglePlanDetailsModal(item) {
        this.setState({
            openPlanDetailsModal: !this.state.openPlanDetailsModal,
            data: item ? item : {}
        });
    }

    openDialog(key, id) {
        this.state.productKeyUpload = key;
        this.state.productIdUpload = id;

        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
    };

    openAddNewDialog(name){
        this.setState({
            [name]: true
        });
    }

    closeAddNewDialog(name){
        this.setState({
            [name]: false
        });
    }

    addNewProduct(){
        let newProduct = {
            plans: [{
                'new' : true,
                "id" : 1,
                'title' : 'New plan 1',
                'selected' : true
            }]
        }

        !this.state.data.products ? this.state.data.products = [] : this.state.data.products;

        this.state.data.products.push(newProduct);

        this.setState({
            data : this.state.data
        });
    }

    changeProduct(event, id){
        this.state.data.products[id][event.target.name] = event.target.value;

        this.setState({
            data: this.state.data
        });
    }

    addNewPlan(key){
        let products = this.state.data.products[key];

        let iterator = products.plans ? products.plans.length + 1 : 1;

        let newPlan = {
            'new' : true,
            'id' : iterator,
            'name' : 'New plan ' + iterator,
            'price' : '',
            'description' : '',
            'selected' : true
        };

        if(products.plans){
            products.plans.push(newPlan);
        }else {
            let plans = [];
            plans.push(newPlan);
            products.plans = plans;
        }

        this.setState({
            data: this.state.data
        });
    }

    selectPlan(plan, key){
        let plans = this.state.data.products[key].plans;

        for(let item of plans){
            item.selected = item.id === plan.id;
        }

        this.setState({
            data: this.state.data
        })
    }

    checkTarget(name){
        if (name === 'type'){
            return 'vendor_plan_type';
        }

        return name;
    }

    changePlan(event, productId, planId){
        let plans = this.state.data.products[productId].plans ? this.state.data.products[productId].plans : [];


        for(let plan of plans){
            if(plan.id === planId){
                plan[this.checkTarget(event.target.name)] = event.target.value;
            }
        }

        this.forceUpdate()
    }

    addNew(event, options, data, dataNamesArray, key, selectedPlan, dropdownName){
        event.preventDefault();

        let plans = this.state.data.products[key].plans ? this.state.data.products[key].plans : [];

        for(let plan of plans){
            if(plan.id === selectedPlan){
                if(!plan[dataNamesArray[0]] || !plan[dataNamesArray[1]]){
                    return '';
                }

                let newObj = {
                    name: plan[dataNamesArray[0]],
                    value: plan[dataNamesArray[1]]
                };

                plan[dropdownName] = newObj;

                this.state[options].push(newObj);

                this.setState({
                    [options] : this.state[options]
                });
            }
        }

        if(options == DropdownOptions.TYPES){
            this.addType(this.state[options]);
        }else if(options == DropdownOptions.DECISIONS){
            this.addDecision(this.state[options]);
        }
    }

    addType(options){
        addType(options).then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })

            this.fetchData();

            this.setState({
                ...this.state,
                addNewTypeDialog : false
            });
        });
    }

    removeProduct(event, key){
        let products = this.state.data.products;
        let deletedProducts = this.state.deletedProducts;

        if (!products[key].id){
            return;
        }

        deletedProducts.push(products[key].id)

        products.splice(key, 1);

        this.setState({
           ...this.state,
            deletedProducts : deletedProducts,
            data : {
                ...this.state.data,
                products : products
            }
        });
    }

    removePlan(event, key, position){
        let plans = this.state.data.products[key].plans;
        let deletedPlans = this.state.deletedPlans;

        if (!plans[position].id){
            return;
        }

        deletedPlans.push(plans[position].id)

        plans.splice(position, 1);
        this.state.data.products[key].plans = plans;

        this.setState({
           ...this.state,
            deletedPlans : deletedPlans,
            data : this.state.data
        });
    }

    addDecision(options){
        addDecision(options).then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })

            this.fetchData();

            this.setState({
                ...this.state,
                addWTHDecision : false
            });
        });
    }

    // changeData(event, data = 'data') {
    //     if (this.state.pageState === PageState.Edit){
    //         this.state.selectedItem[event.target.name] = event.target.value;
    //         this.setState({...this.state})
    //     }else{
    //         this.setState({
    //             [data]: update(this.state[data], { [event.target.name]: {$set: event.target.value} })
    //         });
    //     }
    // }

    modalRender() {
        return (
            <ServicesModal
                toggleModal={this.toggleModal}
                open={this.state.openModal}
                // open={true}
                deleteFile={this.deleteFile}
                onChange={this.changeData}
                data={this.state.data}
                openDialog={this.openDialog}
                modalTitle={strings.vendorsDataBase.mainTitle}
                showHeader={true}
                form={
                    <VendorsDataBaseForm
                        countries={this.state.countries}
                        data={this.state.data}
                        openDialog={this.openDialog}
                        uploadedFile={this.state.uploadedFile}
                        addNewTypeDialogOpen={this.state.addNewTypeDialog}
                        addWTHDecisionDialogOpen={this.state.addWTHDecision}
                        handleAdd={this.addNew}
                        handleOpen={this.openAddNewDialog}
                        handleClose={this.closeAddNewDialog}
                        onChange={this.changeData}
                        removeProduct={this.removeProduct}
                        removePlan={this.removePlan}
                        changeProduct={this.changeProduct}
                        changePlan={this.changePlan}
                        selectPlan={this.selectPlan}
                        selectedPlan={this.state.selectedPlan}
                        types={this.state.types}
                        decisions={this.state.decisions}
                        addProduct={this.addNewProduct}
                        addPlan={this.addNewPlan}
                        onAdd={this.add}
                        onEdit={this.edit}
                        pageState={this.state.pageState}
                    />
                }
                content={
                    <div className={'dropzone-wrapper'}>
                        <Dropzone
                            onDrop={this.onDrop}
                            multiple={true}
                            ref={this.dropzoneRef}
                            accept={CONFIG.acceptFiles}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div>
                                    <section className={'file-dropzone'}>
                                        <div {...getRootProps()} className={'cursor-pointer'}>
                                            <input {...getInputProps()} />
                                        </div>
                                    </section>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                }
            />
        );
    }

    planDetailsModalRender() {
        return (
            <ServicesModal
                toggleModal={this.togglePlanDetailsModal}
                open={this.state.openPlanDetailsModal}
                // open={true}
                deleteFile={this.deleteFile}
                onChange={this.changeData}
                data={this.state.data}
                // openDialog={this.openDialog}
                modalTitle={strings.vendorsDataBase.mainTitle}
                planDetailsTitle={strings.vendorsDataBase.planDetailsTitle}
                showHeader={false}
                form={
                    <PlanDetailsForm
                        title={strings.vendorsDataBase.planDetailsTitle}
                    />
                }
                sidebar={
                    <PlanDetailsSidebar
                        data={this.state.selectedItem}
                    />
                }
            />
        );
    }

    onDrop(acceptedFile) {

        let plans = this.state.data.products[this.state.productKeyUpload].plans ? this.state.data.products[this.state.productKeyUpload].plans : [];

        for(let plan of plans){
            if(plan.id === this.state.productIdUpload){
                plan['file'] = acceptedFile[0];
            }
        }

        // this.forceUpdate()
        this.setState({
            ...this.state,
            data : {
                ...this.state.data
            }
        })

        // let applicationFile = new FormData();
        // applicationFile.append('application', this.state.application.id);
        // applicationFile.append('name', acceptedFile.name);
        // applicationFile.append('content', acceptedFile);
        // applicationFile.append('fileType', acceptedFile.type);
        // applicationFile.append('type', this.state.fileUploadModal);

        // uploadFiles(applicationFile).then((result) => {
        //     let files = this.state.files;
        //
        //     files.push(result.data);
        //
        //     this.setState({
        //         files: files
        //     });
        // });
    }

    add(){
        addVendorsDatabase(this.state.data).then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            this.state.data.products.forEach(product => {
                let plans = product.plans

                let index = 0;
                plans.forEach(plan => {
                    if (plan.file){
                        let applicationFile = new FormData();
                        applicationFile.append('file', plan.file);
                        applicationFile.append('name', plan.file ? plan.file.name : '');
                        applicationFile.append('referenceType', ReferenceTypes.VENDOR_PLANS);
                        applicationFile.append('referenceId', response.data.planReturn[index].id);
                        index++;

                        addDocument(applicationFile).then(responseFile => {
                            if (!responseFile.ok){
                                return;
                            }

                            // setTimeout(() => {
                            //     this.fetchData();
                            // }, 1000)
                        })
                    }
                })
            })

            setTimeout(()=> {
                this.fetchData();
                this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })
            }, 1000)
        });

        this.toggleModal(this.state.data);
    }

    edit(){
        this.state.data.deletedProducts = this.state.deletedProducts
        this.state.data.deletedPlans = this.state.deletedPlans

        editVendorsDatabase(this.state.data).then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            this.state.data.products.forEach(product => {
                let plans = product.plans

                let index = 0;
                plans.forEach(plan => {
                    if (plan.file){
                        let applicationFile = new FormData();
                        applicationFile.append('file', plan.file);
                        applicationFile.append('name', plan.file ? plan.file.name : '');
                        applicationFile.append('referenceType', ReferenceTypes.VENDOR_PLANS);
                        applicationFile.append('referenceId', response.data.planReturn[index].id);
                        index++;

                        addDocument(applicationFile).then(responseFile => {
                            if (!responseFile.ok){
                                return;
                            }

                            // setTimeout(() => {
                            //     this.fetchData();
                            // }, 1000)
                        })
                    }
                })

                // this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })
                //
                // this.fetchData();
            })

            this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })

            this.fetchData();
        });

        this.toggleModal(this.state.data);
    }

    delete(){
        deleteVendorsDatabase(this.state.selectedItem.id).then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            setTimeout(() => {
                this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })
                this.fetchData();
            }, 1000)
        });
    }

    render() {
        let modal = this.modalRender();
        let planDetailsModal = this.planDetailsModalRender();

        return (
            <div id={'admin-table-page'}>
                <Grid id='admin-table'>
                    <div className='header'>
                        { this.getPageHeader() }
                        { this.getPageDescription() }
                    </div>
                    <div className='filter-controls'>

                        {
                            this.state.showSearch &&
                            <div className={'filter-wrapper'}>
                                <TextField
                                    placeholder={ strings.table.search }
                                    type="search"
                                    name='search'
                                    value={ this.state.searchData.search }
                                    onChange={ this.searchChanged }
                                    variant={'outlined'}
                                    className={'filter-input'}
                                />
                            </div>
                        }

                        {
                            this.state.showAdd &&
                            this.renderTableControls()
                        }
                    </div>
                    <div className={'table-wrapper'} md={12}>
                        { this.renderTable(this.state.tableData) }
                    </div>
                    {modal}
                    {planDetailsModal}
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminVendorsDataBase)));