export function sortTable(data, param){
    return data.sort((a, b) => {
        return ('' + a[param]).localeCompare(b[param]);
    });
}

export function findItem(id, data) {

    for(let item of data) {
        if(item.id == id) {
            return item;
        }
    }

    return null;
}

export const cutDateAfterTime = (date) => {
    var s = date;
    var n = s.indexOf('T');
    s = s.substring(0, n != -1 ? n : s.length);
    return s;
}