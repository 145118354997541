import React from 'react';
import strings from '../../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../../functions/Validation";

function renderLoginForm(onSubmit, onChange, errors, data, keyPress, onForgotPassword){
    return(
        <div>
            <div className={ 'select-container' + (hasError(errors, 'email') ? ' error' : '') }>
                <TextField
                    label={ strings.login.email }
                    error={ hasError(errors, 'email') }
                    helperText={ getError(errors, 'email') }
                    fullWidth
                    name='email'
                    onChange={ onChange }
                    onKeyPress={ keyPress }
                    margin="normal"
                    value={ data.email }
                    variant={'outlined'}
                />
            </div>

            <div className={ 'select-container' + (hasError(errors, 'password') ? ' error' : '') }>
                <TextField
                    label={ strings.login.password }
                    error={ hasError(errors, 'password') }
                    helperText={ getError(errors, 'password') }
                    fullWidth
                    name='password'
                    type='password'
                    onChange={ onChange }
                    onKeyPress={ keyPress }
                    margin="normal"
                    value={ data.password }
                    variant={'outlined'}
                />
            </div>

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={ onSubmit }>
                    { strings.login.login }
                </Button>
            </div>
            <p className={'forgot-password'} onClick={onForgotPassword} >{strings.login.forgotPassword}</p>
        </div>
    );
}

function renderForgotPassword(onSubmit, onChange, errors, data, keyPress, resetPassword){
    return (
        <div className={'forgot-password-form'}>
            <h3>{strings.login.forgotPasswordMessage}</h3>

            <div className={ 'select-container' + (hasError(errors, 'email') ? ' error' : '') }>
                <TextField
                    label={ strings.login.email }
                    error={ hasError(errors, 'email') }
                    helperText={ getError(errors, 'email') }
                    fullWidth
                    name='email'
                    onChange={ onChange }
                    onKeyPress={ keyPress }
                    margin="normal"
                    value={ data.email }
                    variant={'outlined'}
                />
            </div>

            <div className='submit-container'>
                <Button variant="contained" color="primary" onClick={ resetPassword }>
                    { strings.login.resetPassword }
                </Button>
            </div>
        </div>
    );
}

const AdminLoginForm = ({
                       onSubmit,
                       onChange,
                       errors,
                       data,
                       keyPress,
                       onForgotPassword,
                       forgotPassword,
                       resetPassword
                   }) => (
    <form id="admin-login-form" onSubmit={ onSubmit } action = "#">
        {
            forgotPassword
            ?
            renderForgotPassword(onSubmit, onChange, errors, data, keyPress, resetPassword)
            :
            renderLoginForm(onSubmit, onChange, errors, data, keyPress, onForgotPassword)
        }
    </form>
);

export default AdminLoginForm;