import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";

export async function resetPasswordRequest(data) {
    return await request('/api/users/password/reset', data, HttpMethod.POST);
}

export async function resetPassword(data) {
    return await request('/api/users/password/reset_form', data, HttpMethod.POST);
}

export async function register(data) {
    return await request('/api/users/register', data, HttpMethod.POST);
}

export async function editProfile(data) {
    return await request('/api/users/edit_profile', data, HttpMethod.PUT);
}

export async function changePassword(data) {
    return await request('/api/users/reset_password', data, HttpMethod.PUT);
}

export async function getUser(id) {
    return await request('/api/users/get_user', {id}, HttpMethod.GET);
}

export async function sendEmail(data) {
    return await request('/api/send_email', data, HttpMethod.POST);
}

export async function getEmail(data) {
    return await request('/api/send_email', data, HttpMethod.GET);
}

export async function deleteEmail(id) {
    return await request('/api/delete_email/' + id, {}, HttpMethod.DELETE);
}