import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import connect from "react-redux/es/connect/connect";
import {Menu} from "@material-ui/core";
import strings from "../../localization";
import {lock, logout} from "../../base/OAuth";

class AdminMobileNavigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showMenu: null,
            credits: null,
            lawsAndRegulations: null
        }
    }

    handleMenuClick(event, menu) {
        this.setState({
            [menu]: this.state[menu] !== null ? null : event.currentTarget
        });
    }

    handleMenuClose(menu) {
        this.setState({
            [menu]: null
        });
    }

    logout() {
        logout();
        this.props.logout();
        this.props.history.push('/');
    }

    lock() {
        lock();
        this.props.history.push('/');
    }

    toggleMenu(){
        this.setState({
            showMenu: !this.state.showMenu
        })
    }

    render() {
        return (
            <div id='mobile-header'>
                <div className={'left'}>
                    <img src={'/images/logo.png'}/>
                    <Menu className={'menu-wrapper admin-mobile-header'}
                          open={this.state.showMenu}
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        <div className={'submenu-wrapper'}
                             onClick={ (event) => this.handleMenuClick(event, 'credits') }
                        >
                            <p>{ strings.navigation.credits }</p>
                            <img src={'/images/Polygon1.svg'} />
                        </div>
                        <div className={ this.state.credits ? ' show submenu' : 'submenu'}>
                            <a href={'/admin/credit-packages'}>{ strings.navigation.creatingCreditPackage }</a>
                            <a href={'/admin/payment-process-approval'}>{ strings.navigation.paymentProcessApproval }</a>
                        </div>
                        <a href={'/'}><p>{ strings.navigation.news }</p></a>
                        <div className={'submenu-wrapper'}
                             onClick={ (event) => this.handleMenuClick(event, 'lawsAndRegulations') }
                        >
                            <p>{ strings.navigation.lawsAndRegulations }</p>
                            <img src={'/images/Polygon1.svg'} />
                        </div>
                        <div className={ this.state.lawsAndRegulations ? ' show submenu' : 'submenu'}>
                            <a href={'/admin/laws-and-regulations?type=1'}>{ strings.navigation.citLaw }</a>
                            <a href={'/admin/laws-and-regulations?type=2'}>{ strings.navigation.vatLaw }</a>
                            <a href={'/admin/laws-and-regulations?type=3'}>{ strings.navigation.opinionOfMinistryOfFinance }</a>
                        </div>
                        <a href={'/admin/cor-states'}><p>{ strings.navigation.corStates }</p></a>
                        <a href={'/admin/cor-vendors'}><p>{ strings.navigation.corVendors }</p></a>
                        <a href={'/admin/vendors-data-base'}><p>{ strings.navigation.vendorsDataBase }</p></a>
                        <a href={'/admin/dtt-countries'}><p>{ strings.navigation.dttCountry }</p></a>
                        <a href={'/admin/vat-proxies'}><p>{ strings.navigation.vatProxy }</p></a>
                        <a href={'/admin/guest-search'}><p>{ strings.navigation.listOfGuestUsersSearch }</p></a>
                        <a href={'/'}><p>{ strings.navigation.logout }</p></a>
                    </Menu>
                </div>
                <div className={'right'}>
                    {
                        this.state.showMenu
                            ?
                            <img src={'/images/x.svg'} onClick={ () => this.toggleMenu() }/>
                            :
                            <img src={'/images/hamburger.svg'} onClick={ () => this.toggleMenu() }/>
                    }
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeMenuState: Actions.changeMenuState,
        logout: Actions.logout,
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminMobileNavigation));
