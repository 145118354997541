import React, {Component} from 'react'

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {makeStyles, TextField} from "@material-ui/core";

class Services extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: [
                {
                    'link' : '/cor-states',
                    'src' : '/images/corstates.png',
                    'name' : 'Invoicing with auto tax solution - taxvoice'
                },
                {
                    'link' : '/cor-vendors',
                    'src' : '/images/corvendors.png',
                    'name' : 'Global tax compliance'
                },
                {
                    'link' : '/vendors-data-base',
                    'src' : '/images/vendorsdatabase.png',
                    'name' : 'International tax advisory'
                },
                {
                    'link' : '/dtt-countries',
                    'src' : '/images/dttcountries.png',
                    'name' : 'Risk assesment'
                },
                {
                    'link' : '/vat-proxies',
                    'src' : '/images/vatproxies.png',
                    'name' : 'Tax planing and decisioning'
                }
            ],
        }

        this.props.changeFullScreen(false);
    }

    renderSingleCard(item){
        return(
            <div className={'service-card'}>
                <img src={item.src}/>
                <div>
                    <a href={item.link}>{item.name}</a>
                </div>
            </div>
        );
    }

    renderServiceCards(data){

        let result = [];

        for(let item of data){
            result.push(this.renderSingleCard(item));
        }

        return result;
    }

    render() {
        return (
            <div>
                <div className={'services-wrapper'}>
                    <Grid container spacing={24}>
                        <div className={'services-image'}
                             style={{backgroundImage: `url('/images/Chart.svg')`}}
                        >
                            <h1>{ strings.services.mainTitle }</h1>
                            <p>{ strings.services.description }</p>
                        </div>
                    </Grid>
                </div>
                <Grid container spacing={24} className={'service-cards-wrapper'}>
                    <div className={'service-cards'}>
                        {
                            this.renderServiceCards(this.state.data)
                        }
                    </div>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Services));
