// LIVE

let CONFIG = {
    baseUrlAPI: 'http://aktiva-tax-api.lilly021-dev.com/api/',
    baseUrl: 'http://aktiva-tax-api.lilly021-dev.com/',
    tokenKey: '1stKgorpnoj8mFIsI3sg',
    refreshTokenKey: 'ZJmD63XQFsK6cHUWwN5T',
    clientId: 'cBkYbyWsC7waUOhp9O5H',
    clientSecret: 'hmWkbTZH6TOIlc9NLgw8',
    facebookAppId: 'FACEBOOK_APP_ID',
    facebookAppSecret: 'FACEBOOK_APP_SECRET',
    googleClientId: 'GOOGLE_CLIENT_ID',
    googleClientSecret: 'GOOGLE_CLIENT_SECRET',
    linkedInClientId: 'LINKEDIN_CLIENT_ID',
    linkedInCliendSecret: 'LINKEDIN_CLIENT_SECRET',
    rowsPerPage: [ 5, 10, 25 ],
    rolesAllowed: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
    acceptFiles: ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
};

// LOCAL
// let CONFIG = {
//     baseUrlAPI: 'http://localhost:8000/api/',
//     baseUrl: 'http://localhost:8000/',
//     tokenKey: '1stKgorpnoj8mFIsI3sg',
//     refreshTokenKey: 'ZJmD63XQFsK6cHUWwN5T',
//     clientId: 'client_id',
//     clientSecret: 'client_secret',
//     facebookAppId: 'FACEBOOK_APP_ID',
//     facebookAppSecret: 'FACEBOOK_APP_SECRET',
//     googleClientId: 'GOOGLE_CLIENT_ID',
//     googleClientSecret: 'GOOGLE_CLIENT_SECRET',
//     linkedInClientId: 'LINKEDIN_CLIENT_ID',
//     linkedInCliendSecret: 'LINKEDIN_CLIENT_SECRET',
//     rowsPerPage: [ 5, 10, 25 ],
//     rolesAllowed: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
//     acceptFiles: ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
// };

export default CONFIG;