import React from 'react';
import strings from '../../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../../functions/Validation";
import PageState from "../../../../constants/PageState";

const CorStatesForm = ({
                            onSubmit,
                            onChange,
                            errors,
                            data,
                            keyPress,
                            openDialog,
                           fileName,
                            uploadedFile,
                            pageState,
                            onAdd,
                            onEdit
                        }) => (
    <form id="admin-service-form" onSubmit={ onSubmit } action = "#">
        <div className={ 'select-container' + (hasError(errors, 'name') ? ' error' : '') }>
            <label>{ strings.adminCorStates.title }</label>
            <TextField
                error={ hasError(errors, 'name') }
                helperText={ getError(errors, 'name') }
                fullWidth
                name='name'
                onChange={ onChange }
                onKeyPress={ keyPress }
                margin="normal"
                value={ data.name }
                variant={'outlined'}
            />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'description') ? ' error' : '') }>
            <label>{ strings.adminCorStates.description }</label>
            <TextField
                error={ hasError(errors, 'description') }
                helperText={ getError(errors, 'description') }
                fullWidth
                name='description'
                onChange={ onChange }
                onKeyPress={ keyPress }
                margin="normal"
                value={ data.description }
                variant={'outlined'}
                className={'description'}
                multiline
                rows={9}
            />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'credit') ? ' error' : '') }>
            <label>{ strings.adminCorStates.credits }</label>
            <TextField
                error={ hasError(errors, 'credit') }
                helperText={ getError(errors, 'credit') }
                fullWidth
                name='credit'
                onChange={ onChange }
                onKeyPress={ keyPress }
                margin="normal"
                value={ data.credit }
                variant={'outlined'}
                type={'number'}
            />
        </div>

        <div className={'browse-button'}>
            <p>{ strings.adminCorStates.attachment }</p>
            <Button variant="contained" color="primary" onClick={openDialog}>
                { strings.adminCorStates.chooseFile }
            </Button>
        </div>
        <p className={'file-name'}>{uploadedFile ? uploadedFile.name : (fileName ? fileName : '')}</p>
        <p className={'upload-description'}>{ strings.adminCorStates.supportedFormats }</p>

        <div className='submit-container'>
            <Button variant="contained" onClick={ pageState === PageState.Add ? onAdd : onEdit }>
                { strings.adminCorStates.save}
            </Button>
        </div>
    </form>
);

export default CorStatesForm;