import React from 'react';
import strings from '../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../functions/Validation";
import {checkAccountType} from "../../../util/AccountTypeUtil";
import AccountTypes from "../../../constants/AccountTypes";

function renderPrivateProfile(data, errors, onChange, edit){
    return (
        <div>
            <div className={ 'select-container' + (hasError(errors, 'name') ? ' error' : '') }>
                <label>{ strings.myProfile.name }</label>
                {
                    edit
                        ?
                        <TextField
                            error={ hasError(errors, 'name') }
                            helperText={ getError(errors, 'name') }
                            fullWidth
                            name='name'
                            variant={'outlined'}
                            onChange={ onChange }
                            margin="normal"
                            value={ data.name }
                            disabled={!edit}
                        />
                        :
                        <p>{data.name}</p>
                }
            </div>

            <div className={ 'select-container' + (hasError(errors, 'surname') ? ' error' : '') }>
                <label>{ strings.myProfile.surname }</label>
                {
                    edit
                        ?
                        <TextField
                            error={ hasError(errors, 'surname') }
                            helperText={ getError(errors, 'surname') }
                            fullWidth
                            name='surname'
                            variant={'outlined'}
                            onChange={ onChange }
                            margin="normal"
                            value={ data.surname }
                            disabled={!edit}
                        />
                        :
                        <p>{data.surname}</p>
                }
            </div>

            <div className={ 'select-container' + (hasError(errors, 'email') ? ' error' : '') }>
                <label>{ strings.myProfile.email }</label>
                {
                    edit
                        ?
                        <TextField
                            error={ hasError(errors, 'email') }
                            helperText={ getError(errors, 'email') }
                            fullWidth
                            name='email'
                            variant={'outlined'}
                            onChange={ onChange }
                            margin="normal"
                            value={ data.email }
                            disabled={!edit}
                        />
                        :
                        <p>{data.email}</p>
                }
            </div>

            <div className={ 'select-container' + (hasError(errors, 'address') ? ' error' : '') }>
                <label>{ strings.myProfile.address }</label>
                {
                    edit
                        ?
                        <TextField
                            error={ hasError(errors, 'address') }
                            helperText={ getError(errors, 'address') }
                            fullWidth
                            name='address'
                            variant={'outlined'}
                            onChange={ onChange }
                            margin="normal"
                            value={ data.address }
                            disabled={!edit}
                        />
                        :
                        <p>{data.address}</p>
                }
            </div>

            <div className={ 'select-container' + (hasError(errors, 'city') ? ' error' : '') }>
                <label>{ strings.myProfile.city }</label>
                {
                    edit
                        ?
                        <TextField
                            error={ hasError(errors, 'city') }
                            helperText={ getError(errors, 'city') }
                            fullWidth
                            name='city'
                            variant={'outlined'}
                            onChange={ onChange }
                            margin="normal"
                            value={ data.city }
                            disabled={!edit}
                        />
                        :
                        <p>{data.city}</p>
                }
            </div>

            <div className={ 'select-container' + (hasError(errors, 'phoneNumber') ? ' error' : '') }>
                <label>{ strings.myProfile.phoneNumber }</label>
                {
                    edit
                        ?
                        <TextField
                            error={ hasError(errors, 'phoneNumber') }
                            helperText={ getError(errors, 'phoneNumber') }
                            fullWidth
                            name='phoneNumber'
                            variant={'outlined'}
                            onChange={ onChange }
                            margin="normal"
                            value={ data.phoneNumber }
                            disabled={!edit}
                        />
                        :
                        <p>{data.phoneNumber}</p>
                }
            </div>
        </div>
    );
}

function renderLegalProfile(data, errors, onChange, edit){
    return (
        <div>
            <div className={ 'select-container' + (hasError(errors, 'name') ? ' error' : '') }>
                <label>{ strings.myProfile.name }</label>
                {
                    edit
                        ?
                        <TextField
                            error={ hasError(errors, 'name') }
                            helperText={ getError(errors, 'name') }
                            fullWidth
                            name='name'
                            variant={'outlined'}
                            onChange={ onChange }
                            margin="normal"
                            value={ data.name }
                            disabled={!edit}
                        />
                        :
                        <p>{data.name}</p>
                }
            </div>

            <div className={ 'select-container' + (hasError(errors, 'address') ? ' error' : '') }>
                <label>{ strings.myProfile.address }</label>
                {
                    edit
                        ?
                        <TextField
                            error={ hasError(errors, 'address') }
                            helperText={ getError(errors, 'address') }
                            fullWidth
                            name='address'
                            variant={'outlined'}
                            onChange={ onChange }
                            margin="normal"
                            value={ data.address }
                            disabled={!edit}
                        />
                        :
                        <p>{data.address}</p>
                }
            </div>

            <div className={ 'select-container' + (hasError(errors, 'location') ? ' error' : '') }>
                <label>{ strings.myProfile.location }</label>
                {
                    edit
                        ?
                        <TextField
                            error={ hasError(errors, 'location') }
                            helperText={ getError(errors, 'location') }
                            fullWidth
                            name='location'
                            variant={'outlined'}
                            onChange={ onChange }
                            margin="normal"
                            value={ data.location }
                            disabled={!edit}
                        />
                        :
                        <p>{data.location}</p>
                }
            </div>

            <div className={ 'select-container' + (hasError(errors, 'legalRegistrationNumber') ? ' error' : '') }>
                <label>{ strings.myProfile.legalRegistrationNumber }</label>
                {
                    edit
                        ?
                        <TextField
                            error={ hasError(errors, 'legalRegistrationNumber') }
                            helperText={ getError(errors, 'legalRegistrationNumber') }
                            fullWidth
                            name='legalRegistrationNumber'
                            variant={'outlined'}
                            onChange={ onChange }
                            margin="normal"
                            value={ data.legalRegistrationNumber }
                            disabled={!edit}
                        />
                        :
                        <p>{data.legalRegistrationNumber}</p>
                }
            </div>

            <div className={ 'select-container' + (hasError(errors, 'pib') ? ' error' : '') }>
                <label>{ strings.myProfile.pib }</label>
                {
                    edit
                        ?
                        <TextField
                            error={ hasError(errors, 'pib') }
                            helperText={ getError(errors, 'pib') }
                            fullWidth
                            name='pib'
                            variant={'outlined'}
                            onChange={ onChange }
                            margin="normal"
                            value={ data.pib }
                            disabled={!edit}
                        />
                        :
                        <p>{data.pib}</p>
                }
            </div>
        </div>
    );
}

const EditProfileForm = ({
                      onSubmit,
                      onChange,
                      errors,
                      data,
                      edit,
                      onEdit
                  }) => {
    return(
        <form id="edit-profile-form" onSubmit={ onSubmit } action = "#">

            <div className={'form-header'}>
                <h2 className={'desktop-title'}>{ strings.myProfile.basicSettings }</h2>
                <h2 className={'mobile-title'}>{ strings.myProfile.editProfile }</h2>
                <img src={'/images/edit.svg'} onClick={() => onEdit('editBasicSettings')} />
            </div>

            {
                checkAccountType(data, AccountTypes.PRIVATE_ENTITY) &&
                renderPrivateProfile(data, errors, onChange, edit)
            }

            {
                checkAccountType(data, AccountTypes.LEGAL_ENTITY) &&
                renderLegalProfile(data, errors, onChange, edit)
            }

            {
                edit &&
                <div className='submit-container desktop-button'>
                    <Button variant="contained" color="primary" onClick={ () => onSubmit('editBasicSettings') }>
                        { strings.myProfile.save }
                    </Button>
                </div>
            }

            <div className='submit-container mobile-button'>
                {
                    edit
                    ?
                    <Button className={'save-btn'} variant="contained" color="primary" onClick={ () => onSubmit('editBasicSettings') }>
                        { strings.myProfile.save }
                    </Button>
                    :
                    <Button className={'edit-btn'} variant="contained" color="primary" onClick={ () => onEdit('editBasicSettings') }>
                        { strings.myProfile.edit }
                    </Button>
                }
            </div>
        </form>
    )
};

export default EditProfileForm;