import React, {createRef} from 'react'
import TablePage from "../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../constants/PageState';
import {
    Drawer, LinearProgress, Table,
    TableBody,
    TableCell, TableFooter,
    TableHead, TablePagination,
    TableRow, TextField
} from "@material-ui/core";
import DrawerWrapper from "../../common/DrawerWrapper";
import Grid from '@material-ui/core/Grid';
import {sortTable} from "../../util/DataUtil";
import {getAllCorStates} from "../../services/admin/CorStatesService";
import CorTypes from "../../constants/CorTypes";
import {downloadDocument} from "../../services/DocumentService";

class CorStates extends TablePage {

    tableDescription = [
        { key: 'name', label: strings.corStates.title },
        { key: 'description', label: strings.corStates.description },
        { key: 'credit', label: strings.corStates.credits },
        { key: 'document', label: strings.corStates.download, transform: 'renderDownloadImage' },
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            showSearch: true,
            // tableData: [],
            tableData : [
            ],
            pageState: PageState.View,
            total: 100,
            errors: {},
            searchData : {
                search : '',
                total : 0,
                page : 1,
                perPage : 30
            }
        };

        this.fetchData = this.fetchData.bind(this);
    }

    fetchData() {

        this.state.searchData.type = CorTypes.STATES;

        getAllCorStates(this.state.searchData).then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                tableData: response.data.result,
                searchData : {
                    ...this.state.searchData,
                    page : response.data.page,
                    perPage : response.data.perPage,
                    total : Math.ceil(response.data.total/response.data.perPage)
                }
            });
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    renderDownloadImage(item){
        return(
            <img src={'/images/direct-download1.svg'}  alt={'download'} onClick={()=> {
                if (!item){
                    return
                }

                downloadDocument(item)
            }}/>
        );
    }

    sortTable(param){
        let sortedData = sortTable(this.state.tableData, param);

        this.setState({
            tableData: sortedData
        })
    }

    getPageHeader() {
        return <h1>{ strings.corStates.mainTitle }</h1>;
    }

    getPageDescription(){
        return <h3>{ strings.corStates.pageDescription }</h3>
    }

    renderTableHeader() {

        let result = [];

        for(let item of this.tableDescription) {
            if(item.label !== 'Download'){
                result.push(
                    <TableCell key={ 'table-header-' + result.length } onClick={() => this.sortTable(item.key)} style={{cursor: 'pointer'}}>
                        <div>
                            { item.label }
                            <div className={'sort-icon'}>
                                <img src={'/images/Polygon2.svg'} />
                                <img src={'/images/Polygon3.svg'} />
                            </div>
                        </div>
                    </TableCell>
                )
            }else {
                result.push(
                    <TableCell key={ 'table-header-' + result.length } style={{cursor: 'default'}}>
                        <div>
                            { item.label }
                        </div>
                    </TableCell>
                )
            }
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    { result }
                </TableRow>
            </TableHead>
        );
    }

    renderTableRowData(item) {

        let result = [];

        for(let description of this.tableDescription) {
            if(description.label !== 'Download'){
                result.push(
                    <TableCell key={ 'table-data-' + description.key + '-' + result.length }>
                        {
                            description.transform !== undefined &&
                            this[description.transform](item[description.key])
                        }
                        {
                            description.transform === undefined &&
                            item[description.key]
                        }
                    </TableCell>
                );
            }else {
                result.push(
                    <TableCell key={ 'table-data-' + description.key + '-' + result.length } style={{textAlign: 'center'}}>
                        {
                            description.transform !== undefined &&
                            this[description.transform](item[description.key])
                        }
                        {
                            description.transform === undefined &&
                            item[description.key]
                        }
                    </TableCell>
                );
            }
        }

        return result;
    }

    renderTableRow(data = []) {

        let result = [];

        for(let item of data) {

            let className = 'table-row';

            if(this.isRowSelected(item)) {
                className += ' selected';
            }

            result.push(
                <TableRow key={ 'table-row-' + result.length } className={ className } onClick={ () => this.selectRow(item) }>
                    { this.renderTableRowData(item) }
                </TableRow>
            )
        }

        return (
            <TableBody>
                { result }
            </TableBody>
        );
    }

    renderRowMenu(index, item) {
        return(
            <TableCell>
            </TableCell>
        );
    }

    render() {
        return (
            <div id={'services-table-page'}>
                <div className={'header-image'}
                     style={{backgroundImage: `url('/images/MaskGroup.jpg')`}}
                >
                </div>
                <Grid id='services-table'>
                    <div className='header'>
                        { this.getPageHeader() }
                        { this.getPageDescription() }
                    </div>
                    <div className='filter-controls'>

                        {
                            this.state.showSearch &&
                            <div className={'filter-wrapper'}>
                                <TextField
                                    placeholder={ strings.table.search }
                                    type="search"
                                    name='search'
                                    value={ this.state.searchData.search }
                                    onChange={ this.searchChanged }
                                    variant={'outlined'}
                                    className={'filter-input'}
                                />
                            </div>
                        }

                        {
                            this.state.showAdd &&
                            this.renderTableControls()
                        }
                    </div>
                    <div className={'table-wrapper'} md={12}>
                        { this.renderTable(this.state.tableData) }
                    </div>

                    <Drawer id='drawer' anchor='right' open={  this.showDrawer() } onClose={ () => this.setPageState(PageState.View) } >
                        <DrawerWrapper onBack={ () => this.setPageState(PageState.View) }>
                            { this.renderDrawerContent() }
                        </DrawerWrapper>
                    </Drawer>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(CorStates)));