import React, { createRef } from 'react';
import TablePage from "../../../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../../../constants/PageState';
import {
    LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem,
    TableBody,
    TableCell, TableFooter,
    TableHead, TablePagination,
    TableRow, TextField
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import CONFIG from "../../../../config";
import {sortTable} from "../../../../util/DataUtil";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import UndoIcon from "@material-ui/icons/Undo";
import Pagination from "@material-ui/lab/Pagination";

class PlanDetailsForm extends TablePage {

    tableDescription = [
        { key: 'productName', label: strings.planDetails.productName },
        { key: 'productDescription', label: strings.planDetails.description },
        { key: 'parentProduct', label: strings.planDetails.parentProduct },
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            showSearch: false,
            showAdd: false,
            // tableData: [],
            tableData : [
                {
                    "id": 28,
                    'productName' : 'product name',
                    'productDescription' : 'product description',
                    'parentProduct' : 'parent product'
                }
            ],
            pageState: PageState.View,
            total: 100,
            errors: {},
            openModal: false,
            uploadedFile: null
        };
    }

    fetchData() {

        // this.setState({
        //     lockTable: true
        // });
    }

    componentDidMount() {
        this.fetchData();
    }

    // TABLE

    sortTable(param){
        let sortedData = sortTable(this.state.tableData, param);

        this.setState({
            tableData: sortedData
        })
    }

    getPageHeader() {
        return '';
    }

    getPageDescription(){
        return '';
    }

    renderTableHeader() {

        let result = [];

        for(let item of this.tableDescription) {
            result.push(
                <TableCell key={ 'table-header-' + result.length } onClick={() => this.sortTable(item.key)} style={{cursor: 'pointer'}}>
                    <div>
                        { item.label }
                        <div className={'sort-icon'}>
                            <img src={'/images/Polygon2.svg'} />
                            <img src={'/images/Polygon3.svg'} />
                        </div>
                    </div>
                </TableCell>
            )
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    { result }
                    <TableCell>
                        {/*{ strings.table.actions }*/}
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    renderTableRow(data = []) {

        let result = [];

        for(let item of data) {

            let className = 'table-row-' + result.length % 2;

            if(this.isRowSelected(item)) {
                className += ' selected';
            }

            result.push(
                <TableRow key={ 'table-row-' + result.length } className={ className } onClick={ () => this.selectRow(item) }>
                    { this.renderTableRowData(item) }
                </TableRow>
            )
        }

        return (
            <TableBody>
                { result }
            </TableBody>
        );
    }

    renderTableFooter() {

        return (
            <TableFooter>
                {/*<TableRow>*/}
                {/*    <TablePagination*/}
                {/*        count={ this.state.total }*/}
                {/*        rowsPerPageOptions={ CONFIG.rowsPerPage }*/}
                {/*        colSpan={ this.tableDescription.length + 1 }*/}
                {/*        page={ this.state.searchData.page - 1 }*/}
                {/*        rowsPerPage={ this.state.searchData.perPage }*/}
                {/*        onChangePage={ this.handleChangePage }*/}
                {/*        onChangeRowsPerPage={ this.handleChangeRowsPerPage }*/}
                {/*        SelectProps={{native: true,}}*/}
                {/*    />*/}
                {/*</TableRow>*/}
                <TableRow className={'plan-details-table-footer'}>
                    <div className={'per-page'} onClick={this.showDropdown}>
                        <p className={'rows-per-page'}>{this.state.searchData.perPage} per page <img src={'/images/Vector.svg'} /></p>
                        <ul className={this.state.showDropdown ? 'show' : ""}>
                            { this.renderPerPageDropdown()}
                        </ul>
                    </div>
                    <Pagination
                        count={this.state.total}
                        shape="rounded"
                        size={'small'}
                        onChange={ this.handleChangePage }
                    />
                </TableRow>
            </TableFooter>
        );
    }

    render() {
        return (
            <div id={'admin-table-page'} className={'plan-details-table'}>
                <Grid id='admin-table'>
                    <div className='header'>
                        { this.getPageHeader() }
                        { this.getPageDescription() }
                    </div>
                    <div className='filter-controls'>

                        {
                            this.state.showSearch &&
                            <div className={'filter-wrapper'}>
                                <TextField
                                    placeholder={ strings.table.search }
                                    type="search"
                                    name='search'
                                    value={ this.state.searchData.search }
                                    onChange={ this.searchChanged }
                                    variant={'outlined'}
                                    className={'filter-input'}
                                />
                            </div>
                        }

                        {
                            this.state.showAdd &&
                            this.renderTableControls()
                        }
                    </div>
                    <div className={'table-wrapper'} md={12}>
                        { this.renderTable(this.state.tableData) }
                    </div>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanDetailsForm)));