import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function getAllPayments(search) {
    return await request('/api/credit_packages_transaction_get', search, HttpMethod.POST);
}

export async function editPaymentStatus(data) {
    return await request('/api/payment', data, HttpMethod.PUT);
}

export async function deletePayment(id) {
    return await request('/api/payment' + id, {}, HttpMethod.DELETE);
}