const LawsAndRegulationsTypes = {
    CIT_LAW: 1,
    VAT_LAW: 2,
    WHT_LAW: 3,
    DTT_LAW: 4,
    OPINION_OF_MINISTRY_OF_FINANCE: 5,
};

export default LawsAndRegulationsTypes;

export function getLawsAndRegulations() {
    const lawsAndRegulations = [
        {
            value: LawsAndRegulationsTypes.CIT_LAW,
            name: 'CIT law'
        },
        {
            value: LawsAndRegulationsTypes.VAT_LAW,
            name: 'VAT law'
        },
        {
            value: LawsAndRegulationsTypes.WHT_LAW,
            name: 'WHT law'
        },
        {
            value: LawsAndRegulationsTypes.DTT_LAW,
            name: 'DTT law'
        },
        {
            value: LawsAndRegulationsTypes.OPINION_OF_MINISTRY_OF_FINANCE,
            name: 'Opinion of Ministry of finance'
        }
    ];

    return lawsAndRegulations;
}

export function getLawsAndRegulationsString(lawsAndRegulations) {
    const selected = getLawsAndRegulations().find(item => item.value === lawsAndRegulations);

    return selected ? selected.name : '';
}
