import React from 'react';
import strings from '../../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../../functions/Validation";
import DatePickerControl from "../../../controls/DatePickerControl";
import PageState from "../../../../constants/PageState";

const VatProxiesForm = ({
                           onSubmit,
                           onChange,
                           errors,
                           data,
                            dateSlash,
                           keyPress,
                           openDialog,
                           uploadedFile,
                           onAdd,
                           onEdit,
                           pageState,
                       }) => (
    <form id="admin-service-form" onSubmit={ onSubmit } action = "#">
        <div className={ 'select-container' + (hasError(errors, 'name') ? ' error' : '') }>
            <label>{ strings.vatProxies.name }</label>
            <TextField
                error={ hasError(errors, 'name') }
                helperText={ getError(errors, 'name') }
                fullWidth
                name='name'
                onChange={ onChange }
                onKeyPress={ keyPress }
                margin="normal"
                value={ data.name }
                variant={'outlined'}
            />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'address') ? ' error' : '') }>
            <label>{ strings.vatProxies.address }</label>
            <TextField
                error={ hasError(errors, 'address') }
                helperText={ getError(errors, 'address') }
                fullWidth
                name='address'
                onChange={ onChange }
                onKeyPress={ keyPress }
                margin="normal"
                value={ data.address }
                variant={'outlined'}
            />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'pib') ? ' error' : '') }>
            <label>{ strings.vatProxies.pib }</label>
            <TextField
                error={ hasError(errors, 'pib') }
                helperText={ getError(errors, 'pib') }
                fullWidth
                name='pib'
                onChange={ onChange }
                onKeyPress={ keyPress }
                margin="normal"
                value={ data.pib }
                variant={'outlined'}
            />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'date_of_confirmation') ? ' error' : '') }>
            <label>{ strings.vatProxies.confirmationDate }</label>
            <DatePickerControl
                date={dateSlash(data.date_of_confirmation)}
                name={'date_of_confirmation'}
                onChange={ onChange }
                // dateFormat='MM/DD/yyyy'
                placeholder={'MM/DD/YYYY'}
                image={data.date_of_confirmation ? '' : '/images/calendar1.svg'}
            />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'foreign_correspondent') ? ' error' : '') }>
            <label>{ strings.vatProxies.foreignCorrespondent }</label>
            <TextField
                error={ hasError(errors, 'foreign_correspondent') }
                helperText={ getError(errors, 'foreign_correspondent') }
                fullWidth
                name='foreign_correspondent'
                onChange={ onChange }
                onKeyPress={ keyPress }
                margin="normal"
                value={ data.foreign_correspondent }
                variant={'outlined'}
            />
        </div>
        <div className={ 'select-container' + (hasError(errors, 'date_of_termination') ? ' error' : '') }>
            <label>{ strings.vatProxies.dateOfTermination }</label>
            <DatePickerControl
                date={dateSlash(data.date_of_termination)}
                name={'date_of_termination'}
                onChange={ onChange }
                // dateFormat='MM/DD/yyyy'
                placeholder={'MM/DD/YYYY'}
                image={data.date_of_termination ? '' : '/images/calendar1.svg'}
            />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'start_date') ? ' error' : '') }>
            <label>{ strings.vatProxies.startDate }</label>
            <DatePickerControl
                date={dateSlash(data.start_date)}
                name={'start_date'}
                onChange={ onChange }
                // dateFormat='MM/DD/yyyy'
                placeholder={'MM/DD/YYYY'}
                image={data.start_date ? '' : '/images/calendar1.svg'}
            />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'deletion_date') ? ' error' : '') }>
            <label>{ strings.vatProxies.deleteDate }</label>
            <DatePickerControl
                date={dateSlash(data.deletion_date)}
                name={'deletion_date'}
                onChange={ onChange }
                // dateFormat='MM/DD/yyyy'
                placeholder={'MM/DD/YYYY'}
                image={data.deletion_date ? '' : '/images/calendar1.svg'}
            />
        </div>

        <div className='submit-container'>
            <Button variant="contained" onClick={ pageState === PageState.Add ? onAdd : onEdit }>
                { strings.adminCorStates.save}
            </Button>
        </div>
    </form>
);

export default VatProxiesForm;