import React from 'react';
import strings from '../../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../../functions/Validation";
import DatePickerControl from "../../../controls/DatePickerControl";

const CreditsCheckoutForm = ({
                       onSubmit,
                       onChange,
                       errors,
                       data,
                       keyPress,
                   }) => (
    <form id="credits-checkout-form" onSubmit={ onSubmit } action = "#">
        <div className={ 'select-container' + (hasError(errors, 'nameOnCard') ? ' error' : '') }>
            <label>{ strings.credits.nameOnCard }</label>
            <TextField
                error={ hasError(errors, 'nameOnCard') }
                helperText={ getError(errors, 'nameOnCard') }
                fullWidth
                name='nameOnCard'
                onChange={ onChange }
                onKeyPress={ keyPress }
                margin="normal"
                value={ data.nameOnCard }
                variant={'outlined'}
            />
        </div>
        <div className={ 'select-container' + (hasError(errors, 'cardNumber') ? ' error' : '') }>
            <label>{ strings.credits.cardNumber }</label>
            <TextField
                error={ hasError(errors, 'cardNumber') }
                helperText={ getError(errors, 'cardNumber') }
                fullWidth
                name='cardNumber'
                onChange={ onChange }
                onKeyPress={ keyPress }
                margin="normal"
                value={ data.cardNumber }
                variant={'outlined'}
            />
        </div>
        <div className={'date-wrapper'}>
            <div className={ 'select-container' + (hasError(errors, 'expiryDate') ? ' error' : '') }>
                <label>{ strings.credits.expiryDate }</label>
                <DatePickerControl
                    date={data.expiryDate}
                    name={'expiryDate'}
                    onChange={ onChange }
                    dateFormat='dd/MM/yyyy'
                />
            </div>
            <div className={ 'select-container' + (hasError(errors, 'securityDate') ? ' error' : '') }>
                <label>{ strings.credits.securityDate }</label>
                <DatePickerControl
                    date={data.securityDate}
                    name={'securityDate'}
                    onChange={ onChange }
                    dateFormat='dd/MM/yyyy'
                />
                <div className={'tooltip right'}>
                    <img src={'/images/info.svg'} />
                    <span className="tooltip-content">Lorem ipsum dolor sit amet</span>
                </div>
            </div>
        </div>
        <div className={ 'select-container' + (hasError(errors, 'zipPostalCode') ? ' error' : '') }>
            <label>{ strings.credits.zipPostalCode }</label>
            <TextField
                error={ hasError(errors, 'zipPostalCode') }
                helperText={ getError(errors, 'zipPostalCode') }
                fullWidth
                name='zipPostalCode'
                onChange={ onChange }
                onKeyPress={ keyPress }
                margin="normal"
                value={ data.zipPostalCode }
                variant={'outlined'}
            />
            <div className={'tooltip right'}>
                <img src={'/images/info.svg'} />
                <span className="tooltip-content">Lorem ipsum dolor sit amet</span>
            </div>
        </div>
        <div className='submit-container'>
            <Button variant="contained" color="primary" onClick={ onSubmit }>
                { strings.credits.pay } €{data.item && data.item.value ? data.item.value.toFixed(2) : ''}
            </Button>
        </div>
    </form>
);

export default CreditsCheckoutForm;