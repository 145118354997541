import React, {Component} from 'react'
import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {Link, withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

class SingleNewsCard extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: {}
        }

        this.props.changeFullScreen(false);
    }

    componentDidMount() {
        this.setState({
            data: this.props.item ? this.props.item : {}
        });
    }

    render() {
        return(
            <div>
                <div className={'card'}>
                    <img src={this.state.data.src} />
                    <div className={'content'}>
                        <div className={'title-wrapper'}>
                            <h3>{ this.state.data.title }</h3>
                        </div>
                        <hr />
                        <p>{ this.state.data.description }</p>
                        <a href={'/single-blog'}>
                            <hr/>
                            <p>{ strings.news.readMore }</p>
                            <hr/>
                        </a>
                    </div>
                    <div className={'card-footer'}>
                        <div className={'date'}>{this.state.data.author}, {this.state.data.date}</div>
                        <div className={'social'}>
                            <a href={this.state.data.facebook}><img src={'/images/facebook1.svg'}/></a>
                            <a href={this.state.data.twitter}><img src={'/images/twitter1.svg'} /></a>
                            <a href={this.state.data.linkedin}><img src={'/images/linkedin1.svg'} /></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleNewsCard));
