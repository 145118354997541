import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function addVendorsDatabase(data) {
    return await request('/api/vendors_database', data, HttpMethod.POST);
}

export async function getAllVendorsDatabases(data) {
    return await request('/api/vendors_database', data, HttpMethod.GET);
}

export async function editVendorsDatabase(data) {
    return await request('/api/vendors_database', data, HttpMethod.PUT);
}

export async function deleteVendorsDatabase(id) {
    return await request('/api/vendors_database/' + id, {}, HttpMethod.DELETE);
}

export async function addType(data) {
    return await request('/api/vendors_database/add_type', data, HttpMethod.POST);
}

export async function addDecision(data) {
    return await request('/api/vendors_database/add_decision', data, HttpMethod.POST);
}

export async function getDecisions(data) {
    return await request('/api/vendors_database/get_decisions', data, HttpMethod.GET);
}

export async function addTypes(data) {
    return await request('/api/vendors_database/get_types', data, HttpMethod.GET);
}