import React from 'react';
import strings from '../../../localization';
import Button from '@material-ui/core/Button';
import {Divider} from "@material-ui/core";
import AccountTypes from "../../../constants/AccountTypes";
import AccountForm from "./AccountForm";

const RegisterForm = ({
                       onSubmit,
                       onChange,
                       onButtonPress,
                       data,
                       errors
                   }) => (
    <form id="register-form" onSubmit={ onSubmit } action = "#">
        {
            !data.accountType
            ?
                <div className={'btn-wrapper'}>
                    <Button name={'accountType'} variant="contained" color="primary" onClick={ (event) => onButtonPress(event, 'accountType', AccountTypes.PRIVATE_ENTITY) }>
                        { strings.register.privateEntity }
                    </Button>

                    <Button name={'accountType'} variant="contained" color="primary" onClick={ (event) => onButtonPress(event, 'accountType', AccountTypes.LEGAL_ENTITY) }>
                        { strings.register.legalEntity }
                    </Button>
                </div>
                :
                <div className={'register-form-wrapper'}>
                    <AccountForm
                        data={data}
                        errors={errors}
                        onChange={onChange}
                        onButtonPress={onButtonPress}
                        onSubmit={onSubmit}
                    />
                </div>
        }
        <Divider />
        <div className={'social-login'}>
            <div className={'fb-login'}>
                <img src={'/images/facebook.svg'} />
                <p>{ strings.login.fbLogin }</p>
            </div>
            <div className={'google-login'}>
                <img src={'/images/search1.svg'} />
                <p>{ strings.login.googleLogin }</p>
            </div>
        </div>

        <div className={'login-wrapper'}>
            <p>{ strings.register.alreadyHaveAccount } <a href={'/login'}>{strings.register.login}</a></p>
        </div>
    </form>
);

export default RegisterForm;