import React, {createRef} from 'react'
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../constants/PageState';
import SingleNewsCard from "../../components/news/SingleNewsCard";
import Pagination from "@material-ui/lab/Pagination";
import TablePage from "../../common/TablePage";

class News extends TablePage {

    constructor(props) {
        super(props);

        this.state = {
            data: [
                {
                    'id' : 1,
                    'author' : 'Author',
                    'src' : '/images/Rectangle126.jpg',
                    // 'title' : 'Cursus neque dignissim sit ',
                    'title' : 'Nemačka - How VAT works in Germany',
                    // 'description' : 'Integer at sed sit egestas mattis neque libero imperdiet. Tincidunt sed placerat metus ullamcorper eu odio. Tellus volutpat quis euismod tempor ridiculus faucibus aliquam sed vulputate. \n' +
                    //     '\n' +
                    //     'Gravida leo nulla pharetra elit sapien at faucibus. Odio ornare montes, velit sed turpis nunc in at sed. Neque sed enim felis semper nibh suspendisse massa. Lacus, ullamcorper cursus proin scelerisque aliquet. Consequat luctus nec semper varius. Odio viverra lectus viverra ante libero, vel et est viverra. ',
                    'description' : 'In Germany, VAT is a 19% tax on most goods and services. This guide explains VAT for German businesses.',
                    'date' : '18.01.2021',
                    'facebook' : '',
                    'twitter' : '',
                    'linkedin' : ''
                },
                {
                    'id' : 2,
                    'src' : '/images/Rectangle127.jpg',
                    // 'title' : 'Sit viverra orci, a volutpat in cursus quam',
                    'title' : 'US - Corporate Tax Rates in USA ',
                    // 'description' : 'Enim est cursus amet posuere blandit. Tellus sapien sed ultrices ullamcorper tincidunt id. Vitae platea commodo auctor purus lacus, commodo, gravida sed dui. Faucibus enim amet egestas in venenatis tempus. Aliquam turpis habitant suspendisse risus orci, aliquam. Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi. Semper vulputate adipiscing laoreet morbi. Lacus fermentum leo sit malesuada praesent sed duis pellentesque aliquam. ',
                    'description' : 'Corporations are generally taxed at both the federal and state level. When a corporation pays taxes on its taxable income, it must pay at a rate set by both the federal and state levels.',
                    'date' : '',
                    'facebook' : '',
                    'twitter' : '',
                    'linkedin' : ''
                },
                {
                    'id' : 3,
                    'src' : '/images/Rectangle128.jpg',
                    // 'title' : 'Malesuada senectus libero scelerisque \n' +
                    //     'neque, sit mattis dignissim',
                    'title' : 'Srbija - How to reduce income tax based on investment in innovation?',
                    // 'description' : 'Aliquam aliquam lobortis nibh at ut amet, ipsum. Non euismod imperdiet tortor est eu sollicitudin massa amet lectus. Id nisl magna ante porttitor quisque viverra. \n' +
                    //     '\n' +
                    //     'Eget cras turpis id purus amet. Convallis volutpat eu orci morbi fermentum. Rhoncus, maecenas orci ultrices mauris. Ante rhoncus sit nibh lorem sit mauris.',
                    'description' : 'Taxes are mandatory, but there are ways to reduce them through the application of prescribed tax deductions.',
                    'date' : '',
                    'facebook' : '',
                    'twitter' : '',
                    'linkedin' : ''
                },
                {
                    'id' : 4,
                    'src' : '/images/Rectangle126.jpg',
                    'title' : 'Mađarska - New tax measures in Hungary for 2024',
                    'description' : 'Hungary’s autumn tax package for 2024 includes the implementation of the EU global minimum taxation directive into domestic law, significant changes to the participation exemption and the deductibility of expenses, and the introduction of a new tax credit for research and development (R&D) expenses.',
                    'date' : '',
                    'facebook' : '',
                    'twitter' : '',
                    'linkedin' : ''
                },
                {
                    'id' : 5,
                    'src' : '/images/Rectangle126.jpg',
                    'title' : 'Rusija - Russia VAT on foreign digital service update',
                    'description' : 'The Russian Federation imposed the obligation to Value Added Tax register on foreign providers of digital or electronic services from 1 January 2017.',
                    'date' : '',
                    'facebook' : '',
                    'twitter' : '',
                    'linkedin' : ''
                },
            ],
            pageState: PageState.View,
            total: 100,
            errors: {},
        };
    }

    fetchData() {
        // this.setState({
        //     lockTable: true
        // });
    }

    componentDidMount() {
        this.fetchData();
    }

    renderNews(data){
        let result = [];

        for(let item of data){
            result.push(<SingleNewsCard item={item} />);
        }

        return result;
    }

    render() {
        return (
            <div id={'news-page'}>
                <h1>{strings.news.title}</h1>
                <div className={'cards-wrapper'}>
                    {
                        this.renderNews(this.state.data)
                    }
                </div>

                <div className={'pagination-wrapper'}>
                    <Pagination
                        count={this.state.total}
                        shape="rounded"
                        size={'small'}
                        onChange={ this.handleChangePage }
                    />
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(News)));
