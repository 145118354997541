import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function addDttCountry(data) {
    return await request('/api/dtt-countries', data, HttpMethod.POST);
}

export async function getAllDttCountries(data) {
    return await request('/api/dtt-countries', data, HttpMethod.GET);
}

export async function editDttCountry(data) {
    return await request('/api/dtt-countries', data, HttpMethod.PUT);
}

export async function deleteDttCountry(id) {
    return await request('/api/dtt-countries/' + id, {}, HttpMethod.DELETE);
}