import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid';
import strings from "../localization";

class Footer extends Component {


    render() {

        return (
            <footer>
                <Grid container spacing={10}>
                    <div className={'footer-wrapper'}>
                        <div className={'one'}>
                            <div className={'icons-wrapper'}>
                                <a href={'/'}><img src={'/images/logo.png'} className={'logo'} /></a>
                                <div className={'icons'}>
                                    <a href={'/'}><img src={'/images/facebook1.svg'} /></a>
                                    <a href={'/'}><img src={'/images/twitter1.svg'} /></a>
                                    <a href={'/'}><img src={'/images/linkedin1.svg'} /></a>
                                </div>
                            </div>
                            <div className={'links'}>
                                <h4>{ strings.footer.aktiva }</h4>
                                <a href={'/about-us'}>{ strings.footer.aboutUs }</a>
                                <a href={'/contact-us'}>{ strings.footer.contactUs }</a>
                                <a href={'/credit-packages'}>{ strings.footer.creditPackages }</a>
                                <a href={'/news'}>{ strings.footer.news }</a>
                                <a href={'/terms-of-services'}>{ strings.footer.termsOfServices }</a>
                                <a href={'/privacy-policy'}>{ strings.footer.privacyPolicy }</a>
                            </div>
                        </div>
                        <div className={'two'}>
                            <div className={'links'}>
                                <h4>{ strings.footer.services }</h4>
                                <a href={'/cor-states'}>Invoicing with auto tax solution - taxvoice</a>
                                <a href={'/cor-vendors'}>Global tax compliance</a>
                                <a href={'/vendors-data-base'}>International tax advisory</a>
                                <a href={'/dtt-countries'}>Risk assesment</a>
                                <a href={'/vat-proxies'}>Tax planing and decisioning</a>
                            </div>
                            <div className={'links'}>
                                <h4>{ strings.footer.lawsAndRegulations }</h4>
                                <a href={'/laws-and-regulations?type=1'}>{ strings.footer.citLaw }</a>
                                <a href={'/laws-and-regulations?type=2'}>{ strings.footer.vatLaw }</a>
                                <a href={'/laws-and-regulations?type=3'}>{ strings.footer.whtLaw }</a>
                                <a href={'/laws-and-regulations?type=4'}>{ strings.footer.dttLaw }</a>
                                <a href={'/laws-and-regulations?type=5'}>{ strings.footer.opinionOfMinistryOfFinance }</a>
                            </div>
                        </div>
                    </div>
                </Grid>
            </footer>
        );
    }
}

export default Footer;
