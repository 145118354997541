import React, {createRef} from 'react'
import Grid from '@material-ui/core/Grid';
import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import Button from "@material-ui/core/Button";
import CreditsCheckoutModal from "../../common/CreditsCheckoutModal";
import PayOptions from "../../constants/PayOptions";
import {getAllCorStates} from "../../services/admin/CorStatesService";
import {getAllCreditPackages} from "../../services/admin/CreatePackagesService";
import {getUser} from "../../services/UserService";

class CreditPackages extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                payOption: PayOptions.PAY_BY_CARD
            },
            creditsData: [

            ],
            queryData : {
                page : 1,
                perPage : 20
            },
            creditAmount : 0,
            openCreditsCheckoutModal: false,
        };

        this.creditsCheckoutModalRef = createRef();
        this.toggleCreditsCheckoutModal = this.toggleCreditsCheckoutModal.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        // this.fetchData();
    }

    fetchData() {

        getAllCreditPackages(this.state.queryData.page, this.state.queryData.perPage).then(response => {
            if (!response.ok) {
                return;
            }

            let creditPackages = response.data.result;

            creditPackages.forEach((item) => {
                item.disabled = true;
            });

            this.setState({
                creditsData: creditPackages
            });
        });

        let user = this.getUser();

        if (!user){
            return
        }

        getUser(user.id).then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                ...this.state,
                creditAmount : response.data.entity.credit
            })
        })
    }


    renderSinglePackage(item){
        return(
            <div className={'package-card'}>
                <div className={'card-header'} style={{background: item.color}}>
                    <div>{item.name.toUpperCase()}</div>
                </div>
                <div className={'credits-value-wrapper'}>
                    <div className={'credits-value'}>{item.credits}</div>
                    <div className={'credits-value-description'}>{ strings.credits.credits.toUpperCase() }</div>
                </div>
                <hr />
                <div className={'credits-package-card-description'}>{item.description}</div>
                <div className={'credits-cost'}>€{item.price}</div>
                <Button variant="contained" onClick={() => this.toggleCreditsCheckoutModal(item)}>
                    { strings.credits.buyCredits}
                </Button>
            </div>
        );
    }

    renderPackages(data){
        let result = [];

        for(let item of data){
            result.push(this.renderSinglePackage(item));
        }

        return result;
    }

    // MODAL SECTION

    toggleCreditsCheckoutModal(item) {
        this.setState({
            openCreditsCheckoutModal: !this.state.openCreditsCheckoutModal,
            data: {
                ...this.state.data,
                item
            }
        });
    }

    openDialog() {
        if (this.creditsCheckoutModalRef.current) {
            this.creditsCheckoutModalRef.current.open();
        }
    };

    formModalRender() {
        return (
            <CreditsCheckoutModal
                toggleModal={this.toggleCreditsCheckoutModal}
                open={this.state.openCreditsCheckoutModal}
                openDialog={this.openDialog}
                deleteFile={this.deleteFile}
                onChange={this.changeData}
                data={this.state.data}
            />
        );
    }

    render() {
        let creditsCheckoutModal = this.formModalRender();

        return (
            <div>
                <Grid container spacing={24}>
                    <div id={'credit-packages'}>
                        <h1 className={'mobile-title'}>{ strings.credits.mainTitle }</h1>
                        <div className={'credits'}>
                            <div className={'credits-border'} />
                            <div className={'credits-content'}>
                                <div className={'credits-label'}>{ strings.credits.yourAvailableCredits.toUpperCase() }</div>
                                <div className={'number-of-credits'}>{this.state.creditAmount}</div>
                                <div className={'credits-label'}>{ strings.credits.additionalInfo.toUpperCase() }</div>
                                <p>Admin needs to approve your credit</p>
                            </div>
                        </div>
                        <div className={'credits-description'}>
                            <h1>{ strings.credits.mainTitle }</h1>
                            <p>{ strings.credits.description }</p>
                        </div>
                        <div className={'packages'}>
                            {
                                this.renderPackages(this.state.creditsData)
                            }
                        </div>
                    </div>
                </Grid>
                {creditsCheckoutModal}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreditPackages));
