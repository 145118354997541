import React from 'react'
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import Page from "../../common/Page";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LoginForm from "../../components/forms/user/LoginForm";
import Validators from "../../constants/ValidatorTypes";
import {login} from "../../base/OAuth";
import {Divider} from "@material-ui/core";
import history from "../../history";
import {renderHeader} from "../../util/HeaderUtil";
import {resetPasswordRequest} from "../../services/UserService";
import { withSnackbar } from "notistack";


class Login extends Page {

    validationList = {
        email: [ {type: Validators.EMAIL } ],
        password: [ {type: Validators.REQUIRED } ]
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            errors: {},
            redirectUrl: props.location.state ? props.location.state.redirectUrl : '/',
            forgotPassword: false
        };

        this.props.changeFullScreen(true);

        this.keyPress = this.keyPress.bind(this);
        this.onForgotPassword = this.onForgotPassword.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    handleResize = () => this.setState({
        windowWidth: window.innerWidth
    });

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    componentDidMount() {

        if(this.props.auth.user) {
            this.props.history.push('/');
        }

        this.handleResize();
        window.addEventListener('resize', this.handleResize)
    }

    keyPress(event) {

        if(event.key == 'Enter') {
            this.login()
        }
    }

    login() {

        if(!this.validate()) {
            return;
        }

        login(this.state.data.email, this.state.data.password).then(response => {

            if(!response.ok) {

                this.setError('email', strings.login.wrongCredentials);
                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            this.props.login(response.data.user);
            this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })

            this.props.history.push({
                pathname: this.state.redirectUrl
            })
        });
    }

    redirectToCreateAccount(){
        history.push('/register');
    }

    onForgotPassword(){
        this.setState({
            forgotPassword: true
        })
    }

    resetPassword(){
        // resetPasswordRequest(this.state.data).then(response => {
        //     if (!response.ok) {
        //         this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
        //         return;
        //     }
        //
        //     this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })
        //
        //     this.fetchData();
        // });
    }

    render() {
        return (
            <div id='login'>
                {
                    renderHeader(this.state.windowWidth, this.props.history.location.pathname)
                }
                <Grid container spacing={24} className={'login-wrapper'}>
                    <Grid item md={3} className={'left-image'}>
                        <img src={'/images/Ilustracija21.png'} />
                    </Grid>
                    <Grid item md={3} className={ this.state.forgotPassword ? 'login-form forgot-password' : 'login-form'}>
                        <Paper className='paper'>
                            {
                                this.state.forgotPassword
                                ?
                                <h1>{ strings.login.forgotPasswordTitle }</h1>
                                :
                                <h1>{ strings.login.loginTitle }</h1>
                            }
                            <LoginForm onSubmit={ () => this.login() } onChange={ this.changeData }
                                       keyPress={ this.keyPress }
                                       data={ this.state.data } errors={ this.state.errors } redirectToCreateAccount={this.redirectToCreateAccount}
                                       onForgotPassword={this.onForgotPassword} forgotPassword={this.state.forgotPassword}
                                       resetPassword={this.resetPassword}
                            />
                        </Paper>
                    </Grid>
                    <Grid item md={3} className={'right-image'}>
                        <img src={'/images/Frame1.png'} />
                    </Grid>
                </Grid>

                <div className={'privacy-terms-wrapper'}>
                    <Divider />
                    <Grid item md={3} className={'privacy-terms'}>
                        <a href={'/privacy-policy'}>{ strings.login.privacyPolicy }</a>
                        <div></div>
                        <a href={'/terms-of-services'}>{ strings.login.termsOfService }</a>
                    </Grid>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        login: Actions.login
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, auth: authReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));