import React, {Component} from 'react';
import {Chip, Dialog, DialogContent, Divider, Slide} from "@material-ui/core/";
import strings from "../../localization";
import {hasError} from "../../functions/Validation";

class ServicesModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // data: {},
        }

        this.changeData = this.changeData.bind(this);
        this.hasContent = this.hasContent.bind(this);
    }

    changeData(event) {

        let data = this.props.data;

        data[event.target.name] = event.target.value;

        this.setState({
            data: data
        });
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="right" ref={ref} {...props} />;
    });

    hasContent(){
        return this.props.sidebar ? ' sidebar-wrapper' : '';
    }

    render() {
        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'services-modal'}
            >
                {
                    this.props.planDetailsTitle &&
                    <div className={'plan-details-header'}>
                        {this.props.planDetailsTitle}
                        <Divider />
                    </div>
                }
                {
                    this.props.showHeader &&
                    <div className='services-modal-header' onClose={this.props.toggleModal}>
                        {
                            this.props.modalTitle
                                ?
                                <p>{this.props.modalTitle}</p>
                                :
                                <p>{ strings.adminCorStates.modalHeader }</p>
                        }
                        <img className={'white-x'} src={'/images/whitex.svg'} onClick={this.props.toggleModal} />
                        <img className={'gray-x'} src={'/images/x.svg'} onClick={this.props.toggleModal} />
                    </div>
                }
                <div className={'service-form-wrapper' + (this.hasContent())}>
                    {this.props.content}
                    {this.props.sidebar}
                    {this.props.form}
                </div>
            </Dialog>
        );
    }
}

export default ServicesModal;