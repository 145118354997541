import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function addLawsAndRegulations(data) {
    return await request('/api/laws_and_regulations', data, HttpMethod.POST);
}

export async function getAllLawsAndRegulations(data) {
    return await request('/api/laws_and_regulations', data, HttpMethod.GET);
}

export async function editLawsAndRegulations(data) {
    return await request('/api/laws_and_regulations', data, HttpMethod.PUT);
}

export async function deleteLawsAndRegulations(id) {
    return await request('/api/laws_and_regulations/' + id, {}, HttpMethod.DELETE);
}