import React, {Component} from 'react'
import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {Link, withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import Button from "@material-ui/core/Button";

class LawsAndRegulations extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: [
                {
                    'link' : '/laws-and-regulations?type=1',
                    'src' : '/images/cit.svg',
                    'title' : strings.lawsAndRegulations.citLaw,
                    'description' : strings.lawsAndRegulations.description
                },
                {
                    'link' : '/laws-and-regulations?type=2',
                    'src' : '/images/vat.svg',
                    'title' : strings.lawsAndRegulations.vatLaw,
                    'description' : strings.lawsAndRegulations.description
                },
                {
                    'link' : '/laws-and-regulations?type=2',
                    'src' : '/images/vat.svg',
                    'title' : strings.lawsAndRegulations.whtLaw,
                    'description' : strings.lawsAndRegulations.description
                },
                {
                    'link' : '/laws-and-regulations?type=2',
                    'src' : '/images/vat.svg',
                    'title' : strings.lawsAndRegulations.dttLaw,
                    'description' : strings.lawsAndRegulations.description
                },
                {
                    'link' : '/laws-and-regulations?type=3',
                    'src' : '/images/opinion.svg',
                    'title' : strings.lawsAndRegulations.opinionOfMinistryOfFinance,
                    'description' : strings.lawsAndRegulations.description
                }
            ],
        }

        this.props.changeFullScreen(false);
    }

    renderSingleLawAndRegulation(item){
        return(
            <div className={'item'}>
                <div>
                    <img src={item.src} />
                    <h3>{item.title}</h3>
                </div>
                <p>{item.description}</p>
                <Link to={item.link}>
                    <Button variant="contained">
                        { strings.lawsAndRegulations.seeDetails }
                    </Button>
                </Link>
            </div>
        );
    }

    renderLawsAndRegulations(data){
        let result = [];

        for(let item of data){
            result.push(this.renderSingleLawAndRegulation(item))
        }

        return result;
    }

    render() {
        return (
            <div id={'laws-and-regulations'}>
                <h1>{ strings.lawsAndRegulations.mainTitle }</h1>
                <div className={'laws'}>
                    {
                        this.renderLawsAndRegulations(this.state.data)
                    }
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LawsAndRegulations));
