import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

class Navigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submenu: {}
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.data
        });
    }

    toggleSubmenu(key) {
        let submenu = this.state.submenu;

        submenu[key] = !submenu[key];

        this.setState({
            submenu: submenu
        });
    }

    renderSidebarSideMenu(plans){
        let result = [];

        for(let plan of plans){
            result.push(
                <p>{plan.title}</p>
            );
        }

        return result;
    }

    renderSidebar(){
        let result = [];

        if(this.state.data){
            for(let product of this.state.data.products){
                result.push(
                    <div>
                        <ListItem className={this.state.submenu[product.productName] ? 'navigation-item-wrapper active' : 'navigation-item-wrapper'} button onClick={ () => this.toggleSubmenu(product.productName) } >
                            <ListItemText inset primary={product.productName} className={this.state.submenu[product.productName] ? 'navigation-text active' : 'navigation-text'} />
                            { this.state.submenu[product.productName] ? <img src={'/images/Polygon3.svg'}/> : <img src={'/images/Polygon2.svg'} /> }
                        </ListItem>
                        <Collapse in={ this.state.submenu[product.productName] } timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className='submenu'>
                                {this.renderSidebarSideMenu(product.plans)}
                            </List>
                        </Collapse>
                    </div>
                )
            }
        }

        return result;
    }

    render() {
        return (
            <div className={'sidebar'}>
                {this.renderSidebar()}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({}, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, auth: authReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));