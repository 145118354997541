import React, {Component} from 'react'
import {bindActionCreators} from "redux";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import MenuState from "../../constants/MenuState";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import {Drawer} from "@material-ui/core";
import strings from "../../localization";
import LawsAndRegulationsTypes, {
    getLawsAndRegulations,
    getLawsAndRegulationsString
} from "../../constants/LawsAndRegulationsTypes";
import {adminLogout, lock, logout} from "../../base/OAuth";
import BaseComponent from "../../common/BaseComponent";

class Navigation extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            submenu: {
                credits: false,
                lawsAndRegulations: false
            }
        };
    }

    getNavigationClass() {

        if(this.props.menu.state === MenuState.SHORT) {
            return 'navigation-content-container short';
        }
        else {
            return 'navigation-content-container'
        }
    }

    isCurrentPath(path, search = '') {
        if(search !== ''){
            return (this.props.history.location.pathname == path && this.props.history.location.search == search);
        }

        return this.props.history.location.pathname == path;
    }

    toggleSubmenu(key) {

        let submenu = this.state.submenu;

        submenu[key] = !submenu[key];

        this.setState({
            submenu: submenu
        });
    }

    render() {
        return (
            <Drawer variant="permanent" id='navigation'>
                <div className={ this.getNavigationClass() }>
                    <div className='logo-container'>
                        <div className='logo'>
                            <img src={'/images/logo.png'}/>
                        </div>
                    </div>
                    <List component="nav">
                        <ListItem className='navigation-item-wrapper' button onClick={ () => this.toggleSubmenu('credits') } >
                            <ListItemText inset primary={strings.navigation.credits} className='navigation-text'/>
                            { this.state.submenu.credits ? <img src={'/images/Polygon3.svg'}/> : <img src={'/images/Polygon2.svg'} /> }
                        </ListItem>
                        <Collapse in={ this.state.submenu.credits } timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className='submenu'>
                                <Link to={'/admin/credit-packages'} className={ this.isCurrentPath('/admin/credit-packages') ? 'navigation-link active' : 'navigation-link'} >
                                    <ListItem className='navigation-item'>
                                        <ListItemText inset primary={strings.navigation.creatingCreditPackage} className='navigation-text'/>
                                    </ListItem>
                                </Link>
                                <Link to={'/admin/payment-process-approval'} className={ this.isCurrentPath('/admin/payment-process-approval') ? 'navigation-link active' : 'navigation-link'} >
                                    <ListItem className='navigation-item'>
                                        <ListItemText inset primary={strings.navigation.paymentProcessApproval} className='navigation-text'/>
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>

                        <Link to={'/admin/news'} className={ this.isCurrentPath('/admin/news') ? 'navigation-link active' : 'navigation-link'} >
                            <ListItem className='navigation-item'>
                                <ListItemText inset primary={strings.navigation.news} className='navigation-text'/>
                            </ListItem>
                        </Link>

                        <ListItem className='navigation-item-wrapper' button onClick={ () => this.toggleSubmenu('lawsAndRegulations') } >
                            <ListItemText inset primary={strings.navigation.lawsAndRegulations} className='navigation-text'/>
                            { this.state.submenu.lawsAndRegulations ? <img src={'/images/Polygon3.svg'}/> : <img src={'/images/Polygon2.svg'} /> }
                        </ListItem>
                        <Collapse in={ this.state.submenu.lawsAndRegulations } timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className='submenu'>
                                <Link to={'/admin/laws-and-regulations?type=' + LawsAndRegulationsTypes.CIT_LAW} className={ this.isCurrentPath('/admin/laws-and-regulations', '?type=' + LawsAndRegulationsTypes.CIT_LAW) ? 'navigation-link active' : 'navigation-link'} >
                                    <ListItem className='navigation-item'>
                                        <ListItemText inset primary={strings.navigation.citLaw} className='navigation-text'/>
                                    </ListItem>
                                </Link>
                                <Link to={'/admin/laws-and-regulations?type=' + LawsAndRegulationsTypes.VAT_LAW} className={ this.isCurrentPath('/admin/laws-and-regulations', '?type=' + LawsAndRegulationsTypes.VAT_LAW) ? 'navigation-link active' : 'navigation-link'} >
                                    <ListItem className='navigation-item'>
                                        <ListItemText inset primary={strings.navigation.vatLaw} className='navigation-text'/>
                                    </ListItem>
                                </Link>
                                <Link to={'/admin/laws-and-regulations?type=' + LawsAndRegulationsTypes.WHT_LAW} className={ this.isCurrentPath('/admin/laws-and-regulations', '?type=' + LawsAndRegulationsTypes.WHT_LAW) ? 'navigation-link active' : 'navigation-link'} >
                                    <ListItem className='navigation-item'>
                                        <ListItemText inset primary={strings.navigation.whtLaw} className='navigation-text'/>
                                    </ListItem>
                                </Link>
                                <Link to={'/admin/laws-and-regulations?type=' + LawsAndRegulationsTypes.DTT_LAW} className={ this.isCurrentPath('/admin/laws-and-regulations', '?type=' + LawsAndRegulationsTypes.DTT_LAW) ? 'navigation-link active' : 'navigation-link'} >
                                    <ListItem className='navigation-item'>
                                        <ListItemText inset primary={strings.navigation.dttLaw} className='navigation-text'/>
                                    </ListItem>
                                </Link>
                                <Link to={'/admin/laws-and-regulations?type=' + LawsAndRegulationsTypes.OPINION_OF_MINISTRY_OF_FINANCE} className={ this.isCurrentPath('/admin/laws-and-regulations', '?type=' + LawsAndRegulationsTypes.OPINION_OF_MINISTRY_OF_FINANCE) ? 'navigation-link active' : 'navigation-link'} >
                                    <ListItem className='navigation-item'>
                                        <ListItemText inset primary={strings.navigation.opinionOfMinistryOfFinance} className='navigation-text'/>
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>

                        <Link to={'/admin/cor-states'} className={ this.isCurrentPath('/admin/cor-states') ? 'navigation-link active' : 'navigation-link'} >
                            <ListItem className='navigation-item'>
                                <ListItemText inset primary={strings.navigation.corStates} className='navigation-text'/>
                            </ListItem>
                        </Link>

                        <Link to={'/admin/cor-vendors'} className={ this.isCurrentPath('/admin/cor-vendors') ? 'navigation-link active' : 'navigation-link'} >
                            <ListItem className='navigation-item'>
                                <ListItemText inset primary={strings.navigation.corVendors} className='navigation-text'/>
                            </ListItem>
                        </Link>

                        <Link to={'/admin/vendors-data-base'} className={ this.isCurrentPath('/admin/vendors-data-base') ? 'navigation-link active' : 'navigation-link'} >
                            <ListItem className='navigation-item'>
                                <ListItemText inset primary={strings.navigation.vendorsDataBase} className='navigation-text'/>
                            </ListItem>
                        </Link>

                        <Link to={'/admin/dtt-countries'} className={ this.isCurrentPath('/admin/dtt-countries') ? 'navigation-link active' : 'navigation-link'} >
                            <ListItem className='navigation-item'>
                                <ListItemText inset primary={strings.navigation.dttCountry} className='navigation-text'/>
                            </ListItem>
                        </Link>

                        <Link to={'/admin/vat-proxies'} className={ this.isCurrentPath('/admin/vat-proxies') ? 'navigation-link active' : 'navigation-link'} >
                            <ListItem className='navigation-item'>
                                <ListItemText inset primary={strings.navigation.vatProxy} className='navigation-text'/>
                            </ListItem>
                        </Link>

                        <Link to={'/admin/guest-search'} className={ this.isCurrentPath('/admin/guest-search') ? 'navigation-link active' : 'navigation-link'} >
                            <ListItem className='navigation-item'>
                                <ListItemText inset primary={strings.navigation.listOfGuestUsersSearch} className='navigation-text'/>
                            </ListItem>
                        </Link>

                        <span style={{marginLeft: '20px', lineHeight: '26px'}} onClick={()=> {
                            adminLogout();
                        }}>Logout</span>
                    </List>
                </div>
            </Drawer>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({}, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, auth: authReducers };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));
