import React, { createRef } from 'react';
import TablePage from "../../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../../constants/PageState';
import {
    LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem,
    TableBody,
    TableCell, TableFooter,
    TableHead, TablePagination,
    TableRow, TextField
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import {sortTable} from "../../../util/DataUtil";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import UndoIcon from "@material-ui/icons/Undo";
import Button from "@material-ui/core/Button";
import {deleteGuestSearch, getAllGuestSearches} from "../../../services/admin/GuestSearchService";
import {getAllCorStates} from "../../../services/admin/CorStatesService";
import {getEmail} from "../../../services/UserService";
import AccountTypes from "../../../constants/AccountTypes";

class GuestSearch extends TablePage {

    tableDescription = [
        { key: 'name', label: strings.guestSearch.name },
        { key: 'email', label: strings.guestSearch.email },
        { key: 'content', label: strings.guestSearch.searchInput },
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            showSearch: true,
            showAdd: false,
            // tableData: [],
            tableData : [
            ],
            searchData: {
                search : '',
                total : 0,
                page : 1,
                perPage : 9999
            },
            pageState: PageState.View,
            total: 100,
            errors: {},
            openModal: false,
            uploadedFile: null
        };
    }

    fetchData() {
        getEmail(this.state.searchData).then(response => {
            if (!response.ok){
                return
            }

            this.setState({
                tableData: response.data.result,
                searchData : {
                    ...this.state.searchData,
                    page : response.data.page,
                    perPage : response.data.perPage,
                    total : Math.ceil(response.data.total/response.data.perPage)
                }
            });
        })
    }

    componentDidMount() {
        if (this.getUser() && (AccountTypes.ADMIN !== this.getUser().type)){
            this.props.history.push('/');
            return
        }

        this.fetchData();
    }

    // TABLE

    sortTable(param){
        let sortedData = sortTable(this.state.tableData, param);

        this.setState({
            tableData: sortedData
        })
    }

    getPageHeader() {
        return <h1>{ strings.guestSearch.title }</h1>;
    }

    getPageDescription(){
        return <h3>{ strings.guestSearch.pageDescription }</h3>
    }

    renderTableHeader() {

        let result = [];

        for(let item of this.tableDescription) {
            result.push(
                <TableCell key={ 'table-header-' + result.length } onClick={() => this.sortTable(item.key)} style={{cursor: 'pointer'}}>
                    <div>
                        { item.label }
                        <div className={'sort-icon'}>
                            <img src={'/images/Polygon2.svg'} />
                            <img src={'/images/Polygon3.svg'} />
                        </div>
                    </div>
                </TableCell>
            )
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    { result }
                    <TableCell>
                        {/*{ strings.table.actions }*/}
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    renderTableControls() {
        return [
            <Button variant="contained" color="secondary"
                // onClick={ () => this.setPageState(PageState.Add) }
                    onClick={ () => this.toggleModal() }
            >
                { strings.table.addDocument }
            </Button>
        ]
    }

    renderRowMenu(index, item) {

        let ariaOwns = 'action-menu-' + index;

        return(
            <TableCell>
                <IconButton
                    aria-owns={ this.state.anchorEl ? ariaOwns : undefined }
                    aria-haspopup="true"
                    onClick={ (event) => this.handleMenuClick(event, ariaOwns) }
                >
                    <MoreVert/>
                </IconButton>
                {
                    ariaOwns === this.state.ariaOwns &&
                    <Menu
                        id={ ariaOwns }
                        anchorEl={ this.state.anchorEl }
                        open={ Boolean(this.state.anchorEl) }
                        onClose={ () => this.handleMenuClose() }
                        className={'table-additional-menu'}
                    >
                        <div className={'menu-header'}>
                            {  strings.table.additionalOptions}
                        </div>
                        {
                            !item[this.deletedField] &&
                            <MenuItem onClick={ () => this.delete(item) }>
                                <ListItemIcon>
                                    <DeleteIcon/>
                                </ListItemIcon>
                                <ListItemText inset primary={ strings.table.delete }/>
                            </MenuItem>
                        }
                        {
                            item[this.deletedField] &&
                            <MenuItem onClick={ () => this.handleRestore(item) }>
                                <ListItemIcon>
                                    <UndoIcon/>
                                </ListItemIcon>
                                <ListItemText inset primary={ strings.table.undo }/>
                            </MenuItem>
                        }

                    </Menu>
                }

            </TableCell>
        );
    }

    delete(){
        deleteGuestSearch(this.state.selectedItem.id).then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            setTimeout(()=> {
                this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })
                this.fetchData();
            }, 1000)
        });
    }

    render() {
        return (
            <div id={'admin-table-page'}>
                <Grid id='admin-table'>
                    <div className='header'>
                        { this.getPageHeader() }
                        { this.getPageDescription() }
                    </div>
                    <div className='filter-controls'>

                        {
                            this.state.showSearch &&
                            <div className={'filter-wrapper'}>
                                <TextField
                                    placeholder={ strings.table.search }
                                    type="search"
                                    name='search'
                                    value={ this.state.searchData.search }
                                    onChange={ this.searchChanged }
                                    variant={'outlined'}
                                    className={'filter-input'}
                                />
                            </div>
                        }

                        {
                            this.state.showAdd &&
                            this.renderTableControls()
                        }
                    </div>
                    <div className={'table-wrapper'} md={12}>
                        { this.renderTable(this.state.tableData) }
                    </div>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(GuestSearch)));