import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function addCorState(data) {
    return await request('/api/cor_states', data, HttpMethod.POST);
}

export async function getAllCorStates(data) {
    return await request('/api/cor_states', data, HttpMethod.GET);
}

export async function editCorState(data) {
    return await request('/api/cor_states', data, HttpMethod.PUT);
}

export async function deleteCorState(id) {
    return await request('/api/cor_states/' + id, {}, HttpMethod.DELETE);
}