import React, {Component} from 'react';
import {Chip, Dialog, DialogContent, Divider, Slide} from "@material-ui/core/";
import { FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import strings from "../localization";
import {withStyles} from "@material-ui/core/styles";
import PayOptions from "../constants/PayOptions";
import CreditsCheckoutForm from "../components/forms/user/creditsCheckoutForm/CreditsCheckoutForm";
import Button from "@material-ui/core/Button";

class CreditsCheckoutModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: {},
        }

        this.changeData = this.changeData.bind(this);
    }

    changeData(event) {

        let data = this.props.data;

        data[event.target.name] = event.target.value;

        this.setState({
            data: data
        });
    }

    renderCreditsForm(){
        if(this.props.data.payOption === PayOptions.PAY_BY_CARD){
            return(
                <div>
                    <img className={'logo'} src={'/images/visa_mastercard_logo1.svg'} />
                    <CreditsCheckoutForm
                        data={this.props.data}
                        onChange={this.props.onChange}
                        onSubmit={this.props.onSubmit}
                    />
                </div>
            );
        }else if(this.props.data.payOption === PayOptions.PAY_MANUALLY){
            return(
                <div className={'payment-slip-wrapper'}>
                    <img className={'payment-slip'} src={'/images/uplatnica2.jpg'} />
                    <div className={'info'}>
                        <img src={'/images/info.svg'} />
                        <p>{ strings.credits.manualPayInfo }</p>
                    </div>
                    <div className='submit-container'>
                        <Button variant="contained" color="primary" onClick={ this.props.onSubmit }>
                            { strings.credits.pay } €{this.state.data.item && this.state.data.item.value ? this.state.data.item.value.toFixed(2) : ''}
                        </Button>
                    </div>
                </div>
            );
        }
    }

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="right" ref={ref} {...props} />;
    });

    getClass(payOption){
        return payOption === this.props.data.payOption ? 'selected' : '';
    }

    render() {
        const CustomRadio = withStyles({
            root: {
                color: '#1C1C1C',
                '&$checked': {
                    color: '#1C1C1C',
                },
            },
            checked: {},
        })((props) => <Radio color="default" {...props} />);

        return (
            <Dialog
                onClose={this.props.toggleModal}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                TransitionComponent={this.Transition}
                className={'credits-checkout-modal'}
            >
                <div className='credits-checkout-modal-header' onClose={this.props.toggleModal}>
                    <FormControl component="fieldset">
                        <div className={'return'} onClick={this.props.toggleModal}>
                            <img src={'images/whitearrow.svg'} />
                            <p>Back to credit packages</p>
                        </div>
                        <RadioGroup name="payOption" value={this.props.data ? this.props.data.payOption : ''} onChange={this.changeData}>
                            <FormControlLabel className={this.getClass(PayOptions.PAY_BY_CARD)} value={PayOptions.PAY_BY_CARD} control={<CustomRadio />} label={ strings.credits.payByCard } />
                            <FormControlLabel className={this.getClass(PayOptions.PAY_MANUALLY)} value={PayOptions.PAY_MANUALLY} control={<CustomRadio />} label={ strings.credits.payManually } />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={'pay-amount'}>
                    <p>{ strings.credits.payAmount }</p>
                </div>
                <p className={'pay-value'}>€ {this.props.data && this.props.data.item && this.props.data.item.value ? this.props.data.item.value.toFixed(2) : ''}</p>
                <hr />
                {/*<DialogContent dividers>*/}
                <div className={'form'}>
                    {
                        this.renderCreditsForm()
                    }
                </div>
                {/*</DialogContent>*/}
            </Dialog>
        );
    }
}

export default CreditsCheckoutModal;