import React, {Component} from 'react';
import Loader from '../components/Loader';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Navigation from "../components/admin/Navigation";
import MenuState from "../constants/MenuState";
import MobileHeader from "../components/MobileHeader";
import AdminMobileNavigation from "../components/admin/AdminMobileNavigation";
import {renderHeader} from "../util/HeaderUtil";

class BaseLayout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            windowWidth: undefined
        }
    }

    getContentClass() {

        if(this.props.menu.state === MenuState.SHORT) {
            return 'content-container short';
        }
        else {
            return 'content-container';
        }
    }

    handleResize = () => this.setState({
        windowWidth: window.innerWidth
    });

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    isCurrentPath(path) {
        return this.props.history.location.pathname.includes(path);
    }

    render() {

        const {children} = this.props;

        return (
            <React.Fragment>
                {
                    this.props.loader &&
                    <Loader/>
                }

                {
                    !this.props.menu.fullScreen &&
                    <div id='main-container'>
                        <div className='navigation-container'>
                            {/*<Navigation/>*/}
                        </div>
                        <div className={ this.getContentClass() }>
                            {
                                renderHeader(this.state.windowWidth, this.props.history.location.pathname)
                            }
                            {/*<Header/>*/}
                            {/*<MobileHeader />*/}
                            { children }
                            {
                                !this.isCurrentPath('/admin') &&
                                <Footer/>
                            }
                        </div>
                    </div>
                }

                {
                    this.props.menu.fullScreen &&
                    children
                }

            </React.Fragment>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({}, dispatch);
}

function mapStateToProps({ authReducers, siteDataReducers, menuReducers })
{
    return {
        loader: siteDataReducers.loader,
        menu: menuReducers
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseLayout));
