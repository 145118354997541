import {request} from "../../base/HTTP";
import HttpMethod from "../../constants/HttpMethod";

export async function addVatProxy(data) {
    return await request('/api/vat-proxies', data, HttpMethod.POST);
}

export async function getAllVatProxies(data) {
    return await request('/api/vat-proxies/all', data, HttpMethod.GET);
}

export async function editVatProxy(data) {
    return await request('/api/vat-proxies/' + data.id, data, HttpMethod.PUT);
}

export async function deleteVatProxy(id) {
    return await request('/api/vat-proxies/' + id, {}, HttpMethod.DELETE);
}