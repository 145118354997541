import React from 'react';
import strings from '../../../../localization';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getError, hasError} from "../../../../functions/Validation";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";

const CustomCheckbox = withStyles({
    root: {
        color: '#0969A0',
        '&$checked': {
            color: '#0969A0',
        },
    },
})((props) => <Checkbox color="default" {...props} />);

const ContactUsForm = ({
                      onSubmit,
                      onCheckboxChange,
                      onChange,
                      errors,
                      data
                  }) => (
    <form id="contact-us-form" onSubmit={ onSubmit } action = "#">
        <div className={ 'select-container' + (hasError(errors, 'fullName') ? ' error' : '') }>
            <label>{ strings.contactUs.fullName }</label>
            <TextField
                error={ hasError(errors, 'fullName') }
                helperText={ getError(errors, 'fullName') }
                fullWidth
                name='fullName'
                type='fullName'
                variant={'outlined'}
                onChange={ onChange }
                margin="normal"
                value={ data.fullName }
            />
        </div>
        <div className={ 'select-container' + (hasError(errors, 'email') ? ' error' : '') }>
            <label>{ strings.contactUs.email }</label>
            <TextField
                error={ hasError(errors, 'email') }
                helperText={ getError(errors, 'email') }
                fullWidth
                name='email'
                type='email'
                variant={'outlined'}
                onChange={ onChange }
                margin="normal"
                value={ data.email }
            />
        </div>
        <div className={ 'select-container' + (hasError(errors, 'phoneNumber') ? ' error' : '') }>
            <label>{ strings.contactUs.phoneNumber }</label>
            <TextField
                error={ hasError(errors, 'phoneNumber') }
                helperText={ getError(errors, 'phoneNumber') }
                fullWidth
                name='phoneNumber'
                type={'number'}
                variant={'outlined'}
                onChange={ onChange }
                margin="normal"
                value={ data.phoneNumber }
            />
        </div>
        <div className={ 'select-container' + (hasError(errors, 'messageSubject') ? ' error' : '') }>
            <label>{ strings.contactUs.messageSubject }</label>
            <TextField
                error={ hasError(errors, 'messageSubject') }
                helperText={ getError(errors, 'messageSubject') }
                fullWidth
                name='messageSubject'
                type='messageSubject'
                variant={'outlined'}
                onChange={ onChange }
                margin="normal"
                value={ data.messageSubject }
            />
        </div>
        <div className={ 'select-container' + (hasError(errors, 'message') ? ' error' : '') }>
            <label>{ strings.contactUs.yourMessage }</label>
            <TextField
                error={ hasError(errors, 'message') }
                helperText={ getError(errors, 'message') }
                multiline
                rows={4}
                rowsMax={4}
                variant={'outlined'}
                onChange={onChange}
                name={'message'}
                value={ data.message }
            />
        </div>

        <div className={ 'select-container' + (hasError(errors, 'agree') ? ' error' : '') }>
            <FormControlLabel
                control={
                    <CustomCheckbox
                        checked={data.agree}
                        onChange={onCheckboxChange}
                        name="agree"
                    />
                }
                label={ strings.contactUs.agree }
            />
            {
                hasError(errors, 'agree') ? <p className='select-error'>{strings.validation.RequiredErrorMessage}</p> : ""
            }
        </div>

        <div className='submit-container'>
            <Button variant="contained" color="primary" onClick={ onSubmit }>
                { strings.contactUs.send }
            </Button>
        </div>
    </form>
);

export default ContactUsForm;