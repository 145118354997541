const ReferenceTypes = {
    NONE: 1,
    USER: 2,
    COR: 3,
    DTT_COUNTRIES: 4,
    VENDORS: 5,
    VENDOR_PLANS: 6,
    VAT_PROXY: 7,
    LAWS: 8
};

export default ReferenceTypes;