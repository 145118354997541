import React, {createRef} from 'react'
import Grid from '@material-ui/core/Grid';
import Page from "../../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import Button from "@material-ui/core/Button";
import CreditsCheckoutModal from "../../common/CreditsCheckoutModal";
import PayOptions from "../../constants/PayOptions";
import ResetPasswordForm from "../../components/forms/user/ResetPasswordForm";
import EditProfileForm from "../../components/forms/user/EditProfileForm";
import {getUser, editProfile, register, resetPassword, changePassword} from "../../services/UserService";
import {withSnackbar} from "notistack";
import {getAllCorStates} from "../../services/admin/CorStatesService";

class MyProfile extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: {
            },
            editBasicSettings: false,
            editPassword: false
        };

        this.edit = this.edit.bind(this);
        this.submit = this.submit.bind(this);
        this.submitPassword = this.submitPassword.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        getUser(this.getUser().id).then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                ...this.state,
                data: {
                    name: response.data.entity.first_name,
                    surname: response.data.entity.last_name,
                    email: response.data.entity.email,
                    address: response.data.entity.address,
                    city: response.data.entity.city,
                    location: response.data.entity.location,
                    legalRegistrationNumber: response.data.entity.legal_registration_number,
                    pib: response.data.entity.pib,
                    phoneNumber: response.data.entity.phone_number,
                    password: 'sifra',
                    accountType: response.data.entity.type
                }
            });
        });
    }

    edit(name){
        this.setState({
            [name]: true
        });
    }

    submit(name){
        this.setState({
            [name]: false
        });

        this.state.data.id = this.getUser().id;

        editProfile(this.state.data).then(response => {

            if(!response.ok) {

                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })
        });
    }

    submitPassword(name){
        this.setState({
            [name]: false
        });

        this.state.data.id = this.getUser().id;

        changePassword(this.state.data).then(response => {

            if(!response.ok) {

                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })
        });
    }

    render() {
        return (
            <Grid container spacing={24} id={'my-profile'}>
                <h1>{ strings.myProfile.myProfile }</h1>

                <EditProfileForm
                    data={this.state.data}
                    edit={this.state.editBasicSettings}
                    onEdit={this.edit}
                    onSubmit={this.submit}
                    onChange={this.changeData}
                />

                <ResetPasswordForm
                    data={this.state.data}
                    edit={this.state.editPassword}
                    onEdit={this.edit}
                    onSubmit={this.submitPassword}
                    onChange={this.changeData}
                />
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(MyProfile)));
