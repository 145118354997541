import React from 'react';
import TablePage from "../../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../../constants/PageState';
import {
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import CONFIG from "../../../config";
import {cutDateAfterTime, findItem} from "../../../util/DataUtil";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import UndoIcon from "@material-ui/icons/Undo";
import Button from "@material-ui/core/Button";
import PaymentStatus from "../../../constants/PaymentStatus";
import {getAllCorStates} from "../../../services/admin/CorStatesService";
import {deletePayment, editPaymentStatus, getAllPayments} from "../../../services/admin/PaymentProcessApprovalService";
import AccountTypes from "../../../constants/AccountTypes";

class PaymentProcessApproval extends TablePage {

    tableDescription = [
        { key: 'customerName', label: strings.paymentProcessApproval.customerName},
        { key: 'creditPackage', label: strings.paymentProcessApproval.creditPackage },
        { key: 'credits', label: strings.paymentProcessApproval.credits },
        { key: 'price', label: strings.paymentProcessApproval.price },
        { key: 'date', label: strings.paymentProcessApproval.date },
        { key: 'id', label: strings.paymentProcessApproval.status, transform: 'renderStatus' },
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            showSearch: true,
            showAdd: false,
            // tableData: [],

            tableData : [
            ],
            pageState: PageState.View,
            total: 100,
            errors: {},
            searchData : {
                search : '',
                page : 1,
                perPage : 20
            }
        };
    }

    fetchData() {
        getAllPayments(this.state.searchData).then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                tableData: response.data.result
            });
        });
    }

    componentDidMount() {
        if (this.getUser() && (AccountTypes.ADMIN !== this.getUser().type)){
            console.log(this.getUser().type, AccountTypes.ADMIN)
            this.props.history.push('/');
            return
        }

        this.fetchData();
    }

    renderStatus(id) {

        let item = findItem(id, this.state.tableData)

        if (!item.status) {
            return(
                <div className={'status-wrapper'}>
                    <div className={'status-btn approve'} onClick={() => this.setPaymentStatus(item, PaymentStatus.APPROVED)} >{strings.paymentProcessApproval.approve}</div>
                    <div className={'status-btn decline'} onClick={() => this.setPaymentStatus(item, PaymentStatus.DECLINED)}>{strings.paymentProcessApproval.decline}</div>
                </div>
            );
        }else {
            if(item.status === PaymentStatus.APPROVED){
                return (
                    <div className={'approved'}>{strings.paymentProcessApproval.approved}</div>
                );
            }else if(item.status === PaymentStatus.DECLINED){
                return(
                    <div className={'declined'}>{strings.paymentProcessApproval.declined}</div>
                );
            }
        }
    }

    setPaymentStatus(item, status){
        item.status = status;

        this.setState({
            item: item
        });

        // editPaymentStatus(item).then(response => {
        //     if (!response.ok) {
        //         this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
        //         return;
        //     }
        //
        //     this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })
        //
        //     this.fetchData();
        // });
    }

    changePaymentStatus(item){
        item.status = '';

        this.setState({
            item: item
        });

        this.handleMenuClose();
    }

    delete(item){
        // deletePayment(item.id).then(response => {
        //     if (!response.ok) {
        //         this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
        //         return;
        //     }
        //
        //     this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })
        //
        //     this.fetchData();
        // });
    }

    // TABLE

    getPageHeader() {
        return <h1>{ strings.paymentProcessApproval.title }</h1>;
    }

    getPageDescription(){
        return <h3>{ strings.paymentProcessApproval.description }</h3>
    }

    renderTableHeader() {

        let result = [];

        for(let item of this.tableDescription) {
            result.push(
                <TableCell key={ 'table-header-' + result.length }>
                    <div>
                        { item.label }
                    </div>
                </TableCell>
            )
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    { result }
                    <TableCell>
                        {/*{ strings.table.actions }*/}
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    renderTableControls() {
        return [
            <Button variant="contained" color="secondary"
                // onClick={ () => this.setPageState(PageState.Add) }
                    onClick={ () => this.toggleModal() }
            >
                { strings.table.addDocument }
            </Button>
        ]
    }

    renderCell(description, item){
        if (description.transform === undefined){
            if (description.key === 'customerName'){
                return item.customer.first_name + ' ' + item.customer.last_name;
            }else if (description.key === 'creditPackage'){
                return item.credit_package.name;
            }else if (description.key === 'date'){
                return cutDateAfterTime(item.date_created);
            }

            return item[description.key];
        }
    }

    renderTableRowData(item){
        let result = [];

        for(let description of this.tableDescription) {

            result.push(
                <TableCell key={ 'table-data-' + description.key + '-' + result.length }>
                    {
                        description.transform !== undefined &&
                        this[description.transform](item[description.key])
                    }
                    {
                        this.renderCell(description, item)
                    }
                </TableCell>
            );
        }

        return result;
    }

    renderRowMenu(index, item) {

        let ariaOwns = 'action-menu-' + index;

        return(
            <TableCell>
                <IconButton
                    aria-owns={ this.state.anchorEl ? ariaOwns : undefined }
                    aria-haspopup="true"
                    onClick={ (event) => this.handleMenuClick(event, ariaOwns) }
                >
                    <MoreVert/>
                </IconButton>
                {
                    ariaOwns === this.state.ariaOwns &&
                    <Menu
                        id={ ariaOwns }
                        anchorEl={ this.state.anchorEl }
                        open={ Boolean(this.state.anchorEl) }
                        onClose={ () => this.handleMenuClose() }
                        className={'table-additional-menu'}
                    >
                        <div className={'menu-header'}>
                            {  strings.table.additionalOptions}
                        </div>
                        <MenuItem onClick={ () => this.changePaymentStatus(item) }>
                            <ListItemIcon>
                                <EditIcon/>
                            </ListItemIcon>
                            <ListItemText inset primary={ strings.paymentProcessApproval.changeStatus }/>
                        </MenuItem>
                        <MenuItem onClick={ () => this.toggleModal(item) }>
                            <ListItemIcon>
                                <img src={'/images/Group126.svg'} />
                            </ListItemIcon>
                            <ListItemText inset primary={ strings.paymentProcessApproval.addProblemInfo }/>
                        </MenuItem>
                        {
                            !item[this.deletedField] &&
                            <MenuItem onClick={ () => this.delete(item) }>
                                <ListItemIcon>
                                    <DeleteIcon/>
                                </ListItemIcon>
                                <ListItemText inset primary={ strings.table.delete }/>
                            </MenuItem>
                        }
                        {
                            item[this.deletedField] &&
                            <MenuItem onClick={ () => this.handleRestore(item) }>
                                <ListItemIcon>
                                    <UndoIcon/>
                                </ListItemIcon>
                                <ListItemText inset primary={ strings.table.undo }/>
                            </MenuItem>
                        }

                    </Menu>
                }

            </TableCell>
        );
    }

    // MODAL

    toggleModal(){}

    searchChanged(event) {

        let searchData = this.state.searchData;

        searchData[event.target.name] = event.target.value;

        this.setState({
            searchData: searchData
        });

        this.fetchData();
    }

    render() {
        return (
            <div id={'admin-table-page'}>
                <Grid id='admin-table'>
                    <div className='header'>
                        { this.getPageHeader() }
                        { this.getPageDescription() }
                    </div>
                    <div className='filter-controls'>

                        {
                            this.state.showSearch &&
                            <div className={'filter-wrapper'}>
                                <TextField
                                    placeholder={ strings.table.search }
                                    type="search"
                                    name='search'
                                    value={ this.state.searchData.search }
                                    onChange={ this.searchChanged }
                                    variant={'outlined'}
                                    className={'filter-input'}
                                />
                            </div>
                        }

                        {
                            this.state.showAdd &&
                            this.renderTableControls()
                        }
                    </div>
                    <div className={'table-wrapper'} md={12}>
                        { this.renderTable(this.state.tableData) }
                    </div>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentProcessApproval)));