import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({

    en: {
        menu: {
            Home: 'Home',
            Products: 'Products',
            Services: 'Services',
            OnlineGoods: 'Online Goods',
            EmeraldDragon: 'Emerald Dragon',
            Features: 'Features'

        },

        table: {
            actions: 'Actions',
            view: 'View',
            edit: 'Edit',
            delete: 'Delete',
            confirmDelete: 'Confirm delete',
            no: 'No',
            yes: 'Yes',
            search: 'Search',
            additionalOptions: 'Additional options',
            addNew: 'Add new document',
            addDocument: '+ Add Document'
        },

        header: {
            lock: 'Lock',
            logout: 'Logout',
            services: 'Services',
            credits: 'Credits',
            news: 'Newsletter',
            contactUs: 'Contact us',
            en: 'EN',
            myProfile: 'My profile',
            createAccount: 'Create account',
            login: 'Login'
        },

        languages: {
            en: 'EN',
            srb: 'SRB'
        },

        filter: {
            search: 'Search'
        },

        search: {
            seeAll: 'See all...',
            enterEmail: 'Enter email',
            enterName: 'Enter name',
            send: 'Send',
            noResults: 'Sorry! We didn\'t find any results. Please enter the email and we will inform you about the updates'
        },

        validation: {
            RequiredErrorMessage: 'required',
            MinLengthErrorMessage: 'Minimal length is ',
            MaxLengthErrorMessage: 'Maximal length is ',
            EmailErrorMessage: 'Please enter valid email',
            PasswordErrorMessage: 'Password must contain at least 6 letters, one upper case, one lower case and one number.',
            UserExistsErrorMessage: 'User with this email address already exists',
            OldPasswordDidNotMatch: 'Old password did not match',
            PasswordsNotEqual: 'Passwords do not match',
            notNumber: 'Not number'
        },

        notFound: {
            notFound: 'Not found!',
            dashboard: 'Dashboard'
        },

        forbidden: {
            forbidden: 'Forbidden!',
            dashboard: 'Dashboard'
        },

        error: {
            error: 'Error!',
            dashboard: 'Dashboard'
        },

        home: {
            mainTitle: '"Optimize global tax efficiancy with our intelligent invoicing soultion"',
            search: 'Search for the services, vendors, countries.....',
            login: 'Log in to unlock all features'
        },

        login: {
            email: 'Enter email',
            password: 'Enter password',
            login: 'Log in',
            loginTitle: 'Log in to AKTIVA sistem',
            wrongCredentials: 'Wrong Credentials',
            forgotPassword: 'Forgot password?',
            privacyPolicy: 'Privacy Policy',
            termsOfService: 'Terms of Service',
            createAccount: 'Create account',
            fbLogin: 'Continue with Facebook',
            googleLogin: 'Continue with Google',
            forgotPasswordTitle: 'Forgot your password?',
            forgotPasswordMessage: 'Don’t worry! Just fill in your email and we’ll send you a link to reset your password.',
            resetPassword: 'Reset password',
            success: 'Success',
            error: 'Error'
        },

        register: {
            create: 'Create AKTIVA sistem',
            privateEntity: 'Private entity',
            legalEntity: 'Legal entity',
            alreadyHaveAccount: 'Already have account?',
            login: 'Login',
            fbLogin: 'Continue with Facebook',
            googleLogin: 'Continue with Google',
            privacyPolicy: 'Privacy Policy',
            termsOfService: 'Terms of Service',
            createAccount: 'Create account',
            registerPrivateEntity: 'Register as a private entity',
            registerLegalEntity: 'Register as a legal entity'
        },

        contactUs: {
            mainTitle: 'Contact Us',
            description: 'Get in touch with us with any question and we will get back to you as soon as possible.',
            fullName: 'Full Name',
            email: 'Email',
            phoneNumber: 'Phone number',
            messageSubject: 'Message Subject',
            yourMessage: 'Your Message',
            agree: 'I agree to the terms of services and privacy policy',
            send: 'Send'
        },

        privateEntity: {
            name: 'Name',
            surname: 'Surname',
            email: 'Enter email',
            password: 'Enter password',
            repeatedPassword: 'Repeated password',
            phone: 'Phone number',
            city: 'City',
            address: 'Address'
        },

        legalEntity: {
            name: 'Name',
            address: 'Address',
            location: 'Location',
            legalRegistrationNumber: 'Legal registration number',
            pib: 'PIB',
            password: 'Enter password',
            repeatedPassword: 'Repeated password'
        },

        footer: {
            whtLaw: 'WHT law',
            dttLaw: 'DTT law',
            aktivaSistem: 'AKTIVA sistem',
            aktiva: 'AKTIVA',
            services: 'SERVICES',
            lawsAndRegulations: 'LAWS AND REGULATIONS',
            aboutUs: 'About us',
            contactUs: 'Contact us',
            creditPackages: 'Credit packages',
            news: 'News',
            termsOfServices: 'Terms of services',
            privacyPolicy: 'Privacy policy',
            corStates: 'COR states',
            corVendors: 'COR vendors',
            vendorsDataBase: 'Vendors data base',
            dttCountries: 'DTT countries',
            vatProxies: 'VAT proxies',
            citLaw: 'CIT law',
            vatLaw: 'VAT law',
            opinionOfMinistryOfFinance: 'Opinion of Ministry of finance'
        },

        corStates: {
            mainTitle: 'Certificate of residence (COR) states',
            pageDescription: 'Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi. Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi.',
            title: 'Title',
            description: 'Description',
            date: 'Date',
            credits: 'Credits',
            download: 'Download'
        },

        services: {
            corStates: 'COR states',
            corVendors: 'COR vendors',
            vendorsDataBase: 'Vendors data base',
            dttCountries: 'DTT countries',
            vatProxies: 'VAT proxies',
            mainTitle: 'Services',
            description: 'Use our cloud based or integrated invoicing solution that lets you plan your next international step. We help you and your customers to earn by optimizing global tax burden.'
        },

        lawsAndRegulations: {
            mainTitle: 'Laws and regulations',
            citLaw: 'CIT law',
            vatLaw: 'VAT law',
            dttLaw: 'DTT law',
            whtLaw: 'WHT law',
            opinionOfMinistryOfFinance: 'Opinion of Ministry of finance',
            seeDetails: 'See details',
            description: 'Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi.',
            taxRegulationOfLegalEntityProfit: 'Tax regulation of legal entity profit',
            pdvRegulations: 'PDV regulations',
            goodPracticeOfMinistryOfFinance: 'Good practice of Ministry of finance',
            title: 'Title',
            tableDescription: 'Description',
            date: 'Date',
            link: 'Link',
            id: 'ID',
            document: 'Document'
        },

        citLaw: {
            formTitle: 'Create a new document for CIT law',
            date: 'Date:',
            link: 'Link'
        },

        vatLaw: {
            formTitle: 'Create a new document for VAT law'
        },

        opinionOfMinistryOfFinance: {
            formTitle: 'Create a new document for Opinion of Ministry of finance'
        },

        singleBlog: {
            news: 'News',
            mostPopular: 'Most popular',
            search: 'Search...'
        },

        news: {
            title: 'Newsletter',
            readMore: 'Read more....'
        },

        navigation: {
            credits: 'Credits',
            creatingCreditPackage: 'Creating credit package',
            paymentProcessApproval: 'Payment process approval',
            news: 'News',
            lawsAndRegulations: 'Laws and Regulations',
            citLaw: 'CIT law',
            vatLaw: 'VAT law',
            whtLaw: 'WHT law',
            dttLaw: 'DTT law',
            opinionOfMinistryOfFinance: 'Opinion of Ministry of finance',
            corStates: 'COR states',
            corVendors: 'COR vendors',
            vendorsDataBase: 'Vendors data base',
            dttCountry: 'DTT country',
            vatProxy: 'VAT proxy',
            listOfGuestUsersSearch: 'List of guest users search',
            logout: 'Log out'
        },

        credits: {
            mainTitle: 'Credit packages',
            description: 'Tristique amet purus id urna, orci, sagittis enim nulla ut. Mattis sit lacus urna arcu. Sem mattis at vitae dui vitae nulla. In turpis a aenean leo tellus laoreet in. At odio nunc gravida egestas adipiscing a maecenas massa nisi.',
            buyCredits: 'Buy credits',
            credits: 'Credits',
            yourAvailableCredits: 'Your available credits',
            additionalInfo: 'Additional info',
            payByCard: 'Pay Invoice by credit card',
            payManually: 'Pay Invoice Manually',
            payAmount: 'Pay amount',
            nameOnCard: 'Name on card',
            cardNumber: 'Card number',
            expiryDate: 'Expiry date',
            securityDate: 'Security date',
            zipPostalCode: 'ZIP / Postal code',
            pay: 'Pay',
            manualPayInfo: 'When the payment is conducted. Admin needs to approve the payment, after that you get the credits'
        },

        creditPackages: {
            initialAmountTitle: 'Defining initial amount of credits',
            initialAmountDescription: 'Define the amount of credits that are assigned after the initially',
            initialAmount: 'Initial amount of credits',
            title: 'Credit packages',
            mainDescription: 'Tristique amet purus id urna, orci, sagittis enim nulla ut. Mattis sit lacus urna arcu. Sem mattis at vitae dui vitae nulla. In turpis a aenean leo tellus laoreet in. At odio nunc gravida egestas adipiscing a maecenas massa nisi.',
            color: 'Color',
            packageName: 'Package name',
            credits: 'Credits',
            price: 'Price',
            description: 'Description',
            edit: 'Edit',
            save: 'Save',
            delete: 'Delete',
            addNewPackage: '+ Add new package'
        },

        lock: {
            password: 'Password',
            login: 'Login',
            wrongCredentials: 'Wrong Credentials',
            unlock: 'Unlock'
        },

        vendorsDataBase: {
            mainTitle: 'Vendors data base',
            pageDescription: 'Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi. Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi.',
            name: 'Name',
            description: 'Description',
            country: 'Country',
            credits: 'Credits',
            productsAndPlans: 'Products & Plans',
            seeDetails: 'See details',
            download: 'Download',
            address: 'Address',
            pib: 'PIB',
            confirmationDate: 'Date of confirmation',
            foreignCorrespondent: 'Foreign correspondent',
            dateOfTermination: 'Date of termination of tax proxy',
            startDate: 'Start date',
            deleteDate: 'Deletion date',
            product: 'Product',
            plan: 'Plan',
            title: 'Title',
            type: 'Type',
            price: 'Price',
            additionalInfo: 'Additional information',
            wthDecision: 'WHT Decision',
            question1: 'Question 1.',
            question2: 'Question 2.',
            websiteLink: 'Website - Link',
            websitePricing: 'Website pricing - Link',
            link: 'Link',
            seePlanDetails: 'See plan details',
            plans: 'Plans',
            addNewProduct: '+ Add new product',
            addPlan: '+ Add Plan',
            addNewType: '+ Add new type',
            addNewWthDecision: '+ Add new WHT Decision',
            additionalInfoContent: 'Additional information - Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet',
            save: 'Save',
            planDetailsTitle: 'Plan details',
            addNewTypeTitle: 'Add new type',
            typeName: 'Type name',
            payment: 'Payment',
            yes: 'Yes',
            no: 'No',
            addNewWthDecisionTitle: 'Add new WHT Decision',
            wthDecisionName: 'WHT Decision name',
            percent: 'Percent (%)'
        },

        planDetails: {
            productName: 'Product Name',
            description: 'Description',
            parentProduct: 'Parent product'
        },

        corVendors: {
            mainTitle: 'COR Vendors',
            pageDescription: 'Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi. Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi.',
            description: 'Description',
            date: 'Date',
            credits: 'Credits',
            download: 'Download',
            title: 'Title'
        },

        dttCountries: {
            mainTitle: 'DTT countries',
            pageDescription: 'Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi. Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi.',
            description: 'Description',
            date: 'Date',
            credits: 'Credits',
            download: 'Download',
            title: 'Name'
        },

        vatProxies: {
            mainTitle: 'VAT proxies',
            pageDescription: 'Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi. Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi.',
            name: 'Name',
            address: 'Address',
            pib: 'PIB',
            confirmationDate: 'Date of confirmation',
            foreignCorrespondent: 'Name of foreign correspondent',
            dateOfTermination: 'Date of termination of tax proxy',
            startDate: 'Start date of carrying PDV activities',
            deleteDate: 'Date of deletion from PDV list'
        },

        guestSearch: {
            title: 'List of guest users search',
            pageDescription: 'Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi. Cras lectus mauris dignissim enim libero quis adipiscing. Commodo sagittis felis sed ac consequat non ullamcorper risus mi.',
            email: 'Email',
            name: 'Name',
            searchInput: 'Search input'
        },

        userList: {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            isDeleted: 'Is deleted',
            dateCreated: 'Date Created',
            pageTitle: 'Users',
            enabled: 'Enabled',
            userDelete: 'User deleted',
            userRestored: 'User restored'
        },

        userForm: {
            email: 'Email',
            firstName: 'First name',
            lastName: 'Last name',
            ok: 'Ok',
            cancel: 'Cancel'
        },

        addUser: {
            pageTitle: 'Add user',
            errorAddClub: 'Error adding user',
            clubAdded: 'User added',
            errorAddingUser: 'Error adding user',
            userAdded: 'User added'
        },

        resetPassword: {
            email: 'Email',
            resetPassword: 'Reset password',
            password: 'Password',
            passwordRepeat: 'Password repeat'
        },

        adminCorStates: {
            modalHeader: 'Create a new document',
            title: 'Title:',
            description: 'Description:',
            date: 'Date:',
            attachment: 'Attachment',
            supportedFormats: 'Supported formats: PDF, DOC, DOCX',
            save: 'Save',
            chooseFile: 'Choose File',
            credits: 'Credits'
        },

        myProfile: {
            myProfile: 'My profile',
            editProfile: 'Edit profile',
            resetPassword: 'Reset password',
            basicSettings: 'Basic settings',
            password: 'Password',
            name: 'Name',
            surname: 'Surname',
            email: 'Email',
            address: 'Address',
            location: 'Location',
            legalRegistrationNumber: 'Legal registration number',
            pib: 'PIB',
            phoneNumber: 'Phone Number',
            city: 'City',
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            confirmPassword: 'Confirm Password',
            edit: 'Edit',
            save: 'Save'
        },

        paymentProcessApproval: {
            title: 'Payment process approval',
            description: 'Tristique amet purus id urna, orci, sagittis enim nulla ut. Mattis sit lacus urna arcu. Sem mattis at vitae dui vitae nulla. In turpis a aenean leo tellus laoreet in. At odio nunc gravida egestas adipiscing a maecenas massa nisi.',
            customerName: 'Customer Name',
            creditPackage: 'Credit package',
            credits: 'Credits',
            price: 'Price',
            date: 'Date',
            status: 'Approve the purchase',
            approve: 'Approve',
            approved: 'Approved',
            decline: 'Decline',
            declined: 'Declined',
            changeStatus: 'Change status',
            addProblemInfo: 'Add problem info',
            delete: 'Delete'
        }
    }
});

export default strings;
