import React, {createRef} from 'react'
import TablePage from "../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {Link, withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../constants/PageState';
import {
    Divider,
    Drawer, FormControl, FormControlLabel, LinearProgress, Radio, RadioGroup, Table,
    TableBody,
    TableCell, TableFooter,
    TableHead, TablePagination,
    TableRow, TextField
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import CONFIG from "../../config";
import {withStyles} from "@material-ui/core/styles";
import AnswerTypes from "../../constants/AnswerTypes";

class VendorsDataBase extends TablePage {

    constructor(props) {
        super(props);

        this.state = {
            // data: props.data ? props.data : {},
            showSearch: true,
            tableData: [],
            pageState: PageState.View,
            total: 100,
            errors: {},
            seeDetails: false,
            selectedItem: props.data ? props.data : {}
        };

        this.changeRadioButton = this.changeRadioButton.bind(this);
    }

    getPageHeader() {
        return <h1>{ strings.vendorsDataBase.mainTitle }</h1>;
    }

    seeDetails(){
        return(
            <Grid id='details'>
                <div className={'see-details-wrapper'}>
                    <div className='header'>
                        { this.getPageHeader() }
                    </div>
                    <p>{this.state.selectedItem.name} > {this.state.selectedItem.description}</p>

                    {this.renderProduct()}
                    {this.renderPlans()}
                </div>
            </Grid>
        );
    }

    renderProduct(){
        return(
            <div className={'product'}>
                <h2>{strings.vendorsDataBase.product}</h2>
                <Divider />
                <div className={'product-name'}>
                    <h3>{strings.vendorsDataBase.name}</h3>
                    <p>{this.state.selectedItem.product.name}</p>
                </div>
                <div className={'product-description'}>
                    <h3>{strings.vendorsDataBase.description}</h3>
                    <p>{this.state.selectedItem.product.description}</p>
                </div>
            </div>
        );
    }

    renderPlans(){
        let result = [];

        for(let item of this.state.selectedItem.product.plans){
            result.push(this.renderSinglePlan(item));
        }

        return result;
    }

    renderSinglePlan(plan) {
        const CustomRadio = withStyles({
            root: {
                color: '#1C1C1C',
                '&$checked': {
                    color: '#1C1C1C',
                },
            },
            checked: {},
        })((props) => <Radio color="default" {...props} />);

        return (
            <div className={'single-plan'}>
                <div className={'single-plan-header-wrapper'} onClick={() => this.togglePlan(plan)}>
                    <div className={'single-plan-header'}>
                        <h2>{strings.vendorsDataBase.plan}</h2>
                        <p>{plan.name}</p>
                    </div>
                    {plan.showPlan ? <p>-</p> : <p>+</p>}
                </div>

                {plan.showPlan ? <Divider /> : ''}

                {
                    plan.showPlan &&
                    <div className={'single-plan-content'}>
                        <div>
                            <h3>{strings.vendorsDataBase.title}</h3>
                            <p>{plan.title}</p>
                        </div>

                        <div>
                            <h3>{strings.vendorsDataBase.description}</h3>
                            <p>{plan.description}</p>
                        </div>

                        <div>
                            <h3>{strings.vendorsDataBase.price}</h3>
                            <p>${plan.price}</p>
                        </div>

                        <div>
                            <h3>{strings.vendorsDataBase.additionalInfo}</h3>
                            <p>{plan.additionalInformation}</p>
                        </div>

                        <div>
                            <h3>{strings.vendorsDataBase.wthDecision}</h3>
                            <p>{plan.wthDecision}</p>
                        </div>

                        <div className={'questions-wrapper'}>
                            <div className={'question'}>
                                <FormControl component="fieldset">
                                    <div>
                                        <h3>{strings.vendorsDataBase.question1}</h3>
                                        <p>Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet?</p>
                                    </div>
                                    <RadioGroup name="question1" className={'answer'} value={plan.question1} onChange={(event) => this.changeRadioButton(event, plan)}>
                                        <FormControlLabel control={<CustomRadio />} value={AnswerTypes.YES} label={ 'Yes' } />
                                        <FormControlLabel control={<CustomRadio />} value={AnswerTypes.NO} label={ 'No' } />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <div className={'question'}>
                                <FormControl component="fieldset">
                                    <div>
                                        <h3>{strings.vendorsDataBase.question2}</h3>
                                        <p>Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet?</p>
                                    </div>
                                    <RadioGroup name="question2" className={'answer'} value={plan.question2} onChange={(event) => this.changeRadioButton(event, plan)}>
                                        <FormControlLabel control={<CustomRadio />} value={AnswerTypes.YES} label={ 'Yes' } />
                                        <FormControlLabel control={<CustomRadio />} value={AnswerTypes.NO} label={ 'No' } />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>

                        <div className={'documents-wrapper'}>
                            {this.renderDocuments(plan)}
                        </div>
                    </div>
                }
            </div>
        );
    }

    renderDocuments(plan){
        let result = [];

        if(plan && plan.documents){
            for(let item of plan.documents){
                result.push(
                    <div className={'document'}>
                        <img src={'/images/pdf-icon1.svg'} />
                        <p>{item.name}</p>
                    </div>
                )
            }
        }

        return result;
    }

    togglePlan(plan){
        plan.showPlan = !plan.showPlan;

        this.setState({
            plan: plan
        })
    }

    changeRadioButton(event, plan){
        plan[event.target.name] = event.target.value;

        this.setState({
            plan: plan
        });
    }

    render() {
        return (
            <div id={'services-table-page'}>
                {
                    this.seeDetails()
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(VendorsDataBase)));