import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import connect from "react-redux/es/connect/connect";
import {Button, Menu} from "@material-ui/core";
import strings from "../localization";
import {lock, logout} from "../base/OAuth";

class MobileHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            showMenu: null,
            services: null,
            language: null
        }
    }

    handleMenuClick(event, menu) {
        this.setState({
            [menu]: this.state[menu] !== null ? null : event.currentTarget
        });
    }

    handleMenuClose(menu) {
        this.setState({
            [menu]: null
        });
    }

    logout() {
        logout();
        this.props.logout();
        this.props.history.push('/');
    }

    lock() {
        lock();
        this.props.history.push('/');
    }

    toggleMenu(){
        this.setState({
            showMenu: !this.state.showMenu
        })
    }

    render() {
        return (
            <div id='mobile-header'>
                <div className={'left'}>
                    <a href={'/'}><img src={'/images/logo.png'}/></a>
                    <Menu className={'menu-wrapper'}
                          open={this.state.showMenu}
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        <div className={'submenu-wrapper'}
                             onClick={ (event) => this.handleMenuClick(event, 'services') }
                        >
                            <p>{ strings.header.services }</p>
                            <img src={'images/Polygon1.svg'} />
                        </div>
                        <div className={ this.state.services ? ' show submenu' : 'submenu'}>
                            <a href={'/cor-states'}>{ strings.services.corStates }</a>
                            <a href={'/cor-vendors'}>{ strings.services.corVendors }</a>
                            <a href={'/vendors-data-base'}>{ strings.services.vendorsDataBase }</a>
                            <a href={'/dtt-countries'}>{ strings.services.dttCountries }</a>
                            <a href={'/vat-proxies'}>{ strings.services.vatProxies }</a>
                        </div>
                        <a href={'/credit-packages'}><p>{ strings.header.credits }</p></a>
                        <a href={'/news'}><p>{ strings.header.news }</p></a>
                        <a href={'/contact-us'}><p>{ strings.header.contactUs }</p></a>
                        <div className={'submenu-wrapper'}
                             onClick={ (event) => this.handleMenuClick(event, 'anchorEl') }
                        >
                            <p className={'my-profile'}>{ strings.header.myProfile }</p>
                            <img src={'images/Polygon1.svg'} />
                        </div>

                        <div className={ this.state.anchorEl ? ' show submenu' : 'submenu'}>
                            <a href={'/my-profile'}>{ strings.myProfile.editProfile }</a>
                            <div>{ strings.header.login }</div>
                        </div>

                        <div className={'submenu-wrapper'}
                             onClick={ (event) => this.handleMenuClick(event, 'language') }
                        >
                            <p>{ strings.header.en }</p>
                            <img src={'images/Polygon1.svg'} />
                        </div>
                        <div className={ this.state.language ? ' show submenu' : 'submenu'}>
                            <a href={'/'}>{ strings.languages.srb }</a>
                        </div>
                    </Menu>
                </div>
                <div className={'right'}>
                    {
                        !this.props.user
                        ?
                            this.state.showMenu
                                ?
                                <img src={'/images/x.svg'} onClick={ () => this.toggleMenu() }/>
                                :
                                <img src={'/images/hamburger.svg'} onClick={ () => this.toggleMenu() }/>
                        :
                        <div className={'profile-wrapper'}>
                            <a href={'/register'} className={'create-account'}>{ strings.header.createAccount }</a>
                            <Button variant="contained" color="primary">
                                <a href={'/login'}>{ strings.header.login }</a>
                            </Button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeMenuState: Actions.changeMenuState,
        logout: Actions.logout,
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileHeader));
