import React from 'react'
import {bindActionCreators} from "redux";
import * as Actions from "../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../localization";
import Page from "../../common/Page";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Validators from "../../constants/ValidatorTypes";
import {Divider} from "@material-ui/core";
import RegisterForm from "../../components/forms/user/RegisterForm";
import AccountTypes from "../../constants/AccountTypes";
import {checkAccountType} from "../../util/AccountTypeUtil";
import {renderHeader} from "../../util/HeaderUtil";
import history from "../../history";
import {register} from "../../services/UserService";
import {withSnackbar} from "notistack";


class Register extends Page {

    validationList = {};

    privateEntityValidationList = {
        name: [ {type: Validators.REQUIRED } ],
        surname: [ {type: Validators.REQUIRED } ],
        email: [ {type: Validators.EMAIL } ],
        password: [ {type: Validators.PASSWORD } ],
        repeatedPassword: [ {type: Validators.CONFIRM_PASSWORD } ],
        phone: [ {type: Validators.IS_NUMBER } ],
        city: [ {type: Validators.REQUIRED } ],
        address: [ {type: Validators.REQUIRED } ]
    };

    legalEntityValidationList = {
        email: [ {type: Validators.REQUIRED } ],
        name: [ {type: Validators.REQUIRED } ],
        address: [ {type: Validators.REQUIRED } ],
        location: [ {type: Validators.REQUIRED } ],
        legalRegistrationNumber: [ {type: Validators.REQUIRED } ],
        pib: [ {type: Validators.REQUIRED } ],
        password: [ {type: Validators.PASSWORD } ],
        repeatedPassword: [ {type: Validators.CONFIRM_PASSWORD } ]
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {
                accountType: ''
            },
            errors: {},
            redirectUrl: props.location.state ? props.location.state.redirectUrl : '/'
        };

        this.props.changeFullScreen(true);
        this.submit = this.submit.bind(this);
    }

    handleButtonClick(event, name, value){
        const field = name;
        const data = this.state.data;
        data[field] = value;

        this.setState({
            data: data
        });
    }

    handleResize = () => this.setState({
        windowWidth: window.innerWidth
    });

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    componentDidMount() {

        if(this.props.auth.user) {
            this.props.history.push('/');
        }

        this.handleResize();
        window.addEventListener('resize', this.handleResize)
    }

    submit(accountType){

        this.validationList = accountType === AccountTypes.PRIVATE_ENTITY ? Object.assign(this.validationList, this.privateEntityValidationList) : Object.assign(this.validationList, this.legalEntityValidationList);

        if(!this.validate()) {
            return;
        }

        register(this.state.data).then(response => {

            if(!response.ok) {

                this.setError('email', strings.login.wrongCredentials);
                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            this.props.login(response.data.user);

            this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })

            this.props.history.push('/login');
        });
    }

    render() {
        return (
            <div id='register'>
                {
                    renderHeader(this.state.windowWidth, this.props.history.location.pathname)
                }
                <Grid container spacing={24} className={'register-wrapper'}>
                    <Grid item md={3} className={'left-image'}>
                        <img src={'/images/Ilustracija21.png'} />
                    </Grid>
                    <Grid item md={3} className={'register-form'}>
                        <Paper className='paper'>
                            <h1>{ strings.register.create }</h1>
                            {
                                this.state.data.accountType
                                ?
                                    checkAccountType(this.state.data, AccountTypes.PRIVATE_ENTITY)
                                    ?
                                    <h4>{ strings.register.privateEntity }</h4>
                                    :
                                    <h4>{ strings.register.legalEntity }</h4>
                                :
                                ''
                            }
                            <RegisterForm
                                onButtonPress={this.handleButtonClick}
                                onChange={ this.changeData }
                                data={ this.state.data }
                                errors={ this.state.errors }
                                onSubmit={this.submit}
                            />
                        </Paper>
                    </Grid>

                    {
                        this.state.data.accountType
                            ?
                            checkAccountType(this.state.data, AccountTypes.PRIVATE_ENTITY)
                                ?
                                <Grid item md={3} className={'right-image'}>
                                    <img src={'/images/Ilustracija31.png'} />
                                </Grid>
                                :
                                <Grid item md={3} className={'right-image'}>
                                    <img src={'/images/Ilustracija41.png'} />
                                </Grid>
                            :
                            <Grid item md={3} className={'right-image'}>
                                <img src={'/images/Ilustracija11.png'} />
                            </Grid>
                    }
                    <Divider />
                    <Grid item md={3} className={'privacy-terms'}>
                        <a href={'/privacy-policy'}>{ strings.register.privacyPolicy }</a>
                        <div></div>
                        <a href={'/terms-of-services'}>{ strings.login.termsOfService }</a>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen,
        login: Actions.login
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers, siteDataReducers })
{
    return { menu: menuReducers, auth: authReducers, siteData: siteDataReducers };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Register)));