import {request} from "../base/HTTP";
import HttpMethod from "../constants/HttpMethod";
import {GetObjectAclCommand, GetObjectCommand, S3Client} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

export async function addDocument(data) {
    return await request('/api/documents/add', data, HttpMethod.POST);
}

export function downloadDocument(document) {
    const config ={
        apiVersion: 'latest',
        region: 'fra1',
        endpoint: 'https://fra1.digitaloceanspaces.com',
        credentials: {
            accessKeyId: 'WAZK3QVFMF2PKTHOM46E',
            secretAccessKey: '4yRh4VMz5231t04waLYvNp+OmJ2tbUBQVX2ZSFoNbHE'
        }
    };
    const client = new S3Client(config);
    const fullPath = `${document.path + '/' + document.file_name + '.' + document.extension}`;
    const command = new GetObjectCommand({Bucket: 'aktivasistem', Key: fullPath});
    getSignedUrl(client, command, {expiresIn: 3600}).then(link => window.open(link));
}
