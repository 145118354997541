import React, { createRef } from 'react';
import TablePage from "../../../common/TablePage";
import {bindActionCreators} from "redux";
import * as Actions from "../../../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import strings from "../../../localization";
import {withSnackbar} from "notistack";
import PageState from '../../../constants/PageState';
import {
    LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem,
    TableBody,
    TableCell, TableFooter,
    TableHead, TablePagination,
    TableRow, TextField
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import {transformDate} from "../../../util/DateUtil";
import CONFIG from "../../../config";
import {sortTable} from "../../../util/DataUtil";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import UndoIcon from "@material-ui/icons/Undo";
import Button from "@material-ui/core/Button";
import ServicesModal from "../../../common/admin/ServicesModal";
import Dropzone from "react-dropzone";
import LawsAndRegulationsForm from "../../../components/forms/admin/lawsAndRegulations/LawsAndRegulationsForm";
import LawsAndRegulationsTypes, {getLawsAndRegulationsString} from "../../../constants/LawsAndRegulationsTypes";
import {
    addLawsAndRegulations,
    deleteLawsAndRegulations,
    editLawsAndRegulations, getAllLawsAndRegulations
} from "../../../services/admin/LawsAndRegulationsService";
import {getAllCorStates} from "../../../services/admin/CorStatesService";
import LawTypes from "../../../constants/LawTypes";
import VatProxiesForm from "../../../components/forms/admin/vatProxies/VatProxiesForm";
import ReferenceTypes from "../../../constants/ReferenceTypes";
import {addDocument, downloadDocument} from "../../../services/DocumentService";
import AccountTypes from "../../../constants/AccountTypes";

class AdminLawsAndRegulations extends TablePage {

    tableDescription = [];

    tableDescriptionCitLaw = [
        { key: 'name', label: strings.lawsAndRegulations.title },
        { key: 'description', label: strings.lawsAndRegulations.tableDescription },
        { key: 'date', label: strings.lawsAndRegulations.date, transform: 'date' },
        { key: 'document', label: strings.lawsAndRegulations.document, transform: 'renderDownloadImage' }
    ];

    tableDescriptionVatLaw = [
        { key: 'name', label: strings.lawsAndRegulations.title },
        { key: 'description', label: strings.lawsAndRegulations.tableDescription },
        { key: 'date', label: strings.lawsAndRegulations.date, transform: 'date' },
        { key: 'document', label: strings.lawsAndRegulations.document, transform: 'renderDownloadImage' }
    ];

    tableDescriptionOpinionOfMinistryOfFinance = [
        { key: 'name', label: strings.lawsAndRegulations.title },
        { key: 'id', label: strings.lawsAndRegulations.id },
        { key: 'date', label: strings.lawsAndRegulations.date, transform: 'renderDate' },
        { key: 'description', label: strings.lawsAndRegulations.tableDescription },
        { key: 'document', label: strings.lawsAndRegulations.document, transform: 'renderDownloadImage' }
    ];

    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            showSearch: true,
            showAdd: true,
            // tableData: [],
            tableData : [
            ],
            pageState: PageState.View,
            total: 100,
            errors: {},
            openModal: false,
            uploadedFile: null,
            type: '',
            searchData : {
                search : '',
                total : 0,
                page : 1,
                perPage : 30
            }
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.add = this.add.bind(this);
        this.edit = this.edit.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.dropzoneRef = createRef();
    }

    fetchData() {
        this.state.searchData.type = Number(this.getSearchParam('type'));

        getAllLawsAndRegulations(this.state.searchData).then(response => {
            if (!response.ok) {
                return;
            }

            this.setState({
                tableData: response.data.result,
                searchData : {
                    ...this.state.searchData,
                    page : response.data.page,
                    perPage : response.data.perPage,
                    total : Math.ceil(response.data.total/response.data.perPage)
                }
            });
        });
    }

    date(item){
        if (!item){
            return '';
        }
        var s = item;

        if (typeof s !== 'string'){
            return item;
        }

        var n = s.indexOf('T');
        return s.substring(0, n != -1 ? n : s.length)
    }

    dateSlash(item){
        if (!item){
            return '';
        }
        var s = item;

        if (typeof s !== 'string'){
            return item;
        }

        var n = s.indexOf('T');
        return new Date(s.substring(0, n != -1 ? n : s.length));
    }

    componentDidMount() {
        if (this.getUser() && (AccountTypes.ADMIN !== this.getUser().type)){
            this.props.history.push('/');
            return
        }

        let paramString = Number(this.getSearchParam('type'));

        this.getLawsAndRegulationsType(paramString);

        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let paramString = Number(this.getSearchParam('type'));

        if(prevState.type !== paramString){
            this.getLawsAndRegulationsType(paramString);
        }
    }

    getLawsAndRegulationsType(paramString){
        if(paramString === LawsAndRegulationsTypes.CIT_LAW){
            this.tableDescription = this.tableDescriptionCitLaw;
        }else if(paramString === LawsAndRegulationsTypes.VAT_LAW){
            this.tableDescription = this.tableDescriptionVatLaw;
        }else if(paramString === LawsAndRegulationsTypes.OPINION_OF_MINISTRY_OF_FINANCE){
            this.tableDescription = this.tableDescriptionOpinionOfMinistryOfFinance;
        }

        this.setState({
            type: paramString
        }, ()=> {
            this.fetchData()
        });
    }

    renderDate(item){
        if(!item){
            return '';
        }

        return transformDate(item);
    }

    renderLink(item) {
        if (!item) {
            return '';
        }

        return (
            <a href={'/'}>{strings.citLaw.link}</a>
        );
    }

    renderDownloadImage(item){
        return(
            <img src={'/images/direct-download1.svg'}  alt={'download'} style={{textAlign: 'center'}} onClick={(event)=> {
                if (!item){
                    return
                }

                downloadDocument(item)
            }}/>
        );
    }

    // TABLE

    sortTable(param){
        let sortedData = sortTable(this.state.tableData, param);

        this.setState({
            tableData: sortedData
        })
    }

    getPageHeader() {
        return <h1>{ getLawsAndRegulationsString(this.state.type) }</h1>;
    }

    getPageDescription(){
        return <h3>{ strings.dttCountries.pageDescription }</h3>
    }

    renderTableHeader() {

        let result = [];

        for(let item of this.tableDescription) {
            if(item.label !== 'Link'){
                result.push(
                    <TableCell key={ 'table-header-' + result.length } onClick={() => this.sortTable(item.key)} style={{cursor: 'pointer'}}>
                        <div>
                            { item.label }
                            <div className={'sort-icon'}>
                                <img src={'/images/Polygon2.svg'} />
                                <img src={'/images/Polygon3.svg'} />
                            </div>
                        </div>
                    </TableCell>
                )
            }else {
                result.push(
                    <TableCell key={ 'table-header-' + result.length } style={{cursor: 'default'}}>
                        <div>
                            { item.label }
                        </div>
                    </TableCell>
                )
            }
        }

        return (
            <TableHead className='table-header'>
                <TableRow>
                    { result }
                    <TableCell>
                        {/*{ strings.table.actions }*/}
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    renderTableControls() {
        return [
            <Button variant="contained" color="secondary"
                    onClick={ () => this.toggleModal() }
            >
                { strings.table.addDocument }
            </Button>
        ]
    }

    renderTableRow(data = []) {

        let result = [];

        for(let item of data) {

            if(this.state.type && (item.type === this.state.type)){
                let className = 'table-row';

                if(this.isRowSelected(item)) {
                    className += ' selected';
                }

                result.push(
                    <TableRow key={ 'table-row-' + result.length } className={ className } onClick={ () => this.selectRow(item) }>
                        { this.renderTableRowData(item) }
                        { this.renderRowMenu(result.length, item) }
                    </TableRow>
                )
            }
        }

        return (
            <TableBody>
                { result }
            </TableBody>
        );
    }

    renderRowMenu(index, item) {

        let ariaOwns = 'action-menu-' + index;

        return(
            <TableCell>
                <IconButton
                    aria-owns={ this.state.anchorEl ? ariaOwns : undefined }
                    aria-haspopup="true"
                    onClick={ (event) => this.handleMenuClick(event, ariaOwns) }
                >
                    <MoreVert/>
                </IconButton>
                {
                    ariaOwns === this.state.ariaOwns &&
                    <Menu
                        id={ ariaOwns }
                        anchorEl={ this.state.anchorEl }
                        open={ Boolean(this.state.anchorEl) }
                        onClose={ () => this.handleMenuClose() }
                        className={'table-additional-menu'}
                    >
                        <div className={'menu-header'}>
                            {  strings.table.additionalOptions}
                        </div>
                        <MenuItem onClick={ () => this.toggleModal(item) }>
                            <ListItemIcon>
                                <EditIcon/>
                            </ListItemIcon>
                            <ListItemText inset primary={ strings.table.edit }/>
                        </MenuItem>
                        {
                            !item[this.deletedField] &&
                            <MenuItem onClick={ () => this.delete(item) }>
                                <ListItemIcon>
                                    <DeleteIcon/>
                                </ListItemIcon>
                                <ListItemText inset primary={ strings.table.delete }/>
                            </MenuItem>
                        }
                        {
                            item[this.deletedField] &&
                            <MenuItem onClick={ () => this.handleRestore(item) }>
                                <ListItemIcon>
                                    <UndoIcon/>
                                </ListItemIcon>
                                <ListItemText inset primary={ strings.table.undo }/>
                            </MenuItem>
                        }

                    </Menu>
                }

            </TableCell>
        );
    }

    // MODAL SECTION

    toggleModal(item) {
        let next = !this.state.openModal;
        this.setState({
            fileName : item ? (item.document ? item.document.name : null) : null,
            openModal: next,
            uploadedFile : next ? this.state.uploadedFile : null,
            data: item ? item : {},
            pageState: item ? PageState.Edit : PageState.Add
            // data: {
            //     ...this.state.data,
            //     item
            // }
        });

        this.handleMenuClose();
    }

    openDialog() {
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
    };

    onDrop(acceptedFile) {

        this.setState({
            uploadedFile: acceptedFile[0]
        })

        // let applicationFile = new FormData();
        // applicationFile.append('application', this.state.application.id);
        // applicationFile.append('name', acceptedFile.name);
        // applicationFile.append('content', acceptedFile);
        // applicationFile.append('fileType', acceptedFile.type);
        // applicationFile.append('type', this.state.fileUploadModal);

        // uploadFiles(applicationFile).then((result) => {
        //     let files = this.state.files;
        //
        //     files.push(result.data);
        //
        //     this.setState({
        //         files: files
        //     });
        // });
    }

    add(){
        this.state.data.date = this.state.data.date ? this.state.data.date.toLocaleDateString("en-US").toString().split('T')[0] : '';
        this.state.data.type = Number(this.getSearchParam('type'));

        addLawsAndRegulations(this.state.data).then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            let applicationFile = new FormData();
            applicationFile.append('file', this.state.uploadedFile);
            applicationFile.append('name', this.state.uploadedFile ? this.state.uploadedFile.name : '');
            applicationFile.append('referenceType', ReferenceTypes.LAWS);
            applicationFile.append('referenceId', response.data.id);

            if ( this.state.uploadedFile){
                addDocument(applicationFile).then(responseFile => {
                    if (!responseFile.ok){
                        return;
                    }

                    setTimeout(() => {
                        this.fetchData();
                        this.toggleModal(this.state.data);
                        this.props.enqueueSnackbar('Successfully added', { variant: 'success' });
                    }, 1000)
                })
            }else{
                setTimeout(() => {
                    this.fetchData();
                    this.toggleModal(this.state.data);
                    this.props.enqueueSnackbar('Successfully added', { variant: 'success' });
                }, 1000)
            }
        });

    }

    edit(){
        this.state.data.type = Number(this.getSearchParam('type'));

        if (typeof this.state.data.date.toLocaleDateString === 'function'){
            this.state.data.date = this.state.data.date.toLocaleDateString("en-US").toString().split('T')[0];
        }else {
            this.state.data.date = this.state.data.date.split('T')[0];
        }

        editLawsAndRegulations(this.state.data).then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            let applicationFile = new FormData();
            applicationFile.append('file', this.state.uploadedFile);
            applicationFile.append('name', this.state.uploadedFile ? this.state.uploadedFile.name : '');
            applicationFile.append('referenceType', ReferenceTypes.LAWS);
            applicationFile.append('referenceId', response.data.id);

            if ( this.state.uploadedFile){
                addDocument(applicationFile).then(responseFile => {
                    if (!responseFile.ok){
                        return;
                    }

                    setTimeout(() => {
                        this.props.enqueueSnackbar('Successfully edited', { variant: 'success' });
                        this.fetchData();
                        this.toggleModal(this.state.data);
                    }, 1000)
                })
            }else{
                setTimeout(() => {
                    this.props.enqueueSnackbar('Successfully edited', { variant: 'success' });
                    this.fetchData();
                    this.toggleModal(this.state.data);
                }, 1000)
            }
        });

    }

    delete(){
        deleteLawsAndRegulations(this.state.selectedItem.id).then(response => {
            if (!response.ok) {
                this.props.enqueueSnackbar(strings.login.error, { variant: 'error' });
                return;
            }

            setTimeout(() => {
                this.props.enqueueSnackbar(strings.login.success, { variant: 'success' })
                this.fetchData();
            }, 1000)
        });
    }

    modalRender() {
        return (
            <ServicesModal
                toggleModal={this.toggleModal}
                open={this.state.openModal}
                deleteFile={this.deleteFile}
                onChange={this.changeData}
                data={this.state.data}
                openDialog={this.openDialog}
                showHeader={true}
                form={
                    <LawsAndRegulationsForm
                        dateSlash={this.dateSlash}
                        data={this.state.data}
                        openDialog={this.openDialog}
                        uploadedFile={this.state.uploadedFile}
                        fileName={this.state.fileName}
                        onChange={this.changeData}
                        onAdd={this.add}
                        onEdit={this.edit}
                        pageState={this.state.pageState}
                    />
                }
                content={
                    <div className={'dropzone-wrapper'}>
                        <Dropzone
                            onDrop={this.onDrop}
                            multiple={true}
                            ref={this.dropzoneRef}
                            accept={CONFIG.acceptFiles}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div>
                                    <section className={'file-dropzone'}>
                                        <div {...getRootProps()} className={'cursor-pointer'}>
                                            <input {...getInputProps()} />
                                        </div>
                                    </section>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                }
            />
        );
    }

    render() {
        let modal = this.modalRender();

        return (
            <div id={'admin-table-page'}>
                <Grid id='admin-table'>
                    <div className='header'>
                        { this.getPageHeader() }
                        { this.getPageDescription() }
                    </div>
                    <div className='filter-controls'>

                        {
                            this.state.showSearch &&
                            <div className={'filter-wrapper'}>
                                <TextField
                                    placeholder={ strings.table.search }
                                    type="search"
                                    name='search'
                                    value={ this.state.searchData.search }
                                    onChange={ this.searchChanged }
                                    variant={'outlined'}
                                    className={'filter-input'}
                                />
                            </div>
                        }

                        {
                            this.state.showAdd &&
                            this.renderTableControls()
                        }
                    </div>
                    <div className={'table-wrapper'} md={12}>
                        { this.renderTable(this.state.tableData) }
                    </div>
                    {modal}
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, filterReducers, authReducers })
{
    return { menu: menuReducers, filter: filterReducers, user: authReducers.user, };
}

export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLawsAndRegulations)));